import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperColorsBox from '../../LmStepperColorsBox';

export default class LmStepperBlindsStep3 extends React.Component {
  render() {
    const { 
      globalState,
      handleSelected1stSection,
      handleSelected2ndSection,
      selected1stSection,
      selected2ndSection,
      stepInfos: { 
        title1stSection, 
        title2ndSection, 
        options1stSection, 
        options2ndSection
      }
    } = this.props;

    return(
      <>
        <LmStepperSectionContainer title={title1stSection}>
          <LmStepperColorsBox 
            globalState={globalState}
            handleSelected={handleSelected1stSection}
            options={options1stSection} 
            selected={selected1stSection} 
          />
        </LmStepperSectionContainer>
        {options2ndSection.length > 0 &&
          <LmStepperSectionContainer title={title2ndSection}>
            <LmStepperColorsBox 
              globalState={globalState}
              handleSelected={handleSelected2ndSection}
              options={options2ndSection} 
              selected={selected2ndSection} 
            />
          </LmStepperSectionContainer>
        }
      </>
    )
  }
}