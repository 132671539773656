import i18next from 'i18next';
import { env } from './env';
import detector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { getLocale } from './api';

const default_lang = 'pl';
window.userLang = default_lang || navigator.language || navigator.userLanguage;

const checkLocale = (lng) => {
  return getLocale(lng);
};

export function init18n() {
  const i18nOpts = {
    lng: window.userLang,
    fallbackLng: 'it',
    debug: true,
  };
  return checkLocale(window.userLang)
    .then((res) => {
      if (res) {
        i18nOpts.backend = {
          loadPath: `${env.REACT_APP_API_URL}locales/{{lng}}/{{ns}}.json`,
          crossDomain: true,
        };
      }
      return init(i18nOpts);
    })
    .catch((err) => {
      //load i18n anyway from frontend to mange backward compatibility
      return init(i18nOpts);
    });
}

const init = (opts) => {
  return new Promise((resolve, reject) => {
    i18next
      .use(HttpApi)
      .use(detector)
      .init({
        backend: {
          loadPath: `${env.REACT_APP_API_URL}locales/{{lng}}/{{ns}}.json`,
          crossDomain: true,
        },
        lng: window.userLang,
        fallbackLng: 'it',
        debug: true,
      })
      .then((res) => {
        console.log('translations loaded');
        resolve();
      });
  });
};

export default i18next;
