import React from 'react';
import style from './style.module.scss';
import './autocomplete.scss';
import i18next from '../../utils/i18n';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// import {
//   // setCredentials, handleLoginButton,
//   handleGetProjectButton,
//   getProject,
// } from './../../utils/commons/loginFunctions';

// imports from the lm-design-system library
import {
  LmButton,
  LmCard,
  LmImage,
  LmInput,
  LmSelect,
} from '@lm/lm-design-system/dist/components';
// imports from material-ui
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// import project components
import LmConfOptionCard from '../LmConfOptionCard';
import LmHomepageProjectWrapper from '../LmHomepageProjectWrapper';

// images
import createProjectIcon from '../../assets/images/create-project-icon.svg';
// import loginIcon from '../../assets/images/login-icon.svg';
import downloadIcon from '../../assets/images/download.png';
import finestra from '../../assets/images/finestre.jpg';
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});
const iconStyles = {
  selectIcon: {
    color: 'green',
  },
};

const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

export default class LmHomepageMainCard extends React.Component {
  state = {
    expandedNewCard: false,
    expandedLoginCard: false,
    expandedProjectCard: false,
    tempSelectedStore: null,
  };

  // handlers for create and retrieve cards on mobile
  handleExpandedNewCard = () => {
    this.setState({ expandedNewCard: !this.state.expandedNewCard });
  };
  handleExpandedLoginCard = () => {
    this.setState({ expandedLoginCard: false });
  };
  handleExpandedProjectCard = () => {
    this.setState({ expandedProjectCard: false });
  };

  render() {
    const {
      expandedNewCard,
      // expandedLoginCard,
      expandedProjectCard,
    } = this.state;

    const {
      configuratorsOptions,
      handleCreateButton,
      handleProjectName,
      handleSelectedConfOptions,
      handleStore, // credentials,
      isLogged,
      projectName,
      projects,
      selectedConfOption,
      store,
      storeDetails,
      storeList,
      globalState,
      project,
    } = this.props;

    // const { username, password } = credentials;
    const { prjEmail, prjCode } = project;

    return (
      <div className={style.lmHomepageMainCard}>
        <div className={style.lmHpMainCardDesktop}>
          <LmCard overflowUnset>
            <div className={style.cardTitle}>
              <img src={createProjectIcon} alt="card title icon" />
              <span>
                {i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.createProject')}
              </span>
            </div>
            <div className={style.cardsWrap}>
              <Grid container justify={'center'} spacing={3}>
                {configuratorsOptions.map((el, i) => {
                  return (
                    <Grid item xs={3} key={`config-option-${i}`}>
                      <LmConfOptionCard
                        el={el}
                        i={i}
                        handleSelectedConfOptions={handleSelectedConfOptions}
                        selectedConfOption={selectedConfOption}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className={style.cardFormWrap}>
              <div className={style.LmInput}>
                <LmInput
                  label={i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.projectName')}
                  maxLenght="10"
                  name="input-project-name"
                  type="text"
                  handleChange={(e) => handleProjectName(e)}
                />
              </div>
              <div className={style.LmSelect}>
                <Autocomplete
                  options={storeList}
                  style={{ width: '100%', display: 'block' }}
                  onChange={(event, newValue) => {
                    handleStore(newValue);
                    this.setState({ tempSelectedStore: newValue });
                  }}
                  value={this.state.tempSelectedStore || storeDetails}
                  getOptionLabel={(option) => {
                    return option.label || '';
                  }}
                  className={`lmAutocomplete`}
                  IconComponent={ExpandMoreIcon}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: '100%', display: 'block' }}
                      {...params}
                      label={i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.store')}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className={style.button}>
                <LmButton
                  disabled={
                    !(selectedConfOption !== 'Infissi' && projectName && store)
                  }
                  onClick={handleCreateButton}
                >
                  {i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.createButton')}
                </LmButton>
              </div>
            </div>
            {/* {!isLogged &&
              <>
                <div className={style.cardTitle}>
                  <img src={loginIcon} alt="card title icon" />
                  <span>FAI LOGIN PER RECUPERARE I TUOI PROGETTI</span>
                </div>
                <div className={style.cardFormWrap}>
                  <div className={style.LmInput}>
                  <LmInput label="Email" name="login-input-email-main-card" type="email" handleChange={(e) => setCredentials(globalState, 'username', e.target.value)} />
                  </div>
                  <div className={style.LmInput}>
                  <LmInput label="Password" name="login-input-password-main-card" type="password" handleChange={(e) => setCredentials(globalState, 'password', e.target.value)} />
                  </div>
                  <div className={style.forgotPassword}>
                    <LmLink href="https://www.leroymerlin.it/login" target="_blank">Hai dimenticato la password?</LmLink>
                    <div className={style.noAccount}>Non hai un account?<LmLink href="https://www.leroymerlin.it/login" target="_blank" className={style.register}> Registrati</LmLink> e scopri i vantaggi a te riservati.</div>
                  </div>
                  <div className={style.button}>
                    <LmButton disabled={!(password && username)} onClick={() => handleLoginButton(globalState)}>LOGIN</LmButton>
                  </div>
                </div>
              </>
            } */}
            <>
              {/* <div className={style.cardTitle}>
                <img src={downloadIcon} alt="card title icon" />
                <span>
                  {i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.createProject')}
                </span>
              </div>
               <div className={style.cardFormWrap}>
                <div className={style.LmInput}>
                  <LmInput
                    label={i18next.t(
                      'windows.LM_HOMEPAGE_MAIN_CARD.insertEmail'
                    )}
                    name="prj-email"
                    type="email"
                    handleChange={(e) =>
                      getProject(globalState, 'prjEmail', e.target.value)
                    }
                  />
                </div>
                <div className={style.LmInput}>
                  <LmInput
                    label={i18next.t(
                      'windows.LM_HOMEPAGE_MAIN_CARD.insertProjectCode'
                    )}
                    name="prj-code"
                    type="text"
                    handleChange={(e) =>
                      getProject(globalState, 'prjCode', e.target.value)
                    }
                  />
                </div> 
                <div className={style.button}>
                  <LmButton
                    disabled={!(prjEmail && prjCode)}
                    onClick={() => handleGetProjectButton(globalState)}
                  >
                    {i18next.t('windows.LM_HOMEPAGE_MAIN_CARD.confirm')}
                  </LmButton>
                </div> 
              </div> */}
            </>
          </LmCard>
          {isLogged && (
            <LmHomepageProjectWrapper
              projects={projects}
              globalState={globalState}
            />
          )}
        </div>

        <div className={style.lmHpMainCardMobile}>
          <div className={style.formOptionsWrapper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Accordion
                  expanded={expandedNewCard}
                  onChange={this.handleExpandedNewCard}
                >
                  <AccordionSummary>
                    <div className={style.cardInfosSummary}>
                      <div className={style.img1}>
                        <LmImage src={createProjectIcon} alt="lm logo" />
                      </div>
                      <span>
                        {i18next.t(
                          'windows.LM_HOMEPAGE_MAIN_CARD.createNewProject'
                        )}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={style.cardInfosDetails}>
                      {/*  <div className={style.LmSelect}>
                        <LmSelect
                          label={i18next.t(
                            'windows.LM_HOMEPAGE.configuratorType'
                          )}
                          selected={this.selectedInitialItem}
                          name="select-conf-option-mobile"
                          items={configuratorsOptions}
                          labelKey="key"
                          handleChange={(event, element, index) =>
                            handleSelectedConfOptions(element.label)
                          }
                        />
                      </div>*/}
                      <div className={style.LmInput}>
                        <LmInput
                          label={i18next.t(
                            'windows.LM_HOMEPAGE_MAIN_CARD.projectName'
                          )}
                          maxLenght="10"
                          name="input-project-name-mobile"
                          type="text"
                          handleChange={(e) => handleProjectName(e)}
                        />
                      </div>
                      <div className={style.LmSelect}>
                        <Autocomplete
                          options={storeList}
                          style={{ width: '100%', display: 'block' }}
                          onChange={(event, newValue) => {
                            handleStore(newValue);
                            this.setState({ tempSelectedStore: newValue });
                          }}
                          value={this.state.tempSelectedStore || storeDetails}
                          getOptionLabel={(option) => {
                            return option.label || '';
                          }}
                          className={`lmAutocomplete`}
                          renderInput={(params) => (
                            <TextField
                              style={{ width: '100%', display: 'block' }}
                              {...params}
                              label={i18next.t(
                                'windows.LM_HOMEPAGE_MAIN_CARD.store'
                              )}
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                      <div className={style.button}>
                        <LmButton
                          disabled={
                            !(
                              selectedConfOption !== 'Infissi' &&
                              projectName &&
                              store
                            )
                          }
                          onClick={handleCreateButton}
                        >
                          {i18next.t(
                            'windows.LM_HOMEPAGE_MAIN_CARD.createButton'
                          )}
                        </LmButton>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* {!isLogged &&
                <Grid item xs={12}>
                  <Accordion expanded={expandedLoginCard} onChange={this.handleExpandedLoginCard}>
                    <AccordionSummary>
                      <div className={style.cardInfosSummary}>
                        <div className={style.img2}>
                          <LmImage src={loginIcon} alt="lm logo" />
                        </div>
                        <span>FAI LOGIN PER RECUPERARE I PROGETTI</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={style.cardInfosDetails}>
                        <div className={style.LmInput}>
                        <LmInput label="Email" maxLenght="10" name="login-input-email-main-card-mobile" type="email" handleChange={(e) => setCredentials(globalState, 'username', e.target.value)} />
                        </div>
                        <div className={style.LmInput}>
                        <LmInput label="Password" maxLenght="5" name="login-input-password-main-card-mobile" type="password" handleChange={(e) => setCredentials(globalState, 'password', e.target.value)} />
                        </div>
                        <div className={style.forgotPassword}>
                          <LmLink href="https://www.leroymerlin.it/login">Hai dimenticato la password?</LmLink>
                        </div>
                        <div className={style.button}>
                          <LmButton disabled={!(password && username)} onClick={() => handleLoginButton(globalState)}>LOGIN</LmButton>
                        </div>
                        <div className={style.noAccount}>
                          <div>Non hai un account?</div>
                          <div><LmLink className={style.register} href="https://www.leroymerlin.it/login">Registrati</LmLink> e scopri i vantaggi a te riservati.</div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              } */}
              {/*<Grid item xs={12}>
                <Accordion
                  expanded={expandedProjectCard}
                  onChange={this.handleExpandedProjectCard}
                >
                  <AccordionSummary>
                    <div className={style.cardInfosSummary}>
                      <div className={style.img2}>
                        <LmImage src={downloadIcon} alt="lm logo" />
                      </div>
                      <span>
                        {i18next.t(
                          'windows.LM_HOMEPAGE_MAIN_CARD.recoverProject'
                        )}
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={style.cardInfosDetails}>
                      <div className={style.LmInput}>
                        <LmInput
                          label={i18next.t(
                            'windows.LM_HOMEPAGE_MAIN_CARD.insertEmail'
                          )}
                          name="prj-email-mobile"
                          type="email"
                          handleChange={(e) =>
                            getProject(globalState, 'prjEmail', e.target.value)
                          }
                        />
                      </div>
                      <div className={style.LmInput}>
                        <LmInput
                          label={i18next.t(
                            'windows.LM_HOMEPAGE_MAIN_CARD.insertProjectCode'
                          )}
                          name="prj-code-mobile"
                          type="text"
                          handleChange={(e) =>
                            getProject(globalState, 'prjCode', e.target.value)
                          }
                        />
                      </div>
                      <div className={style.button}>
                        <LmButton
                          disabled={!(prjEmail && prjCode)}
                          onClick={() => handleGetProjectButton(globalState)}
                        >
                          Conferma
                        </LmButton>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid> */}
              {/*isLogged && (
                <LmHomepageProjectWrapper
                  projects={projects}
                  globalState={globalState}
                />
              )*/}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
