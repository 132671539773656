import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';

// imports from the lm-design-system library
import { LmNavigationMenu } from '@lm/lm-design-system/dist/components';
// import project components
import LmLoginModal from '../LmLoginModal';
import LmModal from '../LmModal';

// images
import logo from '../../assets/images/lm-logo.svg';
import accountIcon from '../../assets/images/account.svg';
import accountIconGreen from '../../assets/images/account-green.svg';
import helpIcon from '../../assets/images/help.svg';
import helpIconGreen from '../../assets/images/help-green.svg';
import storeIcon from '../../assets/images/store.svg';
import storeIconGreen from '../../assets/images/store-green.svg';
//import ecobonusBanner from '../../assets/images/ecobonus_banner.jpg';
import { env } from '../../utils/env';
import {
  handleLoginModal,
  handleLoginButton,
  handleLogoutButton,
} from '../../utils/commons/loginFunctions';

export default class LmConfiguratorHeader extends React.Component {
  state = {
    navigationMenuOpened: false,
  };

  handleNavigationMenuToggle = () =>
    this.setState({ navigationMenuOpened: !this.state.navigationMenuOpened });

  changeLocation = () => {
    window.location.href = './';
  };

  handleLoginRedirect = () => {
    //window.location.href = "https://www.leroymerlin.it/login?redirectUrl=/oaainfissi/"
    // window.location.href = "https://dev-cliente.leroymerlin.it/?redirectUrl=/oaainfissi/"
    window.location.href = env.REACT_LM_LOGIN_URL;
  };

  render() {
    const { navigationMenuOpened } = this.state;
    const {
      currentStep,
      isLogged,
      projectName,
      loginModalOpened,
      storeDetails,
      globalState,
      modalOpened,
      modalClosed,
      modalMessage,
      //selectedConfOption,
    } = this.props;

    const navigationLinksLogged = [
      { label: 'Logout', onClick: () => handleLogoutButton(globalState) },
      { label: 'Crea progetto' },
      { label: 'I tuoi progetti' },
    ];

    const navigationLinksNotLogged = [
      { label: 'Accedi o registrati', onClick: this.handleLoginRedirect },
      { label: 'Crea progetto' },
    ];

    return (
      <div className={style.lmConfiguratorHeader}>
        <div className={style.navbar}>
          <div>
            <a href="./">
              <img className={style.img} src={logo} alt="Logo Leroy Merlin" />
            </a>
          </div>
          <div className={style.loginButton}>
            <div className={style.triangle}>
              <button hidden={!isLogged.mainContact || isLogged.mainContact.guest} className={style.dropdown1}>
                <div
                  className={style.dropbtn}
                  onClick={this.handleNavigationMenuToggle}
                >
                  <img
                    className={style.imgAccount}
                    src={accountIcon}
                    alt="img account"
                  />
                  <img
                    className={style.imgAccountGreen}
                    src={accountIconGreen}
                    alt="img account"
                  />
                  <span className={style.spanNavAccount}>
                    {!isLogged
                      ? i18next.t('windows.LM_CONFIGURATOR_HEADER.account')
                      : isLogged.mainContact.name}
                  </span>
                </div>
                <div className={style.dropdown_content}>
                  <div className={style.arrowGreen}></div>
                  <div className={style.titleAccount}>
                    <h4>
                      {!isLogged
                        ? i18next.t('windows.LM_CONFIGURATOR_HEADER.account')
                        : isLogged.mainContact.name}
                    </h4>
                  </div>
                  <div className={style.buttonLoginLogout}>
                    <div
                      onClick={
                        !isLogged
                          ? this.handleLoginRedirect
                          : () => handleLogoutButton(globalState)
                      }
                    >
                      <span>
                        {' '}
                        {!isLogged ? 'ACCEDI O REGISTRATI' : 'LOGOUT'}
                      </span>
                    </div>
                  </div>
                  <div className={style.list} onClick={this.changeLocation}>
                    {i18next.t('windows.LM_CONFIGURATOR_HEADER.createProject')}
                  </div>
                  {isLogged && (
                    <div className={style.list} onClick={this.changeLocation}>
                      {i18next.t('windows.LM_CONFIGURATOR_HEADER.myProjects')}
                    </div>
                  )}
                </div>
              </button>
              {/*<div className={style.dropdown2}>
                <div className={style.dropbtn}>
                  <img
                    className={style.imgHelp}
                    src={helpIcon}
                    alt="img account"
                  />
                  <img
                    className={style.imgHelpGreen}
                    src={helpIconGreen}
                    alt="img account"
                  />
                   <span className={style.spanNavHelp}>Aiuto</span>
                  <span className={style.spanNavHelp}>
                    {i18next.t('windows.LM_CONFIGURATOR_HEADER.help')}
                  </span>
                </div>
              </div>*/}
              <div className={style.dropdown3}>
                <div className={style.dropbtn}>
                  {isLogged && (
                    <>
                      <div>
                        <img
                          className={style.imgStore}
                          src={storeIcon}
                          alt="img account"
                        />
                        <img
                          className={style.imgStoreGreen}
                          src={storeIconGreen}
                          alt="img account"
                        />
                      </div>

                      <div className={style.spanStore}>
                        <div className={style.spanNavStore1}>
                          {/* Il mio negozio */}
                          {i18next.t('windows.LM_CONFIGURATOR_HEADER.myStore')}
                        </div>
                        <div className={style.spanNavStore2}>
                          {storeDetails && storeDetails.storeName}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <nav className={style.lmNav}>
            <LmNavigationMenu
              opened={navigationMenuOpened}
              links={
                !isLogged ? navigationLinksNotLogged : navigationLinksLogged
              }
              handleToggle={this.handleNavigationMenuToggle}
            >
              <span>
                {' '}
                {isLogged
                  ? `Benvenuto, ${isLogged.mainContact.name}`
                  : 'Benvenuto'}{' '}
              </span>
            </LmNavigationMenu>
          </nav>

          <LmLoginModal
            globalState={globalState}
            loginModalOpened={loginModalOpened}
            handleLoginButton={handleLoginButton}
            handleLoginModal={handleLoginModal}
          />

          <LmModal
            modalOpened={modalOpened}
            modalClosed={modalClosed}
            modalMessage={modalMessage}
          />
        </div>

        {currentStep === 0 && (
          <>
            <div
              className={style.lmConfiguratorHeaderDesktop}
              style={{
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                /* backgroundImage:
                  selectedConfOption === 'Finestre'
                    ? `url(${ecobonusBanner})`
                    : '', */
              }}
            >
              <span className={style.title1}>
                {/* CONFIGURATORE */}
                {i18next.t('windows.LM_CONFIGURATOR_HEADER.config')}
              </span>
              <span className={style.subtitleBold}>
                {/* BENVENUTI NEL NOSTRO CONFIGURATORE */}
                {i18next.t('windows.LM_CONFIGURATOR_HEADER.subtitleBold')}
              </span>
              <span className={style.subtitle1}>
                {/* DEDICATO ALL'ASSISTENZA DEGLI UTENTI LEROY MERLIN */}
                {i18next.t('windows.LM_CONFIGURATOR_HEADER.subtitle1')}
              </span>
            </div>
            <div className={style.lmConfiguratorHeaderMobile}>
              {/* <span className={style.title2}>BENVENUTO!</span> */}
              <span className={style.title2}>
                {i18next.t('windows.LM_CONFIGURATOR_HEADER.title2')}
              </span>
              {/* <span className={style.subtitle2}>Configura i tuoi infissi</span> */}
              <span className={style.subtitle2}>
                {i18next.t('windows.LM_CONFIGURATOR_HEADER.subtitle2')}
              </span>
            </div>
          </>
        )}

        {currentStep !== 0 && (
          <div className={style.stepperProjectName}>
            <h1>{projectName}</h1>
          </div>
        )}
      </div>
    );
  }
}
