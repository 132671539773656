import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
// imports from the lm-design-system library
import { LmButton, LmInput } from '@lm/lm-design-system/dist/components';
// imports from material-ui
import { Dialog } from '@material-ui/core';

export default class LmStepperDuplicateModal extends React.Component {
  state = {
    newHeight: undefined,
    newWidth: undefined,
  };

  handleNewSizes = (value, type) => this.setState({ [type]: value });

  render() {
    const {
      globalState,
      duplicateModalOpened,
      handleDuplicate,
      handleSummaryCardDuplicateModal,
    } = this.props;
    const { newHeight, newWidth } = this.state;

    return (
      <Dialog
        open={duplicateModalOpened}
        onClose={() => handleSummaryCardDuplicateModal(globalState)}
        maxWidth="xl"
      >
        <div className={style.duplicateForm}>
          <div className={style.title}>
            {/* Scegli le misure del tuo nuovo infisso */}
            {i18next.t('windows.LM_STEPPER_DUPLICATE_MODAL.chooseMeasurement')}
          </div>

          <div className={style.lmInput}>
            <LmInput
              label={i18next.t('windows.LM_STEP_WINDOW_SIZE.heightInputLabel')}
              maxLenght="10"
              name="new-height-input"
              type="number"
              handleChange={(e) =>
                this.handleNewSizes(e.target.value, 'newHeight')
              }
            />
          </div>
          <div className={style.lmInput}>
            <LmInput
              label={i18next.t('windows.LM_STEP_WINDOW_SIZE.widthInputLabel')}
              maxLenght="10"
              name="new-width-input"
              type="number"
              handleChange={(e) =>
                this.handleNewSizes(e.target.value, 'newWidth')
              }
            />
          </div>

          <div className={style.lmButton}>
            <LmButton
              disabled={!(newHeight && newWidth)}
              onClick={() => handleDuplicate(globalState, newHeight, newWidth)}
            >
              {i18next.t('windows.LM_STEPPER_DUPLICATE_MODAL.duplicate')}
            </LmButton>
          </div>
        </div>
      </Dialog>
    );
  }
}
