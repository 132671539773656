import React from 'react';
import style from './style.module.scss';

export default class LmStepperSectionContainer extends React.Component {
  render() {
    const { children, title, subtitle1, subtitle2} = this.props;

    return (
      <div className={style.lmStepperSectionContainer}>
        <h2>{title}</h2>
        <h4>{subtitle1}</h4>
        <h4>{subtitle2}</h4>
        {children}
      </div>
    )
  }
}