import React from 'react';
import style from './style.module.scss';

// imports from material-ui
import { Grid } from '@material-ui/core';
// imports from the lm-design-system library
import { LmImage } from '@lm/lm-design-system/dist/components';

import { deleteProject, handleProjectCardModify } from '../../utils/commons/projectFunctions';

import logo from '../../assets/images/lm-logo.svg';

export default class LmHomepageProjectWrapper extends React.Component {

    render() {

        const { globalState, projects } = this.props;

        return (
            <div hidden={(!projects || projects.length === 0)} className={style.projectContainer}>
                <h2 className={style.titleProjectCont}>
                    {(!projects || projects.length === 0) && <span>Al momento non ci sono progetti salvati per il tuo profilo</span>}
                    {projects && projects.length > 0 && <span>I tuoi progetti</span>}
                </h2>
                <Grid container spacing={3}>
                    {projects && projects.map((el, i) => {
                        return (
                            <Grid item xs={12} md={6} key={`config-option-${i}`}>
                                <div className={style.projectWrapper}>
                                    <div className={style.projectItem}>
                                        <div className={style.projectTitle}>
                                            <h2>{el.title}</h2>
                                            <span>{el.code}</span>
                                        </div>

                                        <div className={style.projectContent}>

                                            <div className={style.projectImage}>
                                                <div className={style.projectImageInner}>
                                                    <LmImage className={style.projectImageInner} src={logo} />
                                                </div>
                                            </div>

                                            <div className={style.projectText}>
                                                <div>
                                                    <p>&nbsp;</p>  {/* N° Elementi: 3 */}
                                                    <p>&nbsp;</p>  {/* N° Totale Unità: 14 */}


                                                    <h2>Tot. {Number(el.price).toFixed(2)}</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.projectActions}>
                                            {/* <span>Duplica</span> */}
                                            <span onClick={() => handleProjectCardModify(globalState, el.id)}>Modifica</span>
                                            <span onClick={() => deleteProject(globalState, el.id)}>Elimina</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}