import React from 'react';
import style from './style.module.scss';
 
// imports from material-ui
import { Grid } from '@material-ui/core';
// import project components
import LmStepperCardColor from '../LmStepperCardColor';

export default class LmStepperColorsBox extends React.Component {
  render() {
    const { globalState, options, selected, handleSelected } = this.props;

    return (
      <div className={style.lmStepperColorsBoxContainer}>
        <div className={style.lmStepperColorsBox}>
          <Grid container justify={"center"} spacing={3}>
            {options.map((el, i) => {
              return (
                <Grid key={`item-${el.label}-${i}`} item xs={4} sm={4} md={3} lg={2}>
                  <LmStepperCardColor el={el} i={i} selected={selected} handleSelected={handleSelected} globalState={globalState}/>
                </Grid>
              )
            })}
          </Grid>
          {/* <div className={style.selectedColor}>
            {selected}
          </div> */}
        </div>
      </div>
    )
  }
}