import axios from 'axios';
import {
  LM_LOGIN,
  LM_PROFILE,
  APIKEY,
  GET_PROJECT,
  GET_PROJECT_PDF,
} from '../api';
import { getProjects, submitProject } from './projectFunctions';
import { handleAppointmentRequest } from './appointmentFunctions';
import jwt_decode from 'jwt-decode';
import i18next from '../i18n';
import {env} from '../env'
// Translation Texts

const attentionText = i18next.t('windows.LOGIN_FUNCTIONS.attention');
const insertUserData = i18next.t('windows.LOGIN_FUNCTIONS.insertUserData');
const correctUserData = i18next.t('windows.LOGIN_FUNCTIONS.correctUserData');
const insertUserOrPassword = i18next.t(
  'windows.LOGIN_FUNCTIONS.insertUserNamePW'
);

const guestUser = () => {
  localStorage.removeItem('user');
  return {
    id: "4456912",
    entityExtensions: {
      AddressNormalizationData: {},
      RelatedContacts: {},
      ContactInformationAdditionalData: {
        housingInformations: [
          { contactUUID: "", from: "" },
        ],
      },
      SegmentationInfos: {
        segmentations: ["directMarketingFlag", "storeCode_31"],
      },
      DirectMarketingPreferences: { storeCode: "0", flagValue: true },
      CustomerExternalInfo: {
        externalReferenceCode: "",
        customerType: "",
        showCompleteCustomerPopup: true,
      },
    },
    mainContact: {
      guest: true,
      anonymous: true,
      uuid: "",
      gender: "",
      type: "",
      emailAddress: "",
      title: "",
      name: "",
      surname: "",
      phone1: { type: "", number: "" },
      address: { zipCode: "", countryIsoCode: "" },
      billingInformation: {},
    },
  };
};

// Login functions
const setCredentials = (globalState, type, value) => {
  /* Getter */
  const state = globalState();
  /* Setter */
  globalState({
    ...state,
    credentials: { ...state.credentials, [type]: value },
  });
};

// Retrieve Project
const getProject = (globalState, type, value) => {
  /* Getter */
  const state = globalState();
  /* Setter */
  globalState({ ...state, project: { ...state.project, [type]: value } });
};

const session = (item, value = null, session) => {
  if (session) {
    if (value) {
      sessionStorage.setItem(item, JSON.stringify(value));
    } else {
      if (value === false) {
        sessionStorage.removeItem(item);
      } else {
        return JSON.parse(sessionStorage.getItem(item));
      }
    }
  } else {
    if (value) {
      localStorage.setItem(item, JSON.stringify(value));
    } else {
      if (value === false) {
        localStorage.removeItem(item);
      } else {
        return JSON.parse(localStorage.getItem(item));
      }
    }
  }
};

const handleLoginModal = (globalState) => {
  const state = globalState();

  globalState({ loginModalOpened: !state.loginModalOpened });
};

const handleGetProjectButton = (globalState) => {
  const state = globalState();
  const {
    project: { prjCode, prjEmail },
  } = state;
  if (prjCode && prjEmail) {
    axios
      .get(`${GET_PROJECT}?email=${prjEmail}&code=${prjCode}`)
      .then(({ data }) => {
        const tempParams = data.data[0];

        const params = {
          title: tempParams.title,
          to: tempParams.storeId,
          clientFirstName: tempParams.clientFirstName,
          clientLastName: tempParams.clientLastName,
          clientEmail: tempParams.clientEmail,
          clientPhone: tempParams.clientPhone,
          acceptedPrivacy: tempParams.gdpr,
          note: tempParams.note,
          lmUniqueId: tempParams.lmUniqueId,
          price: tempParams.price,
          windows: tempParams.project_windows.map((e) => {
            const newObj = { ...e, selections: e.project_window_selections };
            delete newObj.project_window_selections;
            return newObj;
          }),
        };

        axios
          .post(`${GET_PROJECT_PDF}`, params, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
            },
            responseType: 'blob',
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  } else {
    globalState({
      modalOpened: true,
      modalMessage: {
        title: { attentionText },
        text: { insertUserData },
      },
    });
  }
};

const setProfileAfterLogin = (globalState, contactInfo) => {
  const state = globalState();
  const { storeList } = state;
  if (contactInfo) {
    const { DirectMarketingPreferences } = contactInfo.entityExtensions;

    const defaultStoreID =
      DirectMarketingPreferences && DirectMarketingPreferences.storeCode
        ? DirectMarketingPreferences.storeCode
        : null;

    const defaultStore = storeList.filter(
      (store) => Number(store.id) === Number(defaultStoreID)
    )[0];
    globalState(
      {
        isLogged: contactInfo,
        loginModalOpened: false,
        store: defaultStore ? defaultStore.id : null,
        storeDetails: defaultStore,
      },
      () => getProjects(globalState)
    );

    session('user', contactInfo);
    session('store', defaultStore ? defaultStore.id : null);
    session('storeDetails', defaultStore);

    if (session('savingProject')) {
      session('savingProject', false);
      globalState({ isLoading: true });
      submitProject(globalState, contactInfo);
    } else {
      globalState({ isLoading: false });
    }
    if (session('takingAppointment')) {
      session('takingAppointment', false);
      globalState({ isLoading: true });
      handleAppointmentRequest(globalState);
    } else {
      globalState({ isLoading: false });
    }
  } else {
    globalState({
      isLoading: false,
      modalOpened: true,
      modalMessage: {
        title: { attentionText },
        text: { correctUserData },
      },
    });
  }
};

const handleLoginButton = (globalState) => {
  const state = globalState();
  const {
    credentials: { username, password },
  } = state;

  if (username && password) {
    globalState({ isLoading: true });
    login({ username, password }).then(({ customerID, authToken, error }) => {
      if (customerID && authToken) {
        getProfile({
          customerID: customerID,
          authToken: authToken,
          r5Token: authToken,
        }).then(({ contactInfo, error }) =>
          setProfileAfterLogin(globalState, contactInfo)
        );
      } else {
        globalState({ isLoading: false });
        globalState({
          modalOpened: true,
          modalMessage: {
            title: { attentionText },
            text: { correctUserData },
          },
        });
      }
    });
  } else {
    globalState({
      modalOpened: true,
      modalMessage: {
        title: { attentionText },
        text: { insertUserOrPassword },
      },
    });
  }
};

const handleLogoutButton = (globalState) => {
  function setCookie(cname, cvalue, exMins) {
    var d = new Date();
    d.setTime(d.getTime() + exMins * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  setCookie('auth_data', '', 0);
  setCookie('r5token', '', 0);
  globalState({ isLogged: false, projects: [] });
  session('autologinAuthData', false);
  session('authData', false, true);
  session('store', false);
  session('storeDetails', false);
  session('savingProject', false);
  session('user', false);
};

// LM direct calls
const login = ({ username, password }) => {
  return axios
    .post(
      LM_LOGIN,
      {
        username: username,
        password: password,
        cartCode: '',
        rememberMe: false,
      },
      { headers: { 'x-square-api-key': APIKEY } }
    )
    .then(({ data }) => {
      //('{Login}', data.status);
      if (data.status === 'OK') {
        // logged user
        return {
          customerID: data.content.customerID,
          authToken: data.content.authenticationToken,
        };
      } else {
        // not logged user
        return { error: data.content[0].description };
      }
    })
    .catch((err) => {
      console.log('{Login}', err);
      return { error: JSON.stringify(err) };
    });
};

const getProfile = ({ customerID, authToken, r5Token }) => {
  if(env.USE_GUEST_USER){
    //console.log('getProfile with USE_GUEST_USER', env.USE_GUEST_USER);
    return new Promise((resolve, reject) => resolve(guestUser())).then( (result) => {
      return { contactInfo: result };
    });
    
  };
  
  return axios
    .get(LM_PROFILE + customerID, {
      headers: {
        'x-square-api-key': APIKEY,
        'x-square-user-ID': customerID,
        'x-square-auth-token': authToken,
        'x-square-r5-token': r5Token,
      },
    })
    .then(({ data }) => {
      //('{getProfile}', data.status);
      if (data.status === 'OK') {
        // logged user
        return { contactInfo: data.content.contactInfo };
      } else {
        // not logged user
        return { error: data.content[0].description };
      }
    })
    .catch((err) => {
      console.log('{getProfile}', err);
      return { error: JSON.stringify(err) };
    });
};

const getCookieValue = (a) => {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export const readR5Token = (token) => {
  try {
    return jwt_decode(token);
  } catch (e) {
    return undefined;
  }
};

export {
  getCookieValue,
  setCredentials,
  session,
  handleLoginModal,
  handleLoginButton,
  handleLogoutButton,
  getProject,
  getProfile,
  handleGetProjectButton,
  setProfileAfterLogin,
};
