import React, { useState } from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
import { Dialog } from '@material-ui/core';
import { LmButton, LmInput } from '@lm/lm-design-system/dist/components';
import { handleShareRequest } from '../../utils/commons/shareProjectService';
import { handleShareRequestModal } from '../../utils/commons/shareProjectService';

import addIcon from '../../assets/images/addIcon.svg';
import closeEmail from '../../assets/images/closeEmail.svg';
import shareIcon from '../../assets/images/shareIcon.svg';
// Translation keys returned from Backend & included locally in frontend public/locales
function LmEmailShare(props) {
  console.log(props);
  const { globalState, shareEmailModalOpened } = props;

  const [fields, setFields] = useState([{ value: null }]);
  const [formatErrorTotal, setFormatErrorTotal] = useState(false);
  const [fieldAdded, setFieldAdded] = useState(false);

  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ value: null });
    if (values.length === 0) {
      setFieldAdded(false);
    } else if (values.length >= 1) {
      setFieldAdded(true);
    }
    if (values.length > 4) {
      setFormatErrorTotal(true);
    } else {
      setFields(values);
    }
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    setFormatErrorTotal(false);

    if (values.length === 0) {
      setFieldAdded(false);
    }
  }

  return (
    <Dialog
      open={shareEmailModalOpened}
      onClose={() => handleShareRequestModal(globalState)}
    >
      <div className={style.container}>
        <div className={style.emailCard}>
          <div className={style.cardTitle}>
            <img src={shareIcon} alt="card title icon" />
            <p>{i18next.t('windows.LM_SHARE_EMAIL.title')}</p>
          </div>
          <p className={style.subText}>
            {i18next.t('windows.LM_SHARE_EMAIL.subtitle')}
          </p>
          <div>
            {fields.map((field, idx) => {
              return (
                <div className={style.inputContainer} key={`${field}-${idx}`}>
                  <LmInput
                    label="Email"
                    maxLenght="20"
                    type="email"
                    placeholder="Enter an email address"
                    handleChange={(e) => handleChange(idx, e)}
                  />
                  <button
                    className={style.closeButton}
                    type="button"
                    onClick={() => handleRemove(idx)}
                  >
                    <img src={closeEmail} alt="close icon" />
                  </button>
                </div>
              );
            })}
          </div>

          <button className={style.addButton} onClick={() => handleAdd()}>
            <img src={addIcon} alt="card title icon" />
            <p>{i18next.t('windows.LM_SHARE_EMAIL.add_email')}</p>
          </button>
          {formatErrorTotal && (
            <p className={style.error}>
              {i18next.t('windows.LM_SHARE_EMAIL.error_max_email')}
            </p>
          )}

          {/* {fieldAdded && ( */}
          <div className={style.buttonContainer}>
            <LmButton onClick={() => handleShareRequest(globalState, fields)}>
              <p>{i18next.t('windows.LM_SHARE_EMAIL.send_email')}</p>
            </LmButton>
          </div>
          {/* )} */}
        </div>
      </div>
    </Dialog>
  );
}
export default LmEmailShare;
