import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';
import LmStepperSizesBox from '../../LmStepperSizesBox';

export default class LmStepperMnetsStep1 extends React.Component {
  state={
    selectedSizes: []
  }

  componentDidMount() {
    const { selected1stSection, stepInfos: { options1stSection } } = this.props;
    this.setState({ 
      selectedSizes: selected1stSection 
        ? options1stSection[options1stSection.map(el => el.item.label).indexOf(selected1stSection)].sizes 
        : []
      })
  };

  componentDidUpdate(prevState) {
    const { selected1stSection, stepInfos: { options1stSection } } = this.props;
    if(prevState.selected1stSection !== selected1stSection) 
      this.setState({ 
        selectedSizes: options1stSection[options1stSection.map(el => el.item.label).indexOf(selected1stSection)].sizes 
      })
  };

  render() {
    const { 
      selectedSizes,
      selectedSizes: { 
        min_height, 
        max_height, 
        min_width, 
        max_width
      } 
    } = this.state;
    const {
      globalState,
      confHeightIsValid,
      confWidthIsValid,
      configuratorHeight,
      configuratorWidth,
      handleConfiguratorHeight,
      handleConfiguratorWidth,
      handleSelected1stSection,
      selected1stSection,
      stepInfos: { 
        title1stSection, 
        title2ndSection, 
        options1stSection 
      }
    } = this.props;

    return(
      <>
        <LmStepperSectionContainer title={title1stSection}>
          <LmStepperCardWrapper 
            globalState={globalState}
            handleSelected={handleSelected1stSection}
            options={options1stSection} 
            selected={selected1stSection} 
          />
        </LmStepperSectionContainer>
        {selected1stSection &&
          <LmStepperSectionContainer title={title2ndSection}>
            <LmStepperSizesBox 
              heightIsValid={confHeightIsValid}
              widthIsValid={confWidthIsValid}
              height={configuratorHeight}
              width={configuratorWidth}
              handleHeightInput={(e) => handleConfiguratorHeight(globalState, e.target.value, min_height, max_height)} 
              handleWidthInput={(e) => handleConfiguratorWidth(globalState, e.target.value, min_width, max_width)}
              sizes={selectedSizes}
            />
          </LmStepperSectionContainer>
        }
      </>
    )
  }
}