import React from 'react';
import style from './style.module.scss';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';
import i18next from '../../../utils/i18n';

export default class LmStepperWinStep2 extends React.Component {
  render() {
    const {
      globalState,
      handleSelected1stSection,
      handleSelected2ndSection,
      selected1stSection,
      selected2ndSection,
      stepInfos: {
        title1stSection,
        title2ndSection,
        options1stSection,
        options2ndSection,
      },
      
    } = this.props;

    const {step2section2itemId} = globalState();

    return (
      <>
        {options1stSection.length !== 0 && (
          <LmStepperSectionContainer title={i18next.t(title1stSection)}>
            <LmStepperCardWrapper
              globalState={globalState}
              handleSelected={handleSelected1stSection}
              options={options1stSection}
              selected={selected1stSection}
            />
          </LmStepperSectionContainer>
        )}
        {options1stSection.length === 0 && (
          <LmStepperSectionContainer
            subtitle1={i18next.t('windows.ALERTS.noGoods')}
            subtitle2={i18next.t('windows.ALERTS.noGoods2')}
          />
        )}

        {selected1stSection && (
          <>
            {options2ndSection.length !== 0 && (
              <LmStepperSectionContainer title={i18next.t(title2ndSection)}>
                <LmStepperCardWrapper
                  globalState={globalState}
                  handleSelected={handleSelected2ndSection}
                  options={options2ndSection}
                  selected={selected2ndSection}
                />
                 { 
                    step2section2itemId && step2section2itemId === 401 &&
                  <div className={style.informationText}>
                  {i18next.t('windows.section.title.information_text')}
                  
                </div>
                }
              </LmStepperSectionContainer>
            )}
            {options2ndSection.length === 0 && (
              <LmStepperSectionContainer
                title={i18next.t('windows.ALERTS.askToStore')}
              />
            )}
          </>
        )}
      </>
    );
  }
}
