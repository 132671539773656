import React from 'react';
import Script from 'react-load-script';
import style from './LmConfigurator.module.scss';
import axios from 'axios';

// imports from the lm-conf-template library
import { LmConfiguratorTemplate } from '@lm/lm-conf-template/dist/components';
// import project components
import LmConfiguratorHeader from '../components/LmConfiguratorHeader';
import LmHomepageMainCard from '../components/LmHomepageMainCard';
import LmLoader from '../components/LmLoader';
// mosquito nets stepper components
import LmStepperMnetsStep1 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep1';
import LmStepperMnetsStep2 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep2';
import LmStepperMnetsStep3 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep3';
import LmStepperMnetsStep4 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep4';
import LmStepperMnetsStep5 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep5';
import LmStepperMnetsStep6 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep6';
import LmStepperMnetsStep7 from '../components/LmStepperStepsMosquitoNets/LmStepperMnetsStep7';
// blinds stepper components
import LmStepperBlindsStep1 from '../components/LmStepperStepsBlinds/LmStepperBlindsStep1';
import LmStepperBlindsStep2 from '../components/LmStepperStepsBlinds/LmStepperBlindsStep2';
import LmStepperBlindsStep3 from '../components/LmStepperStepsBlinds/LmStepperBlindsStep3';
import LmStepperBlindsStep4 from '../components/LmStepperStepsBlinds/LmStepperBlindsStep4';
import LmStepperBlindsStep5 from '../components/LmStepperStepsBlinds/LmStepperBlindsStep5';
// windows stepper components
import LmStepperWinStep1 from '../components/LmStepperStepsWindows/LmStepperWinStep1';
import LmStepperWinStep2 from '../components/LmStepperStepsWindows/LmStepperWinStep2';
import LmStepperWinStep3 from '../components/LmStepperStepsWindows/LmStepperWinStep3';
import LmStepperWinStep4 from '../components/LmStepperStepsWindows/LmStepperWinStep4';
import LmStepperWinStep5 from '../components/LmStepperStepsWindows/LmStepperWinStep5';
import LmStepperWinStep6 from '../components/LmStepperStepsWindows/LmStepperWinStep6';

// imports from utils folder
import { STORE_LIST_API } from '../utils/api';
import {
  getStepsLabels,
  getStepsInfos,
  stepsCreate,
  stepsResetOnBackButton,
  detectDevice,
  handleStepNavigation,
} from '../utils/commons/index';
import {
  session,
  getProfile,
  setProfileAfterLogin,
  getCookieValue,
  readR5Token,
} from '../utils/commons/loginFunctions';
import { getProjects } from '../utils/commons/projectFunctions';
import i18next from '../utils/i18n';
import { env } from '../utils/env';

// images
import finestra from '../assets/images/finestre.jpg';

export default class LmConfigurator extends React.Component {
  state = {
    isLoading: false,

    // starting options
    configuratorsOptions: [
      /*  { img: zanzariera, label: "Zanzariere" },
      { img: tapparella, label: "Tapparelle" }, */
      {
        img: finestra,
        label: 'Finestre',
        key: 'windows.LM_HOMEPAGE_MAIN_CARD.windows',
      },
    ],
    selectedConfOption: 'Finestre',

    // stores
    storeList: [],

    // modal
    modalOpened: false,
    modalClosed: () => this.setState({ modalOpened: false }),
    modalMessage: null,

    // login
    isLogged: session('user') || false,
    credentials: {
      username: null,
      password: null,
    },
    project: {
      prjCode: null,
      prjEmail: null,
    },
    loginModalOpened: false,
    contactInfo: null,

    // inputs initial state
    projectName: null,
    store: session('store') || null,
    storeDetails: session('storeDetails') || null,

    // stepper
    isFromProjectCard: false,
    currentGroup: null,
    currentPreview: null,
    currentPreviewWindowsFront: null,
    currentPreviewWindowsBack: null,
    currentStepperId: null,
    currentStepperCode: null,
    currentPrice: 0,
    currentStep: 0,
    steps: [],
    stepsInfos: [],
    stepsLabels: [],
    stepsCode: [],
    stepperColorslist: null,
    stepperCanProceedMnets: [false, false, false, false, false, true, true],
    stepperCanProceedBlinds: [false, false, false, true, true],
    stepperCanProceedWindows: [false, false, false, false, false, true],
    totalPrice: 0,
    discountedTotalPrice: 0,
    newProductModalOpened: false,
    selectedNewProduct: null,
    productModalOpened: false,
    selectedToBeLinkedProduct: null,
    selectedLinkedProduct: null,
    appointmentModalOpened: false,
    appointmentForm: {},
    selectedBlindsColorImage: null,
    selectedBlindsColorImageExt: null,
    selectedBlindsColorImageInt: null,
    duplicateModalOpened: false,
    toBeDuplicatedCardItem: null,
    toBeDuplicatedWindowsItem: null,
    shareEmailModalOpened: false,

    // steps selections for BE
    selections: [],
    step1selections: null,
    step2selections: null,
    step3selections: null,
    step4selections: null,
    step5selections: null,
    step6selections: null,
    windows: [],

    // steps selections
    confHeightIsValid: false,
    confWidthIsValid: false,
    configuratorHeight: '',
    configuratorWidth: '',
    selectedDependsOns: [],
    selectedPrices: [],
    selectedAccessories: [],
    selectedAccessoriesItems: [],
    selectedAccessoriesPrices: [],
    selectedColorId: null,
    selected1stStep1stSection: null,
    selected2ndStep1stSection: null,
    selected2ndStep2ndSection: null,
    selected3rdStep1stSection: null,
    selected3rdStep2ndSection: null,
    selected3rdStep3rdSection: null,
    selected4thStep1stSection: null,
    selected4thStep2ndSection: null,
    selected4thStep3rdSection: null,
    selected5thStep1stSection: null,
    summaryCardsInfos: [],
  };

  globalState = (props, callback) => {
    if (props) {
      this.setState(props, callback && callback);
    } else {
      return this.state;
    }
  };

  // main card functions
  handleSelectedConfOptions = (label) => {
    this.setState({ selectedConfOption: label });
  };
  handleProjectName = (e) => {
    this.setState({
      projectName: e.target.value,
      appointmentForm: { projectName: e.target.value },
    });
  };
  handleStore = (element) => {
    this.setState({
      store: element && element.id,
      storeDetails: element,
    });
  };
  handleCreateButton = async () => {
    await getStepsLabels(this.globalState);
    await getStepsInfos(this.globalState);
  };

  // BE calls
  getStoreList = async () => {
    this.setState({
      isLoading: true,
    });
    await axios
      .get(STORE_LIST_API)
      .then((res) =>
        this.setState({ storeList: res.data.data, isLoading: false })
      )
      .catch((err) => {
        console.log('{getStoreList} error', err);
        this.setState({
          isLoading: false,
        });
      });
  };

  async componentDidMount() {
    if (env.USE_GUEST_USER) {
      getProfile({
        customerID: '',
        authToken: '',
      }).then(({ contactInfo, error }) =>
        setProfileAfterLogin(this.globalState, contactInfo)
      );
    } else {
      const r5token = getCookieValue('r5Token') || getCookieValue('r5token'); //document.cookie.split('r5token=')[1];
      const auth_cookie = document.cookie.split('auth_data=')[1];

      const authCookie = r5token || auth_cookie;
      if (authCookie) {
        try {
          const authCookieData = JSON.parse(
            decodeURIComponent(authCookie.split(';')[0])
          );
          getProfile({
            customerID: authCookieData.customerID,
            authToken: authCookieData.authenticationToken,
          }).then(({ contactInfo, error }) =>
            setProfileAfterLogin(this.globalState, contactInfo)
          );
        } catch (e) {
          const tokenString = authCookie.split(';')[0];
          const token = readR5Token(tokenString);
          if (token) {
            getProfile({
              customerID: token.external_id,
              authToken: undefined,
              r5Token: tokenString,
            }).then(({ contactInfo, error }) =>
              setProfileAfterLogin(this.globalState, contactInfo)
            );
          }
        }
      }
    }

    await getProjects(this.globalState);
    await this.getStoreList();
  }

  render() {
    const {
      configuratorHeight,
      configuratorWidth,
      configuratorsOptions,
      currentPrice,
      currentStep,
      isLogged,
      project,
      projects,
      projectName,
      loginModalOpened,
      selectedConfOption,
      selectedAccessories,
      selected1stStep1stSection,
      selected2ndStep1stSection,
      selected2ndStep2ndSection,
      selected3rdStep1stSection,
      selected3rdStep2ndSection,
      selected3rdStep3rdSection,
      selected4thStep1stSection,
      selected4thStep2ndSection,
      selected4thStep3rdSection,
      selected5thStep1stSection,
      store,
      storeList,
      storeDetails,
      credentials,
      modalOpened,
      modalClosed,
      modalMessage,
      isLoading,
      totalPrice,
      discountedTotalPrice,
      summaryCardsInfos,
      currentPreview,
      currentPreviewWindowsFront,
      currentPreviewWindowsBack,
      selectedBlindsColorImage,
      selectedBlindsColorImageExt,
      selectedBlindsColorImageInt,
      defExternal,
    } = this.state;

    const handleScriptLoad = () => {
      // eslint-disable-next-line
      /*  utag.view({
        page_name: ['configuratore', selectedConfOption, 'homepage', '0'],
        page_layout: detectDevice(),
      }); */
    };

    return (
      <div className={style.lmConfigurator}>
        {/* <Script
          url={`//tags.tiqcdn.com/utag/leroymerlinit/configuratori/prod/utag.js`}
          onLoad={handleScriptLoad.bind(this)}
        /> */}
        <LmLoader isLoading={isLoading} />

        <header>
          <LmConfiguratorHeader
            globalState={this.globalState}
            selectedConfOption={selectedConfOption}
            currentStep={currentStep}
            loginModalOpened={loginModalOpened}
            isLogged={isLogged}
            storeDetails={storeDetails}
            projectName={projectName}
            credentials={credentials}
            project={project}
            modalOpened={modalOpened}
            modalClosed={modalClosed}
            modalMessage={modalMessage}
          />
        </header>

        <>
          {/* homepage */}
          {currentStep === 0 && (
            <div className={style.container}>
              <LmHomepageMainCard
                globalState={this.globalState}
                handleSelectedConfOptions={this.handleSelectedConfOptions}
                handleProjectName={this.handleProjectName}
                handleStore={this.handleStore}
                handleCreateButton={this.handleCreateButton}
                configuratorsOptions={configuratorsOptions}
                credentials={credentials}
                project={project}
                store={store}
                storeDetails={storeDetails}
                isLogged={isLogged}
                projectName={projectName}
                projects={projects}
                selectedConfOption={selectedConfOption}
                storeList={storeList}
              />
            </div>
          )}

          {/* stepper page */}
          {currentStep !== 0 && (
            <LmConfiguratorTemplate
              globalState={this.globalState}
              handleStepNavigation={handleStepNavigation}
              handleChangeBack={() => stepsResetOnBackButton(this.globalState)}
              handleChangeNext={() => getStepsInfos(this.globalState)}
              i18nLabels={{
                Anteprima: i18next.t(
                  'windows.LM_SUMMARY_CARD_MOBILE.previewItem'
                ),
                Riepilogo: i18next.t(
                  'windows.LM_SUMMARY_CARD_MOBILE.summaryTitle'
                ),
                TipologiaInfisso: i18next.t(
                  'windows.LM_SUMMARY_CARD_MOBILE.fixtureType'
                ),
                Altezza: i18next.t('windows.LM_SUMMARY_CARD_MOBILE.height'),
                Larghezza: i18next.t('windows.LM_SUMMARY_CARD_MOBILE.width'),
                NumeroAnte: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.numberOfDoors'
                ),
                Apertura: i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.opening'),
                Materiale: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.material'
                ),
                internalView: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.internalView'
                ),
                externalView: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.externalView'
                ),
                totalPrice: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.totalPrice'
                ),
                priceWarning: i18next.t('windows.SUMMARY.priceWarning'),
                projectWarning: i18next.t('windows.SUMMARY.projectWarning'),
                externalColor: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.color'
                ),
                internalColor: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.colorSide'
                ),
                infoTitle: i18next.t('windows.SUMMARY.infoTitle'),
                askToCheck: i18next.t('windows.SUMMARY.askToCheck'),
                checkInfo1: i18next.t('windows.SUMMARY.checkInfo1'),
                checkInfoLink: i18next.t('windows.SUMMARY.checkInfoLink'),
                checkInfo2: i18next.t('windows.SUMMARY.checkInfo2'),
                implementation: i18next.t('windows.SUMMARY.implementation'),
                qualityLevel: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.levelOfQuality'
                ),
                aspect: i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.aspect'),
                implInfo1: i18next.t('windows.SUMMARY.implInfo1'),
                implInfo2: i18next.t('windows.SUMMARY.implInfo2'),
                implInfo3: i18next.t('windows.SUMMARY.implInfo3'),
                //link to PDF
                implInfo4: i18next.t('windows.SUMMARY.implInfo4'),
                currency: i18next.t('currency'),
                handle: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.addedAccessories'
                ),
                outsideView: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.outsideView'
                ),
                insideView: i18next.t(
                  'windows.LM_STEPPER_SUMMARY_INFOS.insideView'
                ),
              }}
              steps={stepsCreate(
                this.globalState,
                selectedConfOption === 'Zanzariere'
                  ? [
                      LmStepperMnetsStep1,
                      LmStepperMnetsStep2,
                      LmStepperMnetsStep3,
                      LmStepperMnetsStep4,
                      LmStepperMnetsStep5,
                      LmStepperMnetsStep6,
                      LmStepperMnetsStep7,
                    ]
                  : selectedConfOption === 'Tapparelle'
                  ? [
                      LmStepperBlindsStep1,
                      LmStepperBlindsStep2,
                      LmStepperBlindsStep3,
                      LmStepperBlindsStep4,
                      LmStepperBlindsStep5,
                    ]
                  : selectedConfOption === 'Finestre'
                  ? [
                      LmStepperWinStep1,
                      LmStepperWinStep2,
                      LmStepperWinStep3,
                      LmStepperWinStep4,
                      LmStepperWinStep5,
                      LmStepperWinStep6,
                    ]
                  : []
              )}
              currentPreview={currentPreview}
              currentPreviewWindowsFront={currentPreviewWindowsFront}
              currentPreviewWindowsBack={currentPreviewWindowsBack}
              currentStep={currentStep}
              currentPrice={currentPrice}
              selectedConfOption={selectedConfOption}
              configuratorHeight={configuratorHeight}
              configuratorWidth={configuratorWidth}
              selectedAccessories={selectedAccessories}
              selected1stStep1stSection={selected1stStep1stSection}
              selected2ndStep1stSection={selected2ndStep1stSection}
              selected2ndStep2ndSection={selected2ndStep2ndSection}
              selected3rdStep1stSection={selected3rdStep1stSection}
              selected3rdStep2ndSection={selected3rdStep2ndSection}
              selected3rdStep3rdSection={selected3rdStep3rdSection}
              selected4thStep1stSection={selected4thStep1stSection}
              selected4thStep2ndSection={selected4thStep2ndSection}
              selected4thStep3rdSection={selected4thStep3rdSection}
              selected5thStep1stSection={selected5thStep1stSection}
              totalPrice={totalPrice}
              discountedTotalPrice={discountedTotalPrice}
              summaryCardsInfos={summaryCardsInfos}
              selectedBlindsColorImage={selectedBlindsColorImage}
              selectedBlindsColorImageExt={selectedBlindsColorImageExt}
              selectedBlindsColorImageInt={selectedBlindsColorImageInt}
              defExternal={defExternal}
            />
          )}
        </>
      </div>
    );
  }
}
