import * as _ from 'lodash';

export default class PriceUtils {
    static readPrice(section, item, priceData, selections) {
        let price = 0;
        let selection, allowed;
        let fromMaterialType, windowTypeIds;

        switch (section.id) {
            case 3:
                priceData = _.orderBy(priceData, ['provider', 'price'], ['desc', 'asc']);
                // price = _.find(priceData, {provider: 'NOBENTO'});

                if (price === 0 || _.isNil(price)) {
                    price = _.first(priceData);
                }

                price = _.get(price, 'price', NaN);

                price = Number(price);

                if (isNaN(price)) {
                    return 0;
                }

                price = Math.ceil(price);

                break;

            case 4:
                allowed = _.get(item, ['meta', 'windowTypeIds'], []);
                selection = _.find(selections, {sectionId: 3});
                priceData = _.orderBy(_.filter(_.get(_.first(_.get(selection, 'values', [])), ['item', 'meta', 'priceData'], []), o => {
                    return allowed.indexOf(o.id) > -1;
                }), ['provider', 'price'], ['desc', 'asc']);

               // price = _.find(priceData, {provider: 'NOBENTO'});

                if ( price === 0 || _.isNil(price)) {
                    price = _.first(priceData);
                }                
                price = _.get(price, 'price', NaN);

                price = Number(price);

                if (isNaN(price)) {
                    return 0;
                }

                price = Math.ceil(price);
                break;

            case 5:
                priceData = _.orderBy(priceData, ['provider', 'price'], ['desc', 'asc']);
                price = _.first(priceData);
                price = _.get(price, 'price', NaN);
                price = Number(price);

               /*  if (isNaN(price)) {
                    return 0;
                } */

                if(!isNaN(price)){
                    price = Math.ceil(price);
                }

                break;
            case 6:
                fromMaterialType = selections[4].values[0];
                windowTypeIds = selections[3].values[0].item.meta.windowTypeIds;
                if(!section.meta.productVariants || section.meta.productVariants.length<=0){
                    return undefined;
                }
                const selectedColorSide = selections[5].values[1] ? selections[5].values[1].value.title:undefined;
                const selectedColor = selections[5].values[0] ? selections[5].values[0].value.title:undefined;
                
                 const foundColor = section.meta.productVariants.filter(p=> (p.stepSectionId === fromMaterialType.id) && (windowTypeIds.indexOf(p.windowTypeId)) >=0);
                
                if(foundColor && foundColor.length >0 && foundColor[0].variants){
                    const foundVariant  = foundColor.filter(a=>  a.variants.filter(c=>c.color_sides.indexOf(selectedColorSide)>=0).length>0)
                    const foundVariantColor = selectedColor? foundVariant.filter(f=>{
                        return f.variants.filter(c=>c.colors.indexOf(selectedColor)>=0).length>0;
                    }): undefined;
                    let changePrice = true;
                    if(selectedColor && (!foundVariantColor || foundVariantColor.length<=0)){
                        changePrice = false;
                    }
                    if(foundVariantColor && foundVariantColor.length>0){
                        price = Math.ceil(foundVariantColor[0].price);
                    }
                    else{
                        console.log('warning variant color not found', foundVariant, foundVariantColor);
                    }
                }  
                break;
            case 7:
                fromMaterialType = selections[4].values[0];
                const fromQLType = selections[6].values[0];
                windowTypeIds = selections[3].values[0].item.meta.windowTypeIds;
                if(!section.meta.productVariants || section.meta.productVariants.length<=0){
                    return undefined;
                }
                let foundQL = section.meta.productVariants.filter(p=> (p.stepSectionId === fromMaterialType.id) && (windowTypeIds.indexOf(p.windowTypeId)) >=0);
                if(!foundQL || foundQL.length<=0){
                    return undefined;
                }
                // v.stepSectionId === fromQLType.id &&
                
                let newPriceItem = foundQL[0].variants.filter(v => (v.quality === item));
                let hasPrice = newPriceItem ? newPriceItem.length>0:false;
                if(!hasPrice && section.meta.productVariantsColor && section.meta.productVariantsColor.length >0 ){
                    newPriceItem = section.meta.productVariantsColor.filter(p=> (p.stepSectionId === fromMaterialType.id) && (windowTypeIds.indexOf(p.windowTypeId)) >=0);//[0].variants.filter(v => (v.quality === item));
                    hasPrice = newPriceItem ? newPriceItem.length>0:false;
                    price = Math.ceil(newPriceItem[0].price);
                    break;
                }
                
                if(hasPrice) {
                    price = Math.ceil(foundQL[0].price);
                } 
           
                break;

            default:
                return price + priceData;

        }

        return price;
    }

    // static getPriceFromSelections(selections, currentStepId = null) {
    //     debugger;

    //     selections = _.orderBy(selections, ['sectionId'], ['asc']);
    //     let totalPrice = 0.000;
    //     let price, priceOverride, increase, accessoryOrColorIncrease;
    //     // su tutto il progetto deve sempre essere applicato un decremento presso dell'8%

    //     if (!_.isArray(selections)) {
    //         selections = [];
    //     }

    //     for (let selection of selections) {

    //         if ((currentStepId !== null && selection.section.stepId <= currentStepId) || currentStepId === null) {

    //             let values = [];

    //             if (_.isArray(selection.values)) {
    //                 values = _.orderBy(selection.values, ['id'], ['asc']);
    //             }
                
    //             let colorIncrease = 1;
    //             let accessoryIncrease =1;
    //             let prevIdColor= -1;
    //             for (let value of values) {
    //                 price = _.get(value, 'price', 0.00);
    //                 priceOverride = _.get(value, 'priceOverride', false);
    //                 // l'increase è l'incremento che viene calcolato in base alla sezione scelta del tipo ITEM_BUTTON (esempio Materiale)
    //                 increase = _.get(value, 'item.meta.increase', 1);
    //                 // accessoryOrColorIncrease è l'incremento percentuale che viene calcolato in base alla selezione di tipo ITEM_DATA (es Colore o accessorio)
    //                 accessoryOrColorIncrease = _.get(value, 'value.increase', 1);

    //                 // la logica di incremento prezzi dei colori è la seguente
    //                 if (selection.section.code==='COLORE'){
    //                     let idColor = _.get(value, 'value.id', 1);
    //                     //controllo se i colori selezionati sono uguali per fronte e retro
    //                     if(prevIdColor !== idColor) {
    //                         // se è già attivo un incremento e se ne deve applicare un secondo, il primo viene annullato e si applica un'unica maggiorazione del 30%
    //                         // altrimenti si applica l'incremento del colore che lo prevede
    //                         colorIncrease = colorIncrease > 1 && accessoryOrColorIncrease> 1 ? 1.3/colorIncrease :  accessoryOrColorIncrease;
    //                     }
    //                     else {
    //                         // i colori scelti sono uguali, si annulla l'incremento del secondo colore selezionato
    //                         colorIncrease = 1;
    //                     }
    //                     prevIdColor = idColor;

    //                 } else {
    //                     accessoryIncrease = accessoryOrColorIncrease;
    //                 }
                    
    //                 price *= increase;

    //                 if (priceOverride) {
    //                     totalPrice = price;
    //                 } else {
    //                     totalPrice += price;
    //                     totalPrice *= accessoryIncrease;
    //                     totalPrice *= colorIncrease;
    //                 }
    //             }
    //         }
    //     }
    //     return totalPrice;
    // }

    // static convertPrice(price) {
    //     return Math.ceil(price);
    // }
}