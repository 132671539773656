"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCommonKeyboardCombinations = isCommonKeyboardCombinations;
exports.getRelevantItems = getRelevantItems;
exports.isValidInput = isValidInput;
exports.isValidSelect = isValidSelect;

var _ramda = require("ramda");

function isCommonKeyboardCombinations(e) {
  if ((0, _ramda.contains)(e.keyCode, [46, 8, 9, 27, 13, 91, 110, 190]) || // Allow: Ctrl/cmd+A
  e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true) || // Allow: Ctrl/cmd+C
  e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true) || // Allow: Ctrl/cmd+V
  e.keyCode === 86 && (e.ctrlKey === true || e.metaKey === true) || // Allow: Ctrl/cmd+X
  e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true) || // Allow: home, end, left, right
  e.keyCode >= 35 && e.keyCode <= 39) {
    // let it happen, don't do anything
    return true;
  } else {
    return false;
  }
}

function getRelevantItems(items, labelKey, search) {
  var regex = new RegExp("^(".concat(search.toLowerCase(), ")"), 'i');
  return items.filter(function (item) {
    return item[labelKey] && item[labelKey].constructor === String && regex.test(item[labelKey]);
  });
}

function isValidInput(input) {
  return {
    validity: input.validity.valid,
    message: input.validationMessage
  };
}

function isValidSelect(element, valueKey, required) {
  return {
    validity: required && element || !required,
    message: required && !element ? 'Please select an option' : ''
  };
}