import axios from 'axios';
import { MAIL_PDF } from '../api';
import { submitProjectFast } from './projectFunctions.js';
import { session, handleLoginModal } from './loginFunctions';
import { sendAnalytics } from './index';
import i18next from "../i18n";

const handleAppointmentRequestModal = (globalState) => {
  const state = globalState();
  globalState({
    appointmentModalOpened: !state.appointmentModalOpened,
  })
}

const requestReducer = (_state) => {
  const { store, projectName, totalPrice, windows, isLogged, appointmentForm } = _state;
  const { name, surname, email, phonenumber, note } = appointmentForm;
  return {
    title: appointmentForm.projectName || projectName || '', // string Titolo del progetto
    to: store || '', // integer ID del negozio
    clientFirstName: name || '',// string Nome del cliente
    clientLastName: surname || '',// string Cognome del cliente
    clientEmail: email || '',// string Email del cliente
    clientPhone: phonenumber || '',// string Numero di telefono del cliente
    note: note || '',// string Note del progetto per il negozio
    acceptedPrivacy: isLogged.entityExtensions?.PrivacyFlags?.flagMap['PRIVACY.PROTECTION.ACCEPTED'] || '',// boolean Accettazione o meno della normativa sulla privacy da parte del cliente
    price: totalPrice || '',// number The total price
    windows: windows || [], // all items
    lmUniqueId: isLogged.mainContact?.uuid || '', // string nullable: true Leroy Merlin Logged In User Unique Identifier
    subject: '',
    textEmail: '',
    windowBackGroundImgPaths: _state.windowBackGroundImgPaths
  }
}

const simpleSendMail = async (postParams) => {

  return await axios.post(
    MAIL_PDF,
    postParams
  )
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      return error;
    })
}


const sendMailToUser = async (globalState) => {
  const state = globalState();
  const { appointmentForm, projectCode } = state;
  const { email } = appointmentForm;
  const postParams = requestReducer(state);
  const logoImage = require('../../assets/images/email_logo.png');
  // USER CASE - SaveProject
  var postParamsUser = {
    ...postParams,
    to: email,
    subject: 'Wycena okien na wymiar z konfiguratora Leroy Merlin',
    logoImgPath: logoImage,
    textEmail: `${i18next.t('windows.emailCustomer.row1')}<br/><br/>${i18next.t('windows.emailCustomer.row2')}<br/><br/>${i18next.t('windows.emailCustomer.row3')}${projectCode}<br/><br/><br/>${i18next.t('windows.emailCustomer.row4')}<br/><br/>${i18next.t('windows.emailCustomer.row5')}<br/><br/><br/><a href="https://www.leroymerlin.pl/polityka-prywatnosci.html" target="_blank">${i18next.t('windows.emailCustomer.rowPrivacy')}</a>`
  }
  globalState({ isLoading: true });

  await simpleSendMail(postParamsUser);
}

const handleAppointmentRequest = (globalState) => {
  const state = globalState();
  const { appointmentForm, isLogged, projectCode } = state;
  let { store } = state;
  let { email} = appointmentForm;
  let { projectName} = appointmentForm;
  const postParams = requestReducer(state);

  console.log("projectCode => " + projectCode);
  console.log("projectName => " + projectName);
  console.log("store => " + store);

  sendAnalytics('richiedi-appuntamento', state)
  
  if (isLogged) {
    // fetch
    globalState({ isLoading: true, appointmentModalOpened: false });
    
    //if (projectCode) {
      globalState({ isLoading: true })

    //emailStore.row1, ...
    //emailCustomer.row1, ...

    const emailStore = {
        "subject": "Wycena okien na wymiar z konfiguratora Leroy Merlin",
        "row1": "Właśnie otrzymaliście prośbę o zorganizowanie pomiaru okien w domu klienta.",
      "row2": "Projekt i dane klienta w pliku PDF",
      "row3": "Pozdrawiamy,",
      "row4": "Leroy Merlin"
    };

    const emailCustomer = {
      "subject": "Wycena okien na wymiar z konfiguratora Leroy Merlin",
      "row1": "Drogi Kliencie,",
      "row2": "W załączniku przesyłamy Ci szacunkową wycenę projektu okien na wymiar, zgodnie z Twoją kalkulacją ze strony okna.leroymerlin.pl.",
      "row3": "Kod projektu to: ",
      "row4": "Z wyrazami szacunku,",
      "row5": "Leroy Merlin",
      "rowPrivacy": "Polityka prywatności"
    }
    const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    if (isLocalhost === true) {
      store = "luigi.bifulco@ext.leroymerlin.it";
      email = "alessandro.marocchini@ext.leroymerlin.it";
    }

    const logoImage = require('../../assets/images/email_logo.png');

      // STORE CASE - Appointment
      var postParamsStore = {
        ...postParams,
        to: store,
        subject: i18next.t('windows.emailStore.subject'),
        textEmail: `${i18next.t('windows.emailStore.row1')}<br/><br/>${i18next.t('windows.emailStore.row2')}<br/><br/>${i18next.t('windows.emailStore.row3')}<br/>${i18next.t('windows.emailStore.row4')}`,
        logoImgPath: logoImage
      };



      // USER CASE - Appointment
      var postParamsUser = {
        ...postParams,
        to: email,
        subject: 'Wycena okien na wymiar z konfiguratora Leroy Merlin',
        textEmail: `${i18next.t('windows.emailCustomer.row1')}<br/>${i18next.t('windows.emailCustomer.row2')}<br/><br/>${i18next.t('windows.emailCustomer.row3')}${projectName}<br/><br/>${i18next.t('windows.emailCustomer.row4')}<br/><br/>${i18next.t('windows.emailCustomer.row5')}<br/><a href="https://www.leroymerlin.pl/polityka-prywatnosci.html" target="_blank">${i18next.t('windows.emailCustomer.rowPrivacy')}</a>`,
        logoImgPath: logoImage
      }

      sendMailToUserAndStore(globalState, postParamsUser, postParamsStore);
    /* } else {
      submitProjectFast(globalState, isLogged).then((r) => {
        globalState({ isLoading: true, projectCode: r.data.code })

        const projectCode = r.data.code;

        // STORE CASE - Appointment
        var postParamsStore = {
          ...postParams,
          to: store,
          subject: 'Configuratore Finestre Leroy Merlin - Richiesta appuntamento',
          textEmail: `Hai appena ricevuto una richiesta di appuntamento per un nuovo progetto Finestre.<br/><br/> Il codice del progetto è ${projectCode}. ${(postParams.note !== '') ? `<br /><br />Il cliente ha inserito le seguenti note: <br />${postParams.note}<br /><br />` : ''}`
        };


        // USER CASE - Appointment
        var postParamsUser = {
          ...postParams,
          to: email,
          subject: 'Configuratore Finestre Leroy Merlin - Richiesta appuntamento',
          textEmail: `Gentile Cliente, <br/>la sua richiesta di appuntamento è stata inviata correttamente.<br/>Appena sarà possibile verrà contattato a questo indirizzo da un responsabile del settore competente.<br/><br/> Il codice del progetto è ${projectCode}. ${(postParams.note !== '') ? `<br /><br />Sono state inserite le seguenti note:<br />${postParams.note}<br /><br />` : ''}`
        }      
        sendMailToUserAndStore(globalState, postParamsUser, postParamsStore);
      });
    } */
  } else {
    // login then fetch
    session('takingAppointment', true)
    handleLoginModal(globalState);
  }


}



const sendMailToUserAndStore = async (globalState, postParamsUser, postParamsStore) => {
  globalState({ isLoading: true });
  
  simpleSendMail(postParamsUser).then(() => {
    globalState({
      isLoading: true
    });
    simpleSendMail(postParamsStore).then((data) => {
      globalState({
        isLoading: false,
        modalOpened: true,
        modalMessage: {
          title: 'Prośba wysłana',
          text: "Wiadomość e-mail przesłana poprawnie",
          callback: () => {
            // Redirect in home?
            // window.location.href = "./";
          }
        }
      });
    }).catch(error => {
      if (error?.response?.data?.message?.msg) {
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: { title: `Błąd`, text: error?.response?.data?.message?.msg }
        })
      }
    })
  }).catch(error => {
    if (error?.response?.data?.message?.msg) {
      globalState({
        isLoading: false,
        modalOpened: true,
        modalMessage: { title: `Błąd`, text: error?.response?.data?.message?.msg }
      })
    }
  })
  // SEND TO STORE
  // await axios
  //   .post(
  //     MAIL_PDF,
  //     postParamsUser
  //   )
  //   .then((data_1) => {

  //     if (postParamsStore){
  //     // SEND TO USER  
  //     axios
  //       .post(
  //         MAIL_PDF,
  //         postParamsStore
  //       )
  //       .then(({ data_2 }) => {
  //         globalState({
  //           isLoading:false,
  //           modalOpened: true,
  //           modalMessage: {
  //             title: 'Richiesta inviata',
  //             text: data_2.message.msg,
  //             callback: () => {
  //               // Redirect in home?
  //               // window.location.href = "./";
  //             }
  //           }
  //         });
  //       })
  //       .catch(error => {
  //         if (error?.response?.data?.message?.msg) {
  //           globalState({
  //             isLoading: false,
  //             modalOpened: true,
  //             modalMessage: { title: `Errore`, text: error?.response?.data?.message?.msg }
  //           })
  //         }
  //       })
  //     } else {
  //       globalState({
  //         isLoading: false,
  //         modalOpened: true,
  //         modalMessage: {
  //           title: 'Richiesta inviata',
  //           text: data_1.message.msg,
  //           callback: () => {
  //             // Redirect in home?
  //             // window.location.href = "./";
  //           }
  //         }
  //       });
  //     }


  //   })
  //   .catch(error => {
  //     if (error?.response?.data?.message?.msg) {
  //       globalState({
  //         isLoading: false,
  //         modalOpened: true,
  //         modalMessage: { title: `Errore`, text: error?.response?.data?.message?.msg }
  //       })
  //     }
  //   })
}

export { handleAppointmentRequestModal, handleAppointmentRequest, requestReducer, sendMailToUser };
