import axios from 'axios';
import { MAIL_PDF_SHARE } from '../api';
// import { submitProjectFast } from './projectFunctions.js';
// import { session, handleLoginModal } from './loginFunctions';
import { sendAnalytics } from './index';
import i18next from "../i18n";

const handleShareRequestModal = (globalState) => {
  const state = globalState();
  globalState({
    shareEmailModalOpened: !state.shareEmailModalOpened,
  });
};

const requestReducer = (_state) => {
  const {
    store,
    projectName,
    totalPrice,
    windows,
    isLogged,
    appointmentForm,
    projectCode
  } = _state;
  const { note } = appointmentForm;
  return {
    title: appointmentForm.projectName || projectName || '', // string Titolo del progetto
    to: store || '', // integer ID del negozio
    note: note || '', // string Note del progetto per il negozio
    acceptedPrivacy:
      isLogged.entityExtensions?.PrivacyFlags?.flagMap[
        'PRIVACY.PROTECTION.ACCEPTED'
      ] || '', // boolean Accettazione o meno della normativa sulla privacy da parte del cliente
    price: totalPrice || '', // number The total price
    windows: windows || [], // all items
    lmUniqueId: isLogged.mainContact?.uuid || '', // string nullable: true Leroy Merlin Logged In User Unique Identifier
    subject: '',
    textEmail: '',
    windowBackGroundImgPaths: _state.windowBackGroundImgPaths
  };
};

const simpleSendMailShare = async (postParams) => {
  return await axios
    .post(MAIL_PDF_SHARE, postParams)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

const handleShareRequest = (globalState, values) => {
  const state = globalState();
  let email = values.map((el) => el.value);
  const postParams = requestReducer(state);

  sendAnalytics('condividi-infissi-email', state);
  console.log(
    'handleAppointmentRequest - sendAnalytics - condividi-infissi-email'
  );

  // fetch
  globalState({ isLoading: true, shareEmailModalOpened: false });
  const { projectCode } = state;
  const logoImage = require('../../assets/images/email_logo.png');

  // USER CASE - Appointment
  var postParamsUser = {
    ...postParams,
    to: email,
    logoImgPath: logoImage,
    
    subject: 'Wycena okien na wymiar z konfiguratora Leroy Merlin',
    textEmail: `${i18next.t('windows.emailCustomer.row1')}<br/><br/>${i18next.t('windows.emailCustomer.row2')}<br/><br/>${i18next.t('windows.emailCustomer.row3')}${projectCode}<br/><br/><br/>${i18next.t('windows.emailCustomer.row4')}<br/><br/>${i18next.t('windows.emailCustomer.row5')}<br/><br/><br/><a href="https://www.leroymerlin.pl/polityka-prywatnosci.html" target="_blank">${i18next.t('windows.emailCustomer.rowPrivacy')}</a>`
  }

  sendMailToShare(globalState, postParamsUser);
};

const sendMailToShare = async (globalState, postParamsUser) => {
  globalState({ isLoading: true });

  simpleSendMailShare(postParamsUser)
    .then((data) => {
      globalState({
        isLoading: true,
      });
      globalState({
        isLoading: false,
        modalOpened: true,
        modalMessage: {
          title:
            'Przeprowadzono udostępnianie wyceny za pośrednictwem poczty elektronicznej',
          text: data.message.msg,
          callback: () => {
            // Redirect in home?
            // window.location.href = "./";
          },
        },
      });
    })
    .catch((error) => {
      if (error?.response?.data?.message?.msg) {
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: {
            title: `Errore`,
            text: error?.response?.data?.message?.msg,
          },
        });
      }
    });
  // SEND TO STORE
  // await axios
  //   .post(
  //     MAIL_PDF,
  //     postParamsUser
  //   )
  //   .then((data_1) => {

  //     if (postParamsStore){
  //     // SEND TO USER
  //     axios
  //       .post(
  //         MAIL_PDF,
  //         postParamsStore
  //       )
  //       .then(({ data_2 }) => {
  //         globalState({
  //           isLoading:false,
  //           modalOpened: true,
  //           modalMessage: {
  //             title: 'Richiesta inviata',
  //             text: data_2.message.msg,
  //             callback: () => {
  //               // Redirect in home?
  //               // window.location.href = "./";
  //             }
  //           }
  //         });
  //       })
  //       .catch(error => {
  //         if (error?.response?.data?.message?.msg) {
  //           globalState({
  //             isLoading: false,
  //             modalOpened: true,
  //             modalMessage: { title: `Errore`, text: error?.response?.data?.message?.msg }
  //           })
  //         }
  //       })
  //     } else {
  //       globalState({
  //         isLoading: false,
  //         modalOpened: true,
  //         modalMessage: {
  //           title: 'Richiesta inviata',
  //           text: data_1.message.msg,
  //           callback: () => {
  //             // Redirect in home?
  //             // window.location.href = "./";
  //           }
  //         }
  //       });
  //     }

  //   })
  //   .catch(error => {
  //     if (error?.response?.data?.message?.msg) {
  //       globalState({
  //         isLoading: false,
  //         modalOpened: true,
  //         modalMessage: { title: `Errore`, text: error?.response?.data?.message?.msg }
  //       })
  //     }
  //   })
};

export {
  handleShareRequestModal,
  handleShareRequest,
  requestReducer,
  sendMailToShare,
};
