import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
// imports from the lm-design-system library
import { LmImage, LmLink } from '@lm/lm-design-system/dist/components';

// images
import arrowDown from '../../assets/images/arrow-down.svg';
import arrowUp from '../../assets/images/arrow-up.svg';
import externalView from '../../assets/images/external-view.jpg';
import externalView2 from '../../assets/images/external-view-2.jpg';
import externalView3 from '../../assets/images/external-view-3.jpg';
import internalView from '../../assets/images/internal-view.jpg';
import internalView2 from '../../assets/images/internal-view-2.jpg';
import internalView3 from '../../assets/images/internal-view-3.jpg';

export default class LmStepperSummaryInfos extends React.Component {
  state = {
    showDetails: false,
  };

  toggleDetails = () => this.setState({ showDetails: !this.state.showDetails });

  render() {
    const { showDetails } = this.state;
    const {
      globalState,
      el,
      changeQuantity,
      handleLinkProductModal,
      handleModify,
      isFromProjectCard,
      handleSummaryCardDuplicateModal,
    } = this.props;

    const externalColor = i18next.t(
      'windows.LM_SUMMARY_CARD_MOBILE.externalColor'
    );
    const internalColor = i18next.t(
      'windows.LM_SUMMARY_CARD_MOBILE.internalColor'
    );
    const fixtureType = i18next.t('windows.LM_SUMMARY_CARD_MOBILE.fixtureType');
    const itemHeight = i18next.t('windows.LM_SUMMARY_CARD_MOBILE.height');
    const itemWidth = i18next.t('windows.LM_SUMMARY_CARD_MOBILE.width');
    const itemOpening = i18next.t('windows.LM_SUMMARY_CARD_MOBILE.opening');
    const numberOfDoors = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.numberOfDoors'
    );
    const viewDetails = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.viewDetails'
    );
    const fixed = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.fixed');
    const netting = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.netting');
    const itemQuality = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.quality');
    const colorType = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.colorType');
    const itemColor = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.color');
    const categorizations = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.categorizations'
    );
    const addedAccessories = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.addedAccessories'
    );
    const hideDetails = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.hideDetails'
    );
    const itemMaterial = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.material');
    const itemModel = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.model');
    const levelOfQuality = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.levelOfQuality'
    );
    // const itemSecurity = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.security');
    const itemAspect = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.aspect');
    const itemHandle = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.handle');
    const totalPrice = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.totalPrice');
    const addCoordinatedProduct = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.addCoordinatedProduct'
    );
    const itemModify = i18next.t('windows.LM_STEPPER_SUMMARY_INFOS.modify');
    const itemDuplicate = i18next.t(
      'windows.LM_STEPPER_SUMMARY_INFOS.duplicate'
    );

    return (
      <>
        <div className={style.summaryInfos}>
          <div className={style.summaryTitle}>
            <h2> {el.cardName} </h2>
          </div>
          <div className={style.summaryDetails}>
            <div className={style.summaryDetailsImg}>
              {el.selectedConfOption === 'Zanzariere' && (
                <LmImage src={el.preview} />
              )}
              {el.selectedConfOption === 'Tapparelle' && (
                <>
                  <LmImage src={el.preview} />

                  {el.selection3 === 'DUERO' ? (
                    <>
                      {el.colorPreviewExt && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            margin: '10px',
                          }}
                        >
                          <div>{externalColor}</div>
                          <img
                            src={el.colorPreviewExt}
                            width="80"
                            height="80"
                            alt="ext color preview"
                          />
                        </div>
                      )}
                      {el.colorPreviewInt && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            margin: '10px',
                          }}
                        >
                          <div>{internalColor}</div>
                          <img
                            src={el.colorPreviewInt}
                            width="80"
                            height="80"
                            alt="int color preview"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {el.colorPreview && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            margin: '10px',
                          }}
                        >
                          <div>{itemColor}: </div>
                          <img
                            src={el.colorPreview}
                            width="80"
                            height="80"
                            alt="color preview"
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {el.selectedConfOption === 'Finestre' && (
                <>
                  <div
                    style={{
                      padding: '0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      boxSizing: 'border-box',
                      margin: '20px 0',
                    }}
                  >
                    <img
                      alt="preview img front"
                      src={el.previewFront}
                      style={{
                        height: Number(el.height) + 50 + 'px',
                        width: Number(el.width) + 50 + 'px',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        backgroundImage: `url(
                            ${
                              el.selection9 === 'Matowe mleczne szkło'
                                ? externalView2
                                : el.selection9 === 'Vetro specchiato'
                                ? externalView3
                                : externalView
                            }
                          )`,
                      }}
                    />
                  </div>
                  {showDetails && (
                    <div
                      style={{
                        padding: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        boxSizing: 'border-box',
                        margin: '20px 0',
                      }}
                    >
                      <img
                        alt="preview img back"
                        src={el.previewBack}
                        style={{
                          height: Number(el.height) + 50 + 'px',
                          width: Number(el.width) + 50 + 'px',
                          maxHeight: '100%',
                          maxWidth: '100%',
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                          backgroundImage: `url(
                              ${
                                el.selection9 === 'Matowe mleczne szkło'
                                  ? internalView2
                                  : el.selection9 === 'Vetro specchiato'
                                  ? internalView3
                                  : internalView
                              }
                            )`,
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={style.summaryDetailsSpecifics}>
              {el.selectedConfOption === 'Zanzariere' && (
                <>
                  <p>
                    {i18next.t('windows.LM_SUMMARY_CARD_MOBILE.externalColor')}
                    {el.selection1}
                  </p>
                  <p>
                    {itemHeight} {el.height} cm
                  </p>
                  <p>
                    {itemWidth} {el.width} cm
                  </p>
                  <p>
                    {itemOpening} {el.selection2}
                  </p>
                  <p>
                    {numberOfDoors} {el.selection3}
                  </p>
                  {!showDetails && (
                    <LmLink handleClick={this.toggleDetails}>
                      {viewDetails}
                      <img
                        className={style.arrow}
                        src={arrowDown}
                        alt="arrow down"
                      />
                    </LmLink>
                  )}
                  {showDetails && (
                    <>
                      <p>
                        {fixed} {el.selection4}
                      </p>
                      <p>
                        {netting} {el.selection5}
                      </p>
                      <p>
                        {itemQuality} {el.selection6}
                      </p>
                      <p>
                        {colorType} {el.selection7}
                      </p>
                      <p>
                        {itemColor} {el.selection8}
                      </p>
                      <p>
                        {categorizations} {el.selection9}
                      </p>
                      <p>
                        <b>{addedAccessories}</b>
                      </p>
                      {Object.values(el['accessories']).map((el, i) => {
                        return (
                          <p key={`card-accessories-nets-${i}`}>
                            {el !== undefined ? el.label : ''}
                          </p>
                        );
                      })}
                      <LmLink handleClick={this.toggleDetails}>
                        {hideDetails}
                        <img
                          className={style.arrow}
                          src={arrowUp}
                          alt="arrow up"
                        />
                      </LmLink>
                    </>
                  )}
                </>
              )}
              {el.selectedConfOption === 'Tapparelle' && (
                <>
                  <p>
                    {i18next.t('windows.LM_SUMMARY_CARD_MOBILE.externalColor')}
                    {el.selection1}
                  </p>
                  <p>
                    {itemHeight} {el.height} cm
                  </p>
                  <p>
                    {itemWidth} {el.width} cm
                  </p>
                  <p>
                    {itemMaterial} {el.selection2}
                  </p>
                  <p>
                    {itemModel} {el.selection3}
                  </p>
                  {!el.selection5 && (
                    <p>
                      {itemColor} {el.selection4}
                    </p>
                  )}
                  {el.selection5 && (
                    <>
                      <p>
                        {externalColor} {el.selection4}
                      </p>
                      <p>
                        {internalColor} {el.selection5}
                      </p>
                    </>
                  )}
                  {/* {!showDetails && 
                    <LmLink handleClick={this.toggleDetails}>
                      {viewDetails} 
                      <img className={style.arrow} src={arrowDown} alt="arrow down"/> 
                    </LmLink>
                  }
                  {showDetails &&
                    <> */}
                  <p>
                    <b>{addedAccessories}</b>
                  </p>
                  {Object.values(el['accessories']).map((el, i) => {
                    return (
                      <p key={`card-accessories-blinds-${i}`}>
                        {el !== undefined ? el.label : ''}
                      </p>
                    );
                  })}
                  {/* <LmLink handleClick={this.toggleDetails}>
                        Nascondi dettagli 
                        <img className={style.arrow} src={arrowUp} alt="arrow up"/> 
                      </LmLink>
                    </>
                  } */}
                </>
              )}
              {el.selectedConfOption === 'Finestre' && (
                <>
                  <p>
                    {fixtureType} {el.selection1}
                  </p>
                  <p>
                    {itemHeight} {el.height} cm
                  </p>
                  <p>
                    {itemWidth} {el.width} cm
                  </p>
                  <p>
                    {numberOfDoors} {el.selection2}
                  </p>
                  <p>
                    {itemOpening} {el.selection3}
                  </p>
                  {!showDetails && (
                    <LmLink handleClick={this.toggleDetails}>
                      {viewDetails}
                      <img
                        className={style.arrow}
                        src={arrowDown}
                        alt="arrow down"
                      />
                      <a className={style.arrowIcon}>
                        <span className={style.leftBar}></span>
                        <span className={style.rightBar}></span>
                      </a>
                    </LmLink>
                  )}
                  {showDetails && (
                    <>
                      <p>
                        {itemMaterial} {el.selection4.replace('<br />', '')}
                      </p>
                      <p>
                        {el.selection111} {el.selection99}
                      </p>
                      <p>
                        {el.selection122} {el.selection100}
                      </p>
                      {/* {el.selection4 === 'Legno' && (
                        <p>
                          {itemColor} {el.selection6}
                        </p>
                      )} */}
                      {/* {el.selection4 !== 'Legno' && (
                        <>
                          <p>
                            {externalColor} {el.selection5}
                          </p>
                          <p>
                            {internalColor} {el.selection6}
                          </p>
                        </>
                      )} */}
                      <p>
                        {levelOfQuality} {el.selection7}
                      </p>
                      {/* <p>
                        {itemSecurity} {el.selection8}
                      </p> */}
                      <p>
                        {itemAspect} {el.selection9}
                      </p>
                      <p>
                        {addedAccessories} {el.selection10}
                      </p>
                      <LmLink handleClick={this.toggleDetails}>
                        {hideDetails}
                        <img
                          className={style.arrow}
                          src={arrowUp}
                          alt="arrow up"
                        />
                      </LmLink>
                    </>
                  )}
                </>
              )}
            </div>

            <div className={style.summaryDetailsNumbers}>
              <div
                className={style.numberButtonDecr}
                onClick={
                  el.quantity > 1
                    ? () => changeQuantity(globalState, 'remove', el.cardName)
                    : null
                }
              >
                <span> - </span>
              </div>
              <div className={style.quantity}>
                <span> {el.quantity} </span>
              </div>
              <div
                className={style.numberButtonIncr}
                onClick={() => changeQuantity(globalState, 'add', el.cardName)}
              >
                <span> + </span>
              </div>
            </div>
          </div>
          <div className={style.summaryPrice}>
            <h2>
              {totalPrice} {Number(el.price).toFixed(2).replace('.', ',')} zł
            </h2>
          </div>
        </div>

        {!isFromProjectCard && (
          <div className={style.projectActions}>
            {/* <p
              onClick={() =>
                handleLinkProductModal(
                  globalState,
                  el.selectedConfOption,
                  el.height,
                  el.width,
                  el.selection1,
                  el.group
                )
              }
            >
              {addCoordinatedProduct}
            </p> */}
            <p onClick={() => handleModify(globalState, el.id, el.code)}>
              {itemModify}
            </p>
            <p
              onClick={() =>
                handleSummaryCardDuplicateModal(globalState, el.id, el.code)
              }
            >
              {itemDuplicate}
            </p>
          </div>
        )}
      </>
    );
  }
}
