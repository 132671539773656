import { env } from './env';
import axios from 'axios';

// REACT_APP_LM_API deve essere api-gw-qa in dev, api in prod
// REACT_APP_LM_APIKEY deve essere testToken in dev, wYv7iVtxEU in prod

const BASE_URL_API = env.REACT_APP_API_URL;

const STORE_LIST_API = BASE_URL_API + 'static-stores';

const STEPPER_STEPS_API = BASE_URL_API + 'steps';
const STEPS_INFOS_API = BASE_URL_API + 'sections';
const STEP_COLORS_LIST_API = BASE_URL_API + 'colors';

const PROJECT = BASE_URL_API + 'project';
const PROJECT_LOGGED = BASE_URL_API + 'project/logged';

const GET_PROJECT = BASE_URL_API + 'project/search';
const GET_PROJECT_PDF = BASE_URL_API + 'generate_pdf';

const FAST_TRACK = BASE_URL_API + 'project/fast-track';

const MAIL_PDF = BASE_URL_API + 'mail_pdf';
// api per inviare email tramite condividi infissi
const MAIL_PDF_SHARE = BASE_URL_API + 'mail_pdf_share';
const LM_DOMAIN = `https://${env.REACT_APP_LM_API}.leroymerlin.it`;
const LM_BASE_URL_API = `${LM_DOMAIN}/api/v1/customer/1_0_0`;
const LM_LOGIN = LM_BASE_URL_API + '/authentication/login';
const LM_PROFILE = LM_BASE_URL_API + '/profilelight/get/';

const APIKEY = env.REACT_APP_LM_APIKEY;

const getLocale = async (lng) => {
  const result = await axios.get(
    `${BASE_URL_API}locales/${lng}/translation.json`
  );
  return result;
};

export {
  STORE_LIST_API,
  STEPPER_STEPS_API,
  STEPS_INFOS_API,
  STEP_COLORS_LIST_API,
  PROJECT,
  PROJECT_LOGGED,
  FAST_TRACK,
  MAIL_PDF,
  MAIL_PDF_SHARE,
  LM_LOGIN,
  LM_PROFILE,
  APIKEY,
  GET_PROJECT,
  GET_PROJECT_PDF,
  getLocale,
};

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //console.log("axios request interceptor", config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("axios response interceptor", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
