import _ from 'lodash';
import axios from 'axios';
import PriceUtils from '../../utils/commons/windowsPriceUtils';
import { STEP_COLORS_LIST_API } from '../../utils/api';
import {
  stepInfosItemMnetsNewOptions,
  stepInfosItemBlindsNewOptions,
  stepInfosItemWindowsNewOptions,
  stepInfosItemMnetsColorsOptions,
  stepInfosItemBlindsColorsOptions,
  stepInfosItemWindowsColorsOptions,
  sectionFiltered,
  stepSizesValueItem,
  stepGenericValueItem,
  stepAccessoriesItem,
  windowsFilterSelectedById,
  windowsFilteredSelectedList,
  windowsFilterDataFromList,
  stepInfosWindowsItem,
} from './genericStepperFunctions';

import externalView from '../../assets/images/external-view-2.jpg';
import externalViewClear from '../../assets/images/external-view.jpg';
import externalView2 from '../../assets/images/external-view-2.jpg';
import externalView3 from '../../assets/images/external-view-3.jpg';
import internalView from '../../assets/images/internal-view.jpg';
import internalView2 from '../../assets/images/internal-view-2.jpg';
import internalView3 from '../../assets/images/internal-view-3.jpg';
import i18next from '../i18n';

const getallImagesBackground = () => {
  let background = {};
  background.externalViewClear = externalViewClear;
  background.internalViewClear = internalView;
  background.internalViewFrosted = internalView2;
  background.externalViewFrosted = externalView2;

  return background;
};

const getImagesBackground = (selected4thStep3rdSection) => {
  let background = [];
  if (selected4thStep3rdSection === 'Matowe mleczne szkło') {
    background[0] = externalView2;
    background[1] = internalView2;
  } else {
    if (selected4thStep3rdSection === 'Vetro specchiato') {
      background[0] = externalView3;
      background[1] = internalView3;
    } else {
      background[0] = externalView;
      background[1] = internalView;
    }
  }
  return background;
};

// stepper sections handlers
const handleConfiguratorHeight = (globalState, value, min, max) => {
  const state = globalState();
  const { selections, selectedConfOption } = state;

  let newSelections = [...selections];

  // check if the value is between min and max height
  const isValid = value >= min / 10 && value <= max / 10;

  // search for the selected item from the BE selections object
  // create the selections.values item
  const step1height = newSelections[1].section.items.find(
    (el) => el.id === 201
  );
  newSelections[1].values[0] = stepSizesValueItem(step1height, value);

  if (selectedConfOption === 'Finestre') {
    newSelections[1].values[0].price = 0;
  }

  // state update then stepperCanProceed function for validation
  globalState(
    {
      selections: newSelections,
      configuratorHeight: value,
      confHeightIsValid: isValid,
    },
    () => stepperCanProceed(globalState, 1)
  );
};

const handleConfiguratorWidth = (globalState, value, min, max) => {
  const state = globalState();
  const { selections, selectedConfOption } = state;

  let newSelections = [...selections];

  // check if the value is between min and max width
  const isValid = value >= min / 10 && value <= max / 10;

  // search for the selected item from the BE selections object
  // create the selections.values item
  const step1width = newSelections[1].section.items.find((el) => el.id === 200);
  newSelections[1].values[1] = stepSizesValueItem(step1width, value);

  if (selectedConfOption === 'Finestre') {
    newSelections[1].values[1].price = 0;
  }

  // state update then stepperCanProceed function for validation
  globalState(
    {
      selections: newSelections,
      configuratorWidth: value,
      confWidthIsValid: isValid,
    },
    () => stepperCanProceed(globalState, 1)
  );
};

const handleSelected1stStep1stSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedConfOption,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };

  // search for the selected item from the BE selections object
  // create the selections.values item
  const step1section1item = newSelections[0].section.items.find(
    (el) => el.id === id
  );
  newSelections[0].values = stepGenericValueItem(step1section1item);

  if (selectedConfOption === 'Finestre') {
    newSelections[0].values[0].price = 0;
  }

  // add the dependsOn of the selected item in the selectedDependsOns state
  newSelectedDependsOns[0] = step1section1item.id;

  // reset for modify summary card when you select something else
  if (windows.length >= 1) {
    const toModifyCardItem = summaryCardsInfos.find(
      (item) => item.selection1 === step1section1item.title
    );

    if (!toModifyCardItem) {
      if (selectedConfOption === 'Zanzariere') {
        globalState({
          currentPrice: 0,
          selected2ndStep1stSection: null,
          selected2ndStep2ndSection: null,
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [
            true,
            false,
            false,
            false,
            false,
            true,
            true,
          ],
        });
      }
      if (selectedConfOption === 'Tapparelle') {
        globalState({
          currentPrice: 0,
          selected2ndStep1stSection: null,
          selected2ndStep2ndSection: null,
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedBlinds: [true, false, false, true, true],
        });
      }
      if (selectedConfOption === 'Finestre') {
        globalState({
          currentPrice: 0,
          selected2ndStep1stSection: null,
          selected2ndStep2ndSection: null,
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, false, false, false, false, true],
        });
      }
    }
  }

  // state update then stepperCanProceed function for validation
  globalState(
    {
      selections: newSelections,
      selected1stStep1stSection: label,
      selectedDependsOns: newSelectedDependsOns,
    },
    () => stepperCanProceed(globalState, 1)
  );
};

const handleSelected2ndStep1stSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    stepsInfos,
    selectedConfOption,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };
  let newStepsInfos = { ...stepsInfos };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section1item = newSelections[2].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[2].values = stepGenericValueItem(step2section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[2] = step2section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[2] = step2section1item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step2section1item.preview}`);

    // filter the 2nd section based on the previous dependsOn
    const section2itemsFiltered = sectionFiltered(
      newSelections[3].section,
      newSelectedDependsOns[2]
    );

    // create the options2ndSection with the filtered items then add it to the stepsInfos state
    newStepsInfos[1].options2ndSection = stepInfosItemMnetsNewOptions(
      section2itemsFiltered
    );

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection2 === step2section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, false, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next sections
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected2ndStep1stSection: label,
        selected2ndStep2ndSection: null,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[2],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 2)
    );
  }

  if (selectedConfOption === 'Tapparelle') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section1item = newSelections[2].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[2].values = stepGenericValueItem(step2section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[2] = step2section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[2] = step2section1item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step2section1item.preview}`);

    // filter the 2nd section based on the previous dependsOn
    const section2itemsFiltered = sectionFiltered(
      newSelections[3].section,
      newSelectedDependsOns[2]
    );

    // create the options2ndSection with the filtered items then add it to the stepsInfos state
    newStepsInfos[1].options2ndSection = stepInfosItemBlindsNewOptions(
      section2itemsFiltered
    );

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection2 === step2section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, false, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next sections
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected2ndStep1stSection: label,
        selected2ndStep2ndSection: null,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[2],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 2)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section1item = newSelections[2].section.items.find(
      (el) => el.id === id
    );
    newSelections[2].values = stepGenericValueItem(step2section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[2] = step2section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[2] = PriceUtils.readPrice(
      newSelections[2].section,
      step2section1item,
      _.get(step2section1item, ['meta', 'priceData'], []),
      selections
    );

    // get item preview
    const itemImage = require(`../../assets/images/configurator/types/${step2section1item.meta.image.replace(
      '/images/',
      ''
    )}`);

    // filter the 2nd section based on the previous dependsOn
    const section2itemsFiltered = sectionFiltered(
      newSelections[3].section,
      newSelectedDependsOns[2]
    );

    // create the options2ndSection with the filtered items then add it to the stepsInfos state
    newStepsInfos[1].options2ndSection = stepInfosItemWindowsNewOptions(
      section2itemsFiltered
    );

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection2 === step2section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, false, false, false, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next sections
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected2ndStep1stSection: label,
        selected2ndStep2ndSection: null,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[2],
        currentPreviewWindowsFront: itemImage,
        currentPreviewWindowsBack: itemImage,
      },
      () => stepperCanProceed(globalState, 2)
    );
  }
};

const handleSelected2ndStep2ndSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    selectedConfOption,
    stepsInfos,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };
  let newStepsInfos = { ...stepsInfos };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section2item = newSelections[3].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[3].values = stepGenericValueItem(step2section2item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[3] = step2section2item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[3] = step2section2item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step2section2item.preview}`);

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection3 === step2section2item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, false, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selections: newSelections,
        selected2ndStep2ndSection: label,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[3],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 2)
    );
  }

  if (selectedConfOption === 'Tapparelle') {
    globalState({ isLoading: true });

    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section2item = newSelections[3].section.items.find(
      (el) => el.title === label && el.id === id
    );
    const valueItem = stepGenericValueItem(step2section2item);

    newSelections[3].values = valueItem;

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[3] = step2section2item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[3] = step2section2item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step2section2item.preview}`);

    // get the brands of the selected item
    const selectedItemBrand = step2section2item.title;

    axios
      .get(`${STEP_COLORS_LIST_API}/blinds`, {
        params: {
          brand: selectedItemBrand,
        },
      })
      .then((res) => {
        const data = res.data.data;

        const externalColors = data['esterno'];
        const internalColors = data['interno'];

        // create the 3rd step step infos
        const step3infos = stepInfosWindowsItem(
          internalColors ? 'Scegli il colore esterno' : 'Scegli il colore',
          [],
          internalColors ? 'Scegli il colore interno' : '',
          []
        );

        // add colors items
        step3infos.options1stSection = stepInfosItemBlindsColorsOptions(
          externalColors
        );
        step3infos.options2ndSection = internalColors
          ? stepInfosItemBlindsColorsOptions(internalColors)
          : [];

        let stepperColorslist = [];
        stepperColorslist[0] = externalColors;
        stepperColorslist[1] = internalColors || [];

        // add the object in the stepsInfos state
        newStepsInfos[2] = step3infos;
        globalState({ stepsInfos: newStepsInfos });

        // reset for modify
        if (windows.length >= 1) {
          const toModifyCardItem = summaryCardsInfos.find(
            (item) => item.selection3 === step2section2item.title
          );

          if (!toModifyCardItem) {
            globalState({
              selected3rdStep1stSection: null,
              selected3rdStep2ndSection: null,
              selectedAccessories: [],
              selectedAccessoriesItems: [],
              selectedAccessoriesPrices: [],
              stepperCanProceedBlinds: [true, true, false, true, true],
            });
          }
        }

        // state update then stepperCanProceed function for validation
        globalState(
          {
            stepperColorslist,
            selections: newSelections,
            selected2ndStep2ndSection: label,
            selectedDependsOns: newSelectedDependsOns,
            selectedPrices: newSelectedPrices,
            currentPrice: newSelectedPrices[3],
            currentPreview: itemImage,
            isLoading: false,
          },
          () => stepperCanProceed(globalState, 2)
        );
      })
      .catch((err) => {
        console.log(err);
        globalState({
          isLoading: false,
          selected3rdStep1stSection: null,
          selected3rdStep2ndSections: null,
        });
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Si è verificato un errore',
          },
        });
      });
  }

  if (selectedConfOption === 'Finestre') {
    debugger;
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step2section2item = newSelections[3].section.items.find(
      // find id which user has selected & item
      (el) => el.id === id
    );
    //TODO when id match 401 constant set a new property on globalState
    const step2section2itemId = step2section2item.id === 401 ? 401 :undefined;
   
    newSelections[3].values = stepGenericValueItem(step2section2item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[3] = step2section2item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[3] = PriceUtils.readPrice(
      newSelections[3].section,
      step2section2item,
      _.get(step2section2item, ['meta', 'priceData'], []),
      selections
    );

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection3 === step2section2item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected3rdStep1stSection: null,
          selected3rdStep2ndSection: null,
          selected3rdStep3rdSection: null,
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, false, false, false, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selections: newSelections,
        selected2ndStep2ndSection: label,
        step2section2itemId: step2section2itemId,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[3],
      },
      () => {
        globalState(
          {
            // logic
            selected3rdStep1stSection: 'PVC',
          },
          () => {
            //handleSelected3rdStep1stSection(globalState(), "PVC", 504)
            stepperCanProceed(globalState, 2);
          }
        );
      }
    );
  }
};

const handleSelected3rdStep1stSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    stepsInfos,
    selectedConfOption,
    stepperColorslist,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };
  let newStepsInfos = { ...stepsInfos };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step3section1item = newSelections[4].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[4].values = stepGenericValueItem(step3section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[4] = step3section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[4] = step3section1item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step3section1item.preview}`);

    // filter the 2nd section based on the previous dependsOn
    const section2itemsFiltered = sectionFiltered(
      newSelections[5].section,
      newSelectedDependsOns[4]
    );

    // create the options2ndSection with the filtered items then add it to the stepsInfos state
    newStepsInfos[2].options2ndSection = stepInfosItemMnetsNewOptions(
      section2itemsFiltered
    );

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection4 === step3section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next sections
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected3rdStep1stSection: label,
        selected3rdStep2ndSection: null,
        selected3rdStep3rdSection: null,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[4],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }

  if (selectedConfOption === 'Tapparelle') {
    // search for the selected item from the stepperColorslist state
    // add the item in selections.values.color_reference
    const step3section1item = stepperColorslist[0].find(
      (el) => el.color_description === label && el.id === id
    );
    const modelItem = newSelections[3].values[0];

    newSelections[4].values[0] = {
      ...modelItem,
      color_reference: step3section1item,
    };

    // get color preview image
    const colorImage = require(`../../assets${step3section1item.meta.image}`);

    // reset for modify summary card
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection4 === step3section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedBlinds: [true, true, true, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selectedBlindsColorImageExt:
          selected2ndStep2ndSection === 'DUERO' ? colorImage : null,
        selectedBlindsColorImage:
          selected2ndStep2ndSection !== 'DUERO' ? colorImage : null,
        selections: newSelections,
        selected3rdStep1stSection: label,
        selectedColorId: id,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step3section1item = newSelections[4].section.items.find(
      (el) => el.id === id
    );
    if (!step3section1item) {
      return;
    }
    newSelections[4].values = stepGenericValueItem(step3section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[4] = step3section1item.id;

    // add the price of the selected item in the selectedPrices state, reset color prices
    const price = PriceUtils.readPrice(
      newSelections[4].section,
      step3section1item,
      _.get(step3section1item, ['meta', 'priceData'], []),
      selections
    );
    newSelectedPrices[4] =
      'increase' in step3section1item.meta
        ? price * step3section1item.meta.increase
        : price;
    newSelectedPrices[51] = null;
    newSelectedPrices[52] = null;
    newSelectedPrices[53] = null;

    // check value of show in meta.updateItem.map, then get titles
    // filter the colors sections based on the previous id in "@selected"
    // create the list of colors from the filtered item
    // get the items in "data" based on the list
    const externalColorsItem = newSelections[5].section.items[0].meta;
    const internalColorsItem = newSelections[5].section.items[1].meta;

    const externalUpdateItem = externalColorsItem.updateItem.map.find(
      (el) => el.id === step3section1item.id
    );
    const internalUpdateItem = internalColorsItem.updateItem.map.find(
      (el) => el.id === step3section1item.id
    );

    const externalTitle =
      externalUpdateItem.show === false ? '' : externalUpdateItem.text;
    const internalTitle =
      internalUpdateItem.show === false ? '' : internalUpdateItem.text;

    const externalSelectedColors =
      externalUpdateItem.show === false
        ? []
        : externalColorsItem['@selected'].values;
    const internalSelectedColors =
      internalUpdateItem.show === false
        ? []
        : internalColorsItem['@selected'].values;

    const externalSelectedColorsFiltered = windowsFilterSelectedById(
      externalSelectedColors,
      step3section1item.id
    );
    const internalSelectedColorsFiltered = windowsFilterSelectedById(
      internalSelectedColors,
      step3section1item.id
    );

    const externalSelectedColorsFilteredList = windowsFilteredSelectedList(
      externalSelectedColorsFiltered
    );
    const internalSelectedColorsFilteredList = windowsFilteredSelectedList(
      internalSelectedColorsFiltered
    );

    const externalDataColors = externalColorsItem.data.values;
    const internalDataColors = internalColorsItem.data.values;

    const externalItems = windowsFilterDataFromList(
      externalSelectedColorsFilteredList,
      externalDataColors
    );
    const internalItems = windowsFilterDataFromList(
      internalSelectedColorsFilteredList,
      internalDataColors
    );

    // options2ndSection and options3rdSection and titles
    newStepsInfos[2].title2ndSection = externalTitle;
    newStepsInfos[2].title3rdSection = internalTitle;
    newStepsInfos[2].options2ndSection = stepInfosItemWindowsColorsOptions(
      externalItems,
      true
    );
    newStepsInfos[2].options3rdSection = internalItems.map((e) => {
      const i = {
        item: {
          enabled: true,
          id: e.code,
          imgPath: e.resource,
          img: require(`../../assets${e.resource}`),
          label: i18next.t(e.name),
        },
        sizes: {
          max_height: 260,
          max_width: 270,
          min_height: 50,
          min_width: 50,
        },
      };
      return i;
    }); //stepInfosItemWindowsColorsOptions(internalItems)
    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection4 === step3section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, false, false, true],
        });
      }
    }

    // get item preview to reset colors
    const previousItem = stepsInfos[1].options1stSection.find(
      (el) => el.item.label === selected2ndStep1stSection
    )['item'];
    const itemImage = require(`../../assets/images/configurator/types/${previousItem.imgPath.replace(
      '/images/',
      ''
    )}`);

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next section
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected3rdStep1stSection: label,
        selected3rdStep2ndSection: null,
        selected3rdStep3rdSection: null,
        currentPreviewWindowsFront: itemImage,
        currentPreviewWindowsBack: itemImage,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[4],
      },
      () => stepperCanProceed(globalState, 3)
    );
  }
};

const handleSelected3rdStep2ndSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    stepsInfos,
    selectedConfOption,
    stepperColorslist,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
    selected3rdStep3rdSection,
    defExternal,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };
  let newStepsInfos = { ...stepsInfos };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step3section2item = newSelections[5].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[5].values = stepGenericValueItem(step3section2item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[5] = step3section2item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[5] = step3section2item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step3section2item.preview}`);

    // filter the 3rd section based on the previous dependsOn
    const section3itemsFiltered = sectionFiltered(
      newSelections[6].section,
      newSelectedDependsOns[5]
    );

    // create the options3rdSection with the filtered items then add it to the stepsInfos state
    newStepsInfos[2].options3rdSection = stepInfosItemMnetsNewOptions(
      section3itemsFiltered
    );

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection5 === step3section2item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    // reset the state of the selected items on the next sections
    globalState(
      {
        selections: newSelections,
        stepsInfos: newStepsInfos,
        selected3rdStep2ndSection: label,
        selected3rdStep3rdSection: null,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[5],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }

  if (selectedConfOption === 'Tapparelle') {
    // search for the selected item from the stepperColorslist state
    // add the item in selections.values.color_reference
    const step3section2item = stepperColorslist[1].find(
      (el) => el.color_description === label && el.id === id
    );
    const modelItem = newSelections[3].values[0];

    newSelections[5].values[0] = {
      ...modelItem,
      color_reference: step3section2item,
    };

    // get color preview image
    const colorImage = require(`../../assets${step3section2item.meta.image}`);

    // reset for modify summary card
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection5 === step3section2item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedBlinds: [true, true, true, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selectedBlindsColorImageInt:
          selected2ndStep2ndSection === 'DUERO' ? colorImage : null,
        selectedBlindsColorImage:
          selected2ndStep2ndSection !== 'DUERO' ? colorImage : null,
        selections: newSelections,
        selected3rdStep2ndSection: label,
        selectedColorId: id,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // preview
    const foundItem = newSelections[5].section.items[0].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );
    if (!foundItem) {
      return;
    }
    const itemCode = foundItem.code;
    const frameImg = stepsInfos[1].options1stSection
      .find((el) => el.item.label === selected2ndStep1stSection)
      ['item'].imgPath.replace('/images/', '');

    // create the selections.values item
    //const step3section1item = newSelections[4].section.items.find(el => el.id === id);
    const stepItemObject = newSelections[5].section.items[0];
    const selectedItem = newSelections[5].section.items[0].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );

    newSelections[5].values[0] = {
      // external color
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: {
        code: stepItemObject.meta.preview.code,
        value: selectedItem.code,
      },
      price: 0,
      shortDescription: '',
      value: {
        disabled: false,
        id: stepItemObject.meta.preview.value,
        increase: 1,
        longDescription: [],
        media: selectedItem.resource,
        media_hover: null,
        price: 0,
        title: selectedItem.name,
      },
    };
    const colorSideId = newSelections[5].values[1].preview.value;
    let itemImageFront = require(`../../assets/images/configurator/types/colors/${itemCode}/${frameImg}`);
    let itemImageBack = require(`../../assets/images/configurator/types/colors/${itemCode}/${frameImg}`);
    const itemImgDefault = require(`../../assets/images/configurator/types/colors/bianco/${frameImg}`);
    let isExt = false;
    if (colorSideId === '6051') {
      console.log('color side 1');
      isExt = false;
      itemImageFront = itemImgDefault;
    } else if (colorSideId === '6052') {
      console.log('color side 2');
      itemImageBack = itemImgDefault;
      isExt = true;
    } else if (colorSideId === '6053') {
      console.log('color side 3');
    }

    // add the price of the selected item in the selectedPrices state
    const price =
      selectedItem.meta !== null
        ? newSelectedPrices[4] * selectedItem.meta.increase
        : newSelectedPrices[4];

    if (selected3rdStep3rdSection) {
      newSelectedPrices[51] =
        label === selected3rdStep3rdSection ? price : newSelectedPrices[4] * 1;
      newSelectedPrices[53] =
        label === selected3rdStep3rdSection ? price : newSelectedPrices[4] * 1;
    } else {
      newSelectedPrices[51] = price;
      newSelectedPrices[53] = price;
    }

    //const price = PriceUtils.readPrice(newSelections[4].section, step3section1item, _.get(step3section1item, ['meta', 'priceData'], []), selections);
    const newPrice = PriceUtils.readPrice(
      newSelections[5].section,
      itemCode,
      undefined,
      selections
    );
    //console.log('product variant price is', newPrice);
    if (newPrice) {
      newSelectedPrices[51] = newPrice;
      newSelectedPrices[53] = newPrice;
    }

    globalState({ currentPrice: newSelectedPrices[53] });

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection5 === selectedItem.name
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, false, false, true],
        });
      }
    }
    const curr = globalState();
    if (!curr.defExternal) {
      globalState({ defExternal: false });
    }
    globalState(
      {
        selections: newSelections,
        selected3rdStep2ndSection: label,
        currentPreviewWindowsFront: itemImageFront,
        currentPreviewWindowsBack: itemImageBack,
        selectedPrices: newSelectedPrices,
        defExternal: isExt,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }
};

const handleSelected3rdStep3rdSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    selectedConfOption,
    selected2ndStep1stSection,
    selected3rdStep1stSection,
    selected3rdStep2ndSection,
    stepsInfos,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step3section3item = newSelections[6].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[6].values = stepGenericValueItem(step3section3item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[6] = step3section3item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[6] = step3section3item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step3section3item.preview}`);

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection6 === step3section3item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, false, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selections: newSelections,
        selected3rdStep3rdSection: label,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[6],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 3)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // preview
    const foundItem = newSelections[5].section.items[1].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );
    if (!foundItem) {
      return;
    }
    const itemCode = foundItem.code;
    const frameImg = stepsInfos[1].options1stSection
      .find((el) => el.item.label === selected2ndStep1stSection)
      ['item'].imgPath.replace('/images/', '');

    //const itemImage = require(`../../assets/images/configurator/types/colors/${itemCode}/${frameImg}`)

    // create the selections.values item
    const stepItemObject = newSelections[5].section.items[1];
    const selectedItem = newSelections[5].section.items[1].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );

    if (selected3rdStep1stSection === 'Legno') {
      const externalStepItemObject = newSelections[5].section.items[0];

      newSelections[5].values[0] = {
        // external color
        id: externalStepItemObject.id,
        item: externalStepItemObject,
        longDescription: externalStepItemObject.meta.longDesc,
        preview: {
          code: externalStepItemObject.meta.preview.code,
          value: selectedItem.code,
        },
        price: 0,
        shortDescription: '',
        value: {
          disabled: false,
          id: externalStepItemObject.meta.preview.value,
          increase: 1,
          longDescription: [],
          media: selectedItem.resource,
          media_hover: null,
          price: 0,
          title: selectedItem.name,
        },
      };
    }

    newSelections[5].values[1] = {
      // internal color
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: {
        code: stepItemObject.meta.preview.code,
        value: selectedItem.code,
      },
      price: 0,
      shortDescription: '',
      value: {
        disabled: false,
        id: stepItemObject.meta.preview.value,
        increase: 1,
        longDescription: [],
        media: selectedItem.resource,
        media_hover: null,
        price: 0,
        title: selectedItem.name,
      },
    };

    // add the price of the selected item in the selectedPrices state
    const price =
      selectedItem.meta !== null
        ? newSelectedPrices[4] * selectedItem.meta.increase
        : newSelectedPrices[4];

    if (selected3rdStep1stSection !== 'Legno' && selected3rdStep2ndSection) {
      newSelectedPrices[52] =
        label === selected3rdStep2ndSection
          ? price
          : newSelectedPrices[4] * 1.0;
      newSelectedPrices[53] =
        label === selected3rdStep2ndSection
          ? price
          : newSelectedPrices[4] * 1.0;
    } else {
      newSelectedPrices[52] = price;
      newSelectedPrices[53] = price;
    }

    const newPrice = PriceUtils.readPrice(
      newSelections[5].section,
      itemCode,
      undefined,
      selections
    );
    //console.log('product variant price is', newPrice);
    if (newPrice) {
      newSelectedPrices[52] = newPrice;
      newSelectedPrices[53] = newPrice;
    }

    globalState({ currentPrice: newSelectedPrices[53] });

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection6 === selectedItem.name
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep1stSection: null,
          selected4thStep2ndSection: null,
          selected4thStep3rdSection: null,
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, false, false, true],
        });
      }
    }

    const previousItem = stepsInfos[1].options1stSection.find(
      (el) => el.item.label === selected2ndStep1stSection
    )['item'];
    const itemImage = require(`../../assets/images/configurator/types/${previousItem.imgPath.replace(
      '/images/',
      ''
    )}`);

    if (selected3rdStep1stSection === 'Legno') {
      globalState(
        {
          currentPreviewWindowsFront: itemImage,
        },
        () => stepperCanProceed(globalState, 3)
      );
    }
    globalState(
      {
        selections: newSelections,
        selected3rdStep3rdSection: label,
        selectedPrices: newSelectedPrices,
        selected3rdStep2ndSection: null,
        currentPreviewWindowsFront: itemImage,
        currentPreviewWindowsBack: itemImage,
      },
      () => {
        debugger;
        const openType = newSelections.find(f=>f.sectionId === 4).values[0];
        let _selected4thStep1stSection = undefined;
        if(openType === 425){
          _selected4thStep1stSection = 'PREMIUM 3 szyby'
        }
        globalState(
          {
            // logic
            selected4thStep1stSection: _selected4thStep1stSection
          },
          () => {
            stepperCanProceed(globalState, 3);
          }
        );
      }
    );
  }
};

const handleSelected4thStep1stSection = (globalState, label, id) => {
  console.log('handleSelected4thStep1stSection');
  debugger;
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selectedConfOption,
    selections,
    selectedDependsOns,
    selectedPrices,
    stepsInfos,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };
  let newStepsInfos = { ...stepsInfos };

  if (selectedConfOption === 'Zanzariere') {
    globalState({ isLoading: true });

    // search for the selected item from the BE selections object
    // create the selections.values item
    const step4section1item = newSelections[7].section.items.find(
      (el) => el.title === label && el.id === id
    );
    newSelections[7].values = stepGenericValueItem(step4section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[7] = step4section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[7] = step4section1item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step4section1item.preview}`);

    // get the brands of the selected item
    const selectedItemBrands = step4section1item.meta.mosquitonet_info.brands;

    // BE call for the 2nd section colors list to be selected
    axios
      .get(`${STEP_COLORS_LIST_API}/mosquitonets`, {
        params: {
          brand: selectedItemBrands,
        },
      })
      .then((res) => {
        const data = res.data.data;

        // get the right colors list based on the 1st selection item
        const section1title =
          step4section1item.title.charAt(0).toLowerCase() +
          step4section1item.title.slice(1);
        const section2itemsFiltered = data[section1title];

        // create the options2ndSection with the filtered items then add it to the stepsInfos state
        newStepsInfos[3].options2ndSection = stepInfosItemMnetsColorsOptions(
          section2itemsFiltered
        );

        // state update then stepperCanProceed function for validation
        globalState(
          {
            stepperColorslist: section2itemsFiltered,
            selections: newSelections,
            stepsInfos: newStepsInfos,
            selected4thStep1stSection: label,
            selectedDependsOns: newSelectedDependsOns,
            selectedPrices: newSelectedPrices,
            currentPrice: newSelectedPrices[7],
            currentPreview: itemImage,
            isLoading: false,
          },
          () => stepperCanProceed(globalState, 4)
        );
      })
      .catch((err) => {
        console.log(err);
        globalState({
          isLoading: false,
          selected4thStep2ndSection: null,
        });
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Si è verificato un errore',
          },
        });
      });

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection7 === step4section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selected4thStep2ndSection: null,
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, true, false, true, true],
        });
      } else {
        globalState({ selected4thStep2ndSection: null });
      }
    } else {
      globalState({ selected4thStep2ndSection: null });
    }
  }

  if (selectedConfOption === 'Finestre') {
    debugger;
    // create the selections.values item
    const stepItemObject = newSelections[6].section.items[0];
    const selectedItem = newSelections[6].section.items[0].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );

    newSelections[6].values[0] = {
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: '',
      price: 0,
      shortDescription: '',
      value: {
        id: selectedItem.code,
        increase: selectedItem.meta.increase,
        longDescription: selectedItem.meta.longDesc,
        media: selectedItem.resource,
        media_hover: selectedItem.resource_hover,
        price: 0,
        title: selectedItem.name,
      },
    };

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[6] =
      'increase' in selectedItem.meta
        ? newSelectedPrices[53] * selectedItem.meta.increase
        : newSelectedPrices[53];

    const newPrice = PriceUtils.readPrice(
      newSelections[6].section,
      selectedItem.code,
      undefined,
      selections
    );
    //console.log('product variant price is', newPrice);
    if (newPrice) {
      newSelectedPrices[6] = newPrice;
    }

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection7 === selectedItem.name
      );

      if (!toModifyCardItem) {
        globalState({
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, true, false, true],
        });
      }
    }

    globalState(
      {
        currentPrice: newSelectedPrices[6],
        selectedPrices: newSelectedPrices,
        selections: newSelections,
        selected4thStep1stSection: label,
        selected4thStep2ndSection: null,
        selected4thStep3rdSection: null,
      },
      () => stepperCanProceed(globalState, 4)
    );
  }
};

const handleSelected4thStep2ndSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    stepperColorslist,
    selectedConfOption,
  } = state;

  let newSelections = [...selections];

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the stepperColorslist state
    // add the item in selections.values.color_reference
    const step4section2item = stepperColorslist.find(
      (el) => el.color_description === label && el.id === id
    );
    newSelections[7].values[0].color_reference = step4section2item;

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection8 === step4section2item.color_description
      );

      if (!toModifyCardItem) {
        globalState({
          selected5thStep1stSection: null,
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, true, false, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selections: newSelections,
        selected4thStep2ndSection: label,
        selectedColorId: id,
      },
      () => stepperCanProceed(globalState, 4)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // create the selections.values item
    const stepItemObject = newSelections[6].section.items[1];
    const selectedItem = newSelections[6].section.items[1].meta.data.values.find(
      (el) => el.name === label
    );

    //const lockItem = {"resource":"/images/window/window_lock_1.svg","code":"safety_1","name":"RC1","position":1,"resource_hover":"/images/window/window_lock_1_hover.svg","meta":null}

    newSelections[6].values[1] = {
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: '',
      price: 0,
      shortDescription: '',
      value: {
        disabled: false,
        id: selectedItem.code,
        increase: 1,
        longDescription: [],
        media: selectedItem.resource,
        media_hover: selectedItem.resource_hover,
        price: 0,
        title: selectedItem.name,
      },
    };

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection8 === selectedItem.name
      );

      if (!toModifyCardItem) {
        globalState({
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, true, false, true],
        });
      }
    }

    globalState(
      {
        selections: newSelections,
        selected4thStep2ndSection: label,
        selected4thStep3rdSection: null,
      },
      () => stepperCanProceed(globalState, 4)
    );
  }
};

const handleSelected4thStep3rdSection = (globalState, label, id) => {
  const state = globalState();
  const {
    selectedConfOption,
    selections,
    selectedPrices,
    windows,
    summaryCardsInfos,
  } = state;

  let newSelections = [...selections];
  let newSelectedPrices = { ...selectedPrices };

  if (selectedConfOption === 'Finestre') {
    // create the selections.values item
    const stepItemObject = newSelections[6].section.items[2];
    const selectedItem = newSelections[6].section.items[2].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );

    newSelections[6].values[2] = {
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: '',
      price: 0,
      shortDescription: '',
      value: {
        id: selectedItem.code,
        longDescription: [],
        media: selectedItem.resource,
        media_hover: null,
        price: 0,
        title: selectedItem.name,
      },
    };

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[7] =
      newSelectedPrices[6] + selectedItem.meta.priceIncrement;
    if (selectedItem.meta && selectedItem.meta.priceIncrement) {
      newSelectedPrices[7] =
        selectedItem.meta !== null
          ? newSelectedPrices[6] + selectedItem.meta.priceIncrement
          : newSelectedPrices[6];
    } else if (selectedItem.meta && selectedItem.meta.increment) {
      newSelectedPrices[7] =
        selectedItem.meta !== null
          ? newSelectedPrices[6] +
            (newSelectedPrices[6] * selectedItem.meta.increment) / 100
          : newSelectedPrices[6];
    } else {
      newSelectedPrices[7] = newSelectedPrices[6];
    }

    // reset for modify
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection9 === selectedItem.name
      );

      if (!toModifyCardItem) {
        globalState({
          selected5thStep1stSection: null,
          stepperCanProceedWindows: [true, true, true, true, false, true],
        });
      }
    }

    const [backgroundFront, backgroundBack] = getImagesBackground(label);
    const allImages = getallImagesBackground();

    globalState(
      {
        backgroundFront: backgroundFront,
        backgroundBack: backgroundBack,
        currentPrice: newSelectedPrices[7],
        selectedPrices: newSelectedPrices,
        selections: newSelections,
        selected4thStep3rdSection: label,
        windowBackGroundImgPaths: allImages,
      },
      () => stepperCanProceed(globalState, 4)
    );
  }
};

const handleSelected5thStep1stSection = (globalState, label, id) => {
  const state = globalState();
  const {
    windows,
    summaryCardsInfos,
    selections,
    selectedDependsOns,
    selectedPrices,
    selectedConfOption,
  } = state;

  let newSelections = [...selections];
  let newSelectedDependsOns = { ...selectedDependsOns };
  let newSelectedPrices = { ...selectedPrices };

  if (selectedConfOption === 'Zanzariere') {
    // search for the selected item from the BE selections object
    // create the selections.values item
    const step5section1item = newSelections[8].section.items.find(
      (el) => (el.title === label || el.title === '') && el.id === id
    );
    newSelections[8].values = stepGenericValueItem(step5section1item);

    // add the dependsOn of the selected item in the selectedDependsOns state
    newSelectedDependsOns[8] = step5section1item.id;

    // add the price of the selected item in the selectedPrices state
    newSelectedPrices[8] = step5section1item.price.toFixed(2);

    // get item preview
    const itemImage = require(`../../assets${step5section1item.preview}`);

    // reset for modify summary card when you select something else
    if (windows.length >= 1) {
      const toModifyCardItem = summaryCardsInfos.find(
        (item) => item.selection9 === step5section1item.title
      );

      if (!toModifyCardItem) {
        globalState({
          selectedAccessories: [],
          selectedAccessoriesItems: [],
          selectedAccessoriesPrices: [],
          stepperCanProceedMnets: [true, true, true, true, true, true, true],
        });
      }
    }

    // state update then stepperCanProceed function for validation
    globalState(
      {
        selections: newSelections,
        selected5thStep1stSection: label,
        selectedDependsOns: newSelectedDependsOns,
        selectedPrices: newSelectedPrices,
        currentPrice: newSelectedPrices[8],
        currentPreview: itemImage,
      },
      () => stepperCanProceed(globalState, 5)
    );
  }

  if (selectedConfOption === 'Finestre') {
    // create the selections.values item
    const stepItemObject = newSelections[7].section.items[0];
    const selectedItem = newSelections[7].section.items[0].meta.data.values.find(
      (el) => i18next.t(el.name) === label
    );

    newSelections[7].values[0] = {
      id: stepItemObject.id,
      item: stepItemObject,
      longDescription: stepItemObject.meta.longDesc,
      preview: '',
      price: 0,
      shortDescription: '',
      value: {
        disabled: false,
        id: selectedItem.code,
        increase: 1,
        longDescription: [],
        media: selectedItem.resource,
        media_hover: '',
        price: 0,
        title: selectedItem.name,
      },
    };

    // add the price of the selected item in the selectedPrices state
    if (selectedItem.meta && selectedItem.meta.priceIncrement) {
      newSelectedPrices[8] =
        selectedItem.meta !== null
          ? newSelectedPrices[7] + selectedItem.meta.priceIncrement
          : newSelectedPrices[7];
    } else if (selectedItem.meta && selectedItem.meta.increment) {
      newSelectedPrices[8] =
        selectedItem.meta !== null
          ? newSelectedPrices[7] +
            (newSelectedPrices[7] * selectedItem.meta.increment) / 100
          : newSelectedPrices[7];
    } else {
      newSelectedPrices[8] = newSelectedPrices[7];
    }

    globalState(
      {
        currentPrice: newSelectedPrices[8],
        selectedPrices: newSelectedPrices,
        selections: newSelections,
        selected5thStep1stSection: label,
      },
      () => stepperCanProceed(globalState, 5)
    );
  }
};

const handleAccessories = (globalState, label, id, i) => {
  const state = globalState();
  const {
    currentPrice,
    selections,
    selectedAccessories,
    selectedAccessoriesPrices,
    selectedAccessoriesItems,
    selectedConfOption,
  } = state;

  let newSelections = [...selections];
  let newSelectedAccessories = [...selectedAccessories];
  let newSelectedAccessoriesPrices = [...selectedAccessoriesPrices];
  let newSelectedAccessoriesItems = [...selectedAccessoriesItems];

  // create the item to add in the selectedAccessories state
  const selectedItem = { label };

  // check if the accessory in the state index is undefined
  // if it's undefined add it
  // if it's not, check if it's the same and toggle it
  // if it's not undefined and not the same, add it
  if (newSelectedAccessories[i] === undefined) {
    newSelectedAccessories[i] = selectedItem;
  } else {
    if (label === newSelectedAccessories[i].label) {
      newSelectedAccessories[i] = {};
    } else {
      newSelectedAccessories[i] = selectedItem;
    }
  }

  // selections index of accessories
  const selectionIndex =
    selectedConfOption === 'Zanzariere'
      ? 9
      : selectedConfOption === 'Tapparelle'
      ? 6
      : 0;

  // search for the selected item from the BE selections object
  const selectedAccessoryItem = newSelections[
    selectionIndex
  ].section.items.find((el) => el.title === label);

  // add and remove the price of the selected accessory to the currentPrice state
  // add the price to the selectedAccessoriesPrices state to check if you selected the same
  if (newSelectedAccessoriesPrices[i] === undefined) {
    newSelectedAccessoriesPrices[i] = selectedAccessoryItem.price.toFixed(2);
    globalState({
      currentPrice: (
        Number(currentPrice) + Number(newSelectedAccessoriesPrices[i])
      ).toFixed(2),
    });
  } else {
    globalState(
      {
        currentPrice: (
          Number(currentPrice) - Number(newSelectedAccessoriesPrices[i])
        ).toFixed(2),
      },
      () => (newSelectedAccessoriesPrices[i] = undefined)
    );
  }

  // check if the item in the selectedAccessoriesItems state index is undefined
  // if it's undefined add it
  // if it's not, check if it's the same and remove it
  // if it's not undefined and not the same, add it
  if (newSelectedAccessoriesItems[i] === undefined) {
    newSelectedAccessoriesItems[i] = stepAccessoriesItem(selectedAccessoryItem);
  } else {
    if (selectedAccessoryItem.id === newSelectedAccessoriesItems[i].id) {
      newSelectedAccessoriesItems[i] = undefined;
    } else {
      newSelectedAccessoriesItems[i] = stepAccessoriesItem(
        selectedAccessoryItem
      );
    }
  }

  // add the selectedAccessoriesItems in selections.values
  newSelections[selectionIndex].values = [...newSelectedAccessoriesItems];

  // state update
  globalState(
    {
      selections: newSelections,
      selectedAccessoriesPrices: newSelectedAccessoriesPrices,
      selectedAccessoriesItems: newSelectedAccessoriesItems,
      selectedAccessories: newSelectedAccessories,
    },
    () => stepperCanProceed(globalState, 6)
  );
};

// stepper validation on selections change
const stepperCanProceed = (globalState, step) => {
  const state = globalState();
  const {
    selectedConfOption,
    stepperCanProceedMnets,
    stepperCanProceedBlinds,
    stepperCanProceedWindows,
    confHeightIsValid,
    confWidthIsValid,
    configuratorHeight,
    configuratorWidth,
    selected1stStep1stSection,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
    selected3rdStep1stSection,
    selected3rdStep2ndSection,
    selected3rdStep3rdSection,
    selected4thStep1stSection,
    selected4thStep2ndSection,
    selected4thStep3rdSection,
    selected5thStep1stSection,
    stepsInfos,
  } = state;

  if (selectedConfOption === 'Zanzariere') {
    let newStepperCanProceedMnets = { ...stepperCanProceedMnets };

    if (step === 1) {
      if (
        selected1stStep1stSection &&
        confHeightIsValid &&
        confWidthIsValid &&
        configuratorHeight &&
        configuratorWidth
      ) {
        newStepperCanProceedMnets[0] = true;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      } else {
        newStepperCanProceedMnets[0] = false;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      }
    }
    if (step === 2) {
      if (selected2ndStep1stSection && selected2ndStep2ndSection) {
        newStepperCanProceedMnets[1] = true;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      } else {
        newStepperCanProceedMnets[1] = false;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      }
    }
    if (step === 3) {
      if (
        selected3rdStep1stSection &&
        selected3rdStep2ndSection &&
        selected3rdStep3rdSection
      ) {
        newStepperCanProceedMnets[2] = true;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      } else {
        newStepperCanProceedMnets[2] = false;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      }
    }
    if (step === 4) {
      if (selected4thStep1stSection && selected4thStep2ndSection) {
        newStepperCanProceedMnets[3] = true;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      } else {
        newStepperCanProceedMnets[3] = false;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      }
    }
    if (step === 5) {
      if (selected5thStep1stSection) {
        newStepperCanProceedMnets[4] = true;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      } else {
        newStepperCanProceedMnets[4] = false;
        return globalState({
          stepperCanProceedMnets: newStepperCanProceedMnets,
        });
      }
    }
    if (step === 6) {
    }
    if (step === 7) {
    }
  }
  if (selectedConfOption === 'Tapparelle') {
    let newStepperCanProceedBlinds = { ...stepperCanProceedBlinds };

    if (step === 1) {
      if (
        selected1stStep1stSection &&
        confHeightIsValid &&
        confWidthIsValid &&
        configuratorHeight &&
        configuratorWidth
      ) {
        newStepperCanProceedBlinds[0] = true;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      } else {
        newStepperCanProceedBlinds[0] = false;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      }
    }
    if (step === 2) {
      if (selected2ndStep1stSection && selected2ndStep2ndSection) {
        newStepperCanProceedBlinds[1] = true;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      } else {
        newStepperCanProceedBlinds[1] = false;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      }
    }
    if (step === 3) {
      const canHaveTwoColors = Boolean(
        stepsInfos[2].options2ndSection.length > 0
      );

      if (
        canHaveTwoColors &&
        selected3rdStep1stSection &&
        selected3rdStep2ndSection
      ) {
        newStepperCanProceedBlinds[2] = true;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      } else if (!canHaveTwoColors && selected3rdStep1stSection) {
        newStepperCanProceedBlinds[2] = true;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      } else {
        newStepperCanProceedBlinds[2] = false;
        return globalState({
          stepperCanProceedBlinds: newStepperCanProceedBlinds,
        });
      }
    }
  }
  if (selectedConfOption === 'Finestre') {
    let newStepperCanProceedWindows = { ...stepperCanProceedWindows };

    if (step === 1) {
      if (
        selected1stStep1stSection &&
        confHeightIsValid &&
        confWidthIsValid &&
        configuratorHeight &&
        configuratorWidth
      ) {
        newStepperCanProceedWindows[0] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else {
        newStepperCanProceedWindows[0] = false;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      }
    }
    if (step === 2) {
      if (selected2ndStep1stSection && selected2ndStep2ndSection) {
        newStepperCanProceedWindows[1] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else {
        newStepperCanProceedWindows[1] = false;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      }
    }
    if (step === 3) {
      const canHaveTwoColors = Boolean(
        stepsInfos[2].options2ndSection.length > 0
      );

      if (
        canHaveTwoColors &&
        selected3rdStep1stSection &&
        selected3rdStep2ndSection &&
        selected3rdStep3rdSection
      ) {
        newStepperCanProceedWindows[2] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else if (
        !canHaveTwoColors &&
        selected3rdStep1stSection &&
        selected3rdStep3rdSection
      ) {
        newStepperCanProceedWindows[2] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else {
        newStepperCanProceedWindows[2] = false;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      }
    }
    if (step === 4) {
      if (
        selected4thStep1stSection &&
        (selected4thStep2ndSection || selected4thStep3rdSection)
      ) {
        newStepperCanProceedWindows[3] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else {
        newStepperCanProceedWindows[3] = false;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      }
    }
    if (step === 5) {
      if (selected5thStep1stSection) {
        newStepperCanProceedWindows[4] = true;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      } else {
        newStepperCanProceedWindows[4] = false;
        return globalState({
          stepperCanProceedWindows: newStepperCanProceedWindows,
        });
      }
    }
  }
};

export {
  handleConfiguratorHeight,
  handleConfiguratorWidth,
  handleSelected1stStep1stSection,
  handleSelected2ndStep1stSection,
  handleSelected2ndStep2ndSection,
  handleSelected3rdStep1stSection,
  handleSelected3rdStep2ndSection,
  handleSelected3rdStep3rdSection,
  handleSelected4thStep1stSection,
  handleSelected4thStep2ndSection,
  handleSelected4thStep3rdSection,
  handleSelected5thStep1stSection,
  handleAccessories,
};
