"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNumber = isNumber;
exports.personName = personName;
exports.companyName = companyName;
exports.disallowSpecialChars = disallowSpecialChars;
exports.isAlphanumeric = isAlphanumeric;
exports.isAlphanumericWithSpaces = isAlphanumericWithSpaces;
exports.default = void 0;

function isNumber(value) {
  return !isNaN(Number(value));
}

function personName(value) {
  var regex = /[*°§£"|~`!@#$%^&()_={}[\]:;,.<>+\\0-9/?-]+/i;
  return !regex.test(value);
}

function companyName(value) {
  var regex = /[*°§£"|~!@#$%^()_={}/[\]:;,<>+?]+/i;
  return !regex.test(value);
}

function disallowSpecialChars(value) {
  var regex = /[*°§£"|~!@#$%^&()_={}/[\]:;,<>+?.]+/i;
  return !regex.test(value);
}

function isAlphanumeric(value) {
  var regex = /^[a-z0-9¿\b]+$/i;
  return regex.test(value);
}

function isAlphanumericWithSpaces(value) {
  var regex = /^[a-z0-9¿\b\s]+/i;
  return regex.test(value);
}

var _default = {
  alphanumeric: {
    press: isAlphanumeric,
    validator: 'alphanumericValidator'
  },
  alphanumericWithSpaces: {
    press: isAlphanumericWithSpaces,
    validator: 'alphanumericWithSpacesValidator'
  },
  number: {
    press: isNumber,
    validator: 'numberValidator'
  },
  name: {
    press: personName,
    validator: 'nameValidator'
  },
  companyName: {
    press: companyName,
    validator: 'companyNameValidator'
  },
  taxCode: {
    press: isAlphanumeric,
    validator: 'taxCodeValidator',
    formatClass: 'uppercase'
  },
  disallowSpecialChars: {
    press: disallowSpecialChars,
    validator: 'disallowSpecialCharsValidator'
  }
};
exports.default = _default;