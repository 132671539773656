"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LmConfiguratorTemplate = void 0;

var _index = _interopRequireDefault(require("./LmConfiguratorTemplate/index.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Design system components export
var components = {
  LmConfiguratorTemplate: _index.default
};
Object.freeze(components);
var LmConfiguratorTemplate = components.LmConfiguratorTemplate;
exports.LmConfiguratorTemplate = LmConfiguratorTemplate;