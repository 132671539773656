import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
import { LmButton } from '@lm/lm-design-system/dist/components';
import { Dialog, Grid } from '@material-ui/core';
import LmConfOptionCard from '../LmConfOptionCard';

export default class LmStepperLinkProductModal extends React.Component {
  state = {
    filteredConfOptions: [],
  };

  componentDidUpdate(prevProps) {
    const {
      configuratorsOptions,
      selectedToBeLinkedProduct,
      currentGroup,
      summaryCardsInfos,
    } = this.props;
    if (prevProps.selectedToBeLinkedProduct !== selectedToBeLinkedProduct) {
      const groupItems = summaryCardsInfos.filter(function (el) {
        return el.group === currentGroup;
      });
      const groupConfOptionsList = groupItems.map(
        (el) => el.selectedConfOption
      );
      const filteredConfOptions = configuratorsOptions.filter(function (el) {
        return !groupConfOptionsList.includes(el.label);
      });

      this.setState({ filteredConfOptions });
    }
  }

  render() {
    const { filteredConfOptions } = this.state;
    const {
      productModalOpened,
      handleLinkProductModal,
      globalState,
      selectedLinkedProduct,
      handleLinkedProduct,
    } = this.props;

    return (
      <Dialog
        open={productModalOpened}
        onClose={() => handleLinkProductModal(globalState)}
        maxWidth="xl"
      >
        <div className={style.linkModalContainer}>
          <div className={style.title}>
            {/* Scegli il prodotto da abbinare */}
            {i18next.t('windows.LM_STEPPER_LINK_PRODUCT_MODAL.selectProduct')}
          </div>

          <Grid container justify={'center'} spacing={2}>
            {filteredConfOptions.map((el, i) => {
              return (
                <Grid item xs={12} md={4} key={`config-option-${i}`}>
                  <LmConfOptionCard
                    el={el}
                    i={i}
                    handleSelectedConfOptions={(label) =>
                      globalState({ selectedLinkedProduct: label })
                    }
                    selectedConfOption={selectedLinkedProduct}
                  />
                </Grid>
              );
            })}
          </Grid>

          <div className={style.linkModalButton}>
            <LmButton
              disabled={!selectedLinkedProduct}
              onClick={() => handleLinkedProduct(globalState)}
            >
              {/* PROCEDI */}
              {i18next.t('windows.LM_STEPPER_LINK_PRODUCT_MODAL.proceed')}
            </LmButton>
          </div>
        </div>
      </Dialog>
    );
  }
}
