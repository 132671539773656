import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
// imports from the lm-design-system library
import { LmInput } from '@lm/lm-design-system/dist/components';

export default class LmStepperSizesBox extends React.Component {
  render() {
    const {
      heightIsValid,
      widthIsValid,
      height,
      width,
      handleHeightInput,
      handleWidthInput,
      sizes: { min_height, max_height, min_width, max_width },
    } = this.props;

    const insertValue = i18next.t('windows.LM_STEP_WINDOW_SIZE.insertValue');
    const andKeyword = i18next.t('windows.LM_STEP_WINDOW_SIZE.and');
    const cmSize = i18next.t('windows.LM_STEP_WINDOW_SIZE.cmSize');

    return (
      <div className={style.lmStepperSizeBoxContainer}>
        <div className={style.lmStepperSizeBox}>
          <h4>
            {insertValue} {min_height / 10} {andKeyword} {max_height / 10}{' '}
            {cmSize}
          </h4>
          <div className={style.lmInput}>
            <LmInput
              label={i18next.t('windows.LM_STEP_WINDOW_SIZE.heightInputLabel')}
              maxLenght="10"
              name="stepper-input-height"
              type="number"
              handleChange={handleHeightInput}
              value={height}
              error={!heightIsValid}
            />
          </div>
          <h4>
            {insertValue} {min_width / 10} {andKeyword} {max_width / 10}{' '}
            {cmSize}
          </h4>
          <div className={style.lmInput}>
            <LmInput
              label={i18next.t('windows.LM_STEP_WINDOW_SIZE.widthInputLabel')}
              maxLenght="10"
              name="stepper-input-width"
              type="number"
              handleChange={handleWidthInput}
              value={width}
              error={!widthIsValid}
            />
          </div>
        </div>
      </div>
    );
  }
}
