import logo from '../../assets/images/lm-logo.svg';
import i18next from '../../utils/i18n';
// mosquito nets
const stepInfosMnetsItem = (
  section1Title,
  section1Items,
  section2Title,
  section2Items,
  section3Title,
  section3Items
) => {
  return {
    title1stSection: section1Title,
    title2ndSection: section2Title || '',
    title3rdSection: section3Title || '',
    options1stSection:
      section1Items.map((el, i) => {
        return {
          item: {
            img:
              el.meta.image !== null
                ? require(`../../assets${el.meta.image}`)
                : logo,
            label: i18next.t(el.title),
            id: el.id,
          },
          sizes: el.meta.mosquitonet_info.sizes,
        };
      }) || [],
    options2ndSection: section2Items || [],
    options3rdSection: section3Items || [],
  };
};
const stepInfosItemMnetsNewOptions = (sectionItems) => {
  return sectionItems.map((el, i) => {
    return {
      item: {
        img:
          el.meta.image !== null
            ? require(`../../assets${el.meta.image}`)
            : logo,
        label: i18next.t(el.title),
        id: el.id,
      },
    };
  });
};
const stepInfosItemMnetsColorsOptions = (sectionItems) => {
  return sectionItems.map((el, i) => {
    return {
      img:
        el.meta.image !== null ? require(`../../assets${el.meta.image}`) : logo,
      label: i18next.t(el.color_description),
      id: el.id,
    };
  });
};
const stepInfosAccessories = (section1Title, section1Items) => {
  return {
    title1stSection: section1Title,
    options1stSection: section1Items.map((el, i) => {
      return {
        item: {
          img:
            el.meta.image !== null
              ? require(`../../assets${el.meta.image}`)
              : logo,
          label: i18next.t(el.title),
          id: el.id,
          category: el.meta.mosquitonet_optional_info.category,
        },
        price: el.price,
      };
    }),
  };
};

// blinds
const stepInfosBlindsItem = (
  section1Title,
  section1Items,
  section2Title,
  section2Items,
  section3Title,
  section3Items
) => {
  return {
    title1stSection: section1Title,
    title2ndSection: section2Title || '',
    title3rdSection: section3Title || '',
    options1stSection:
      section1Items.map((el, i) => {
        return {
          item: {
            img:
              el.meta.image !== null
                ? require(`../../assets${el.meta.image}`)
                : logo,
            label: i18next.t(el.title),
            id: el.id,
          },
          sizes: el.meta.blind_info.sizes,
        };
      }) || [],
    options2ndSection: section2Items || [],
    options3rdSection: section3Items || [],
  };
};
const stepInfosItemBlindsNewOptions = (sectionItems) => {
  return sectionItems.map((el, i) => {
    return {
      item: {
        img:
          el.meta.image !== null
            ? require(`../../assets${el.meta.image}`)
            : logo,
        label: i18next.t(el.title),
        id: el.id,
      },
    };
  });
};
const stepInfosItemBlindsColorsOptions = (sectionItems) => {
  return sectionItems.map((el, i) => {
    return {
      img:
        el.meta.image !== null ? require(`../../assets${el.meta.image}`) : logo,
      label: i18next.t(el.color_description),
      id: el.id,
    };
  });
};
const stepInfosBlindsAccessories = (section1Title, section1Items) => {
  return {
    title1stSection: section1Title,
    options1stSection: section1Items.map((el, i) => {
      return {
        item: {
          img:
            el.meta.image !== null
              ? require(`../../assets${el.meta.image}`)
              : logo,
          label: i18next.t(el.title),
          id: el.id,
          category: el.meta.blind_optional_info.category,
        },
        price: el.price,
      };
    }),
  };
};

const blindsAccessoriesFiltered = (items, selected) => {
  return items.filter(function (item) {
    return item.code.includes(selected);
  });
};

// windows
const stepInfosWindowsItem = (
  section1Title,
  section1Items,
  section2Title,
  section2Items,
  section3Title,
  section3Items,
  section4Title,
  section4Items
) => {
  const step3Check =
    section1Title.indexOf('windows.section.title.choose_material') >= 0;
  //console.log('step 3 check', step3Check);
  return {
    title1stSection: section1Title,
    title2ndSection: section2Title || '',
    title3rdSection: section3Title || '',
    title4thSection: section4Title || '',
    options1stSection:
      (
        section1Items.map((el, i) => {
          return {
            item: {
              imgPath: el.meta.image,
              img:
                el.meta.image !== null
                  ? require(`../../assets${el.meta.image}`)
                  : logo,
              label: i18next.t(el.title),
              id: el.id,
              enabled: el.meta.enabled, //el.title === "Legno" || el.title === "Legno <br />e alluminio" ? true : false
            },
            sizes: {
              max_height: el.meta.maxHeight,
              max_width: el.meta.maxWidth,
              min_height: el.meta.minHeight,
              min_width: el.meta.minWidth,
            },
          };
        }) || []
      ).filter((el) => {
        //console.log('filtering for step 3', step3Check, el.item.enabled);
        return step3Check ? el.item.enabled : true;
      }) || [],
    options2ndSection: section2Items || [],
    options3rdSection: section3Items || [],
    options4thSection: section4Items || [],
  };
};
const stepInfosItemWindowsNewOptions = (sectionItems) => {
  //console.log('stepInfosItemWindowsNewOptions', sectionItems);
  return sectionItems.map((el, i) => {
    return {
      item: {
        img:
          el.meta.image !== null
            ? require(`../../assets${el.meta.image}`)
            : logo,
        label: i18next.t(el.title),
        id: el.id,
      },
    };
  });
};
const stepInfosItemWindowsColorsOptions = (sectionItems, sort = false) => {
  //console.log('stepInfosItemWindowsColorsOptions', sectionItems);
  const toMap = sort
    ? sectionItems.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      })
    : sectionItems;
  return toMap.map((el, i) => {
    return {
      img: el.resource !== null ? require(`../../assets${el.resource}`) : logo,
      label: i18next.t(el.name),
    };
  });
};

const stepInfosItemWindowsFeaturesOptions = (sectionItems, sort = false) => {
  // overload of funtion and pass another parameter
  // or call window.localStorage
  // only in step 4, take second parameter. if undefined the continue with usual function.
  //console.log('stepInfosItemWindowsFeaturesOptions', sectionItems);

  if (window.localStorage.i18nextLng === 'pl') {
    const mapped = sectionItems.map((el, i) => {
      const disabled =
        el.meta === null || !el.meta.disabled ? false : el.meta.disabled;
      return {
        item: {
          img:
            el.resource !== null
              ? require(`../../assets_pl${el.resource}`)
              : logo,
          label: i18next.t(el.name),
          disabled,
        },
      };
    });
    return sort
      ? mapped.sort(function (a, b) {
          return -1;
        })
      : mapped;
  } else {
    const mapped = sectionItems.map((el, i) => {
      const disabled =
        el.meta === null || !el.meta.disabled ? false : el.meta.disabled;
      return {
        item: {
          img:
            el.resource !== null ? require(`../../assets${el.resource}`) : logo,
          label: i18next.t(el.name),
          disabled,
        },
      };
    });
    return sort
      ? mapped.sort(function (a, b) {
          return -1;
        })
      : mapped;
  }
};
const stepInfosItemWindowsAccessoriesOptions = (sectionItems) => {
  const toMap = sectionItems.sort((a,b)=>{
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  });
  return toMap.map((el, i) => {
    return {
      item: {
        img:
          el.resource !== null ? require(`../../assets${el.resource}`) : logo,
        label: i18next.t(el.name),
        price: 0,
      },
    };
  });
};

const stepInfosItemWindowsHandleColorsOptions = (
  sectionItems,
  sort = false
) => {
  const toMap = sort
    ? sectionItems.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      })
    : sectionItems;
  return toMap.map((el, i) => {
    return {
      img: el.resource !== null ? require(`../../assets${el.resource}`) : logo,
      label: i18next.t(el.name),
    };
  });
};

const windowsFilterSelectedById = (items, id) => {
  return items.filter(function (item) {
    return item.itemid === id;
  });
};
const windowsFilteredSelectedList = (items) => {
  return items.map((el) => {
    return el.code;
  });
};
const windowsFilterDataFromList = (list, data) => {
  return list.map((el) => data.find((element) => element.code === el) || {});
};

// generic
const stepSelectionsItem = (data) => {
  return data.map((s) => {
    return {
      sectionId: s.id,
      section: s,
      values: [],
    };
  });
};
const stepSizesValueItem = (item, value) => {
  return {
    id: item.id,
    longDescription: item.meta.longDesc,
    shortDescription: item.meta.shortDesc,
    price: item.price,
    priceOverride: false,
    item: { ...item },
    value: Number(value),
  };
};
const stepGenericValueItem = (item) => {
  return [
    {
      id: item.id,
      longDescription: item.meta.longDesc,
      shortDescription: item.meta.shortDesc,
      price: item.price,
      priceOverride: false,
      item: { ...item },
    },
  ];
};
const stepAccessoriesItem = (item) => {
  return {
    id: item.id,
    longDescription: item.meta.longDesc,
    shortDescription: item.meta.shortDesc,
    price: item.price,
    priceOverride: false,
    item: { ...item },
  };
};

const sectionFiltered = (section, dependsOn) => {
  return section.items.filter(function (item) {
    return item.dependsOn === dependsOn;
  });
};
const getSelectedOptionId = (summaryInfos, selected) => {
  let selectedCardsList = summaryInfos.filter(function (item) {
    return item.cardName.includes(selected);
  });
  //patch until there are only Windows and because on cardName is not included
  //the "Finestre" keyword since i18n constraints and cardName is nof strong for this filter function
  //to fix should be enough to includes selected card list with another euristic such as
  //prefix code or others...to patch it's enough to include all list
  if (!selectedCardsList || selectedCardsList.length <= 0) {
    selectedCardsList = summaryInfos;
  }

  let selectedCardsIds =
    selectedCardsList.length > 0
      ? selectedCardsList.map((el) => {
          return el.cardName.slice(-1);
        })
      : [];

  const idsNumbers =
    selectedCardsIds.length > 0 ? selectedCardsIds.map((el) => Number(el)) : 0;

  const maxId = idsNumbers !== 0 ? Math.max(...idsNumbers) : 0;

  return maxId + 1;
};

export {
  stepInfosMnetsItem,
  stepInfosBlindsItem,
  stepInfosWindowsItem,
  stepInfosItemMnetsNewOptions,
  stepInfosItemBlindsNewOptions,
  stepInfosItemWindowsNewOptions,
  stepInfosItemMnetsColorsOptions,
  stepInfosItemBlindsColorsOptions,
  stepInfosItemWindowsColorsOptions,
  stepInfosItemWindowsFeaturesOptions,
  stepInfosItemWindowsAccessoriesOptions,
  stepInfosItemWindowsHandleColorsOptions,
  stepInfosAccessories,
  stepInfosBlindsAccessories,
  stepSelectionsItem,
  stepSizesValueItem,
  stepGenericValueItem,
  stepAccessoriesItem,
  blindsAccessoriesFiltered,
  windowsFilterSelectedById,
  windowsFilteredSelectedList,
  windowsFilterDataFromList,
  sectionFiltered,
  getSelectedOptionId,
};
