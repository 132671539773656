import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';

export default class LmStepperBlindsStep4 extends React.Component {
  render() {
    const { 
      globalState,
      handleAccessories,
      selectedAccessories,
      stepInfos: { title1stSection, options1stSection }
    } = this.props;

    return(
      <LmStepperSectionContainer title={title1stSection}>
        <LmStepperCardWrapper 
          globalState={globalState}
          isAccessory
          options={options1stSection} 
          selected={selectedAccessories} 
          handleSelected={handleAccessories}
        />
      </LmStepperSectionContainer>
    )
  }
}