import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';

import {
  setCredentials,
  handleLoginModal,
  handleLoginButton,
} from './../../utils/commons/loginFunctions';

// imports from the lm-design-system library
import {
  LmButton,
  LmInput,
  LmLink,
} from '@lm/lm-design-system/dist/components';
// imports from material-ui
import { Dialog } from '@material-ui/core';

export default class LmLoginModal extends React.Component {
  render() {
    const { loginModalOpened, globalState } = this.props;

    // translations
    const loginText = i18next.t('windows.LM_LOGIN_MODAL.loginText');
    const forgotPassword = i18next.t('windows.LM_LOGIN_MODAL.forgotPassword');
    const loginButton = i18next.t('windows.LM_LOGIN_MODAL.loginButton');
    const noAccount = i18next.t('windows.LM_LOGIN_MODAL.noAccount');
    const registerAccount = i18next.t('windows.LM_LOGIN_MODAL.register');
    const learnAdvantages = i18next.t('windows.LM_LOGIN_MODAL.learnAdvantages');

    return (
      <Dialog
        open={loginModalOpened}
        onClose={() => handleLoginModal(globalState)}
        maxWidth="xl"
      >
        <div className={style.loginForm}>
          <div className={style.title}>{loginText}</div>
          <div className={style.LmInput}>
            <LmInput
              label="Email"
              maxLenght="10"
              name="login-input-email"
              type="email"
              handleChange={(e) =>
                setCredentials(globalState, 'username', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleLoginButton(globalState)
              }
            />
          </div>
          <div className={style.LmInput}>
            <LmInput
              label="Password"
              maxLenght="5"
              name="login-input-password"
              type="password"
              handleChange={(e) =>
                setCredentials(globalState, 'password', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleLoginButton(globalState)
              }
            />
          </div>
          <div className={style.forgotPassword}>
            <LmLink href="https://www.leroymerlin.it/login" target="_blank">
              {forgotPassword}
            </LmLink>
          </div>
          <div className={style.LmButton}>
            <LmButton onClick={() => handleLoginButton(globalState)}>
              {loginButton}
            </LmButton>
          </div>
          <div className={style.noAccount}>
            <div>{noAccount}</div>
            <div>
              <LmLink
                href="https://www.leroymerlin.it/login"
                target="_blank"
                className={style.register}
              >
                {registerAccount}{' '}
              </LmLink>
              {learnAdvantages}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
