import React from 'react';
import style from './style.module.scss';

// imports from material-ui
import { Grid } from '@material-ui/core';
// import project components
import LmStepperCard from '../LmStepperCard';

export default class LmStepperCardWrapper extends React.Component {
  render() {
    const {
      globalState,
      isAccessory,
      options,
      selected,
      handleSelected,
      size,
      noLabel,
    } = this.props;

    return (
      <div className={style.lmStepperCardWrapper}>
        <Grid container justify={'center'} spacing={1}>
          {options.map((el, i) => {
            return (
              <Grid
                key={`item-${el.item.label}-${i}`}
                item
                xs={size ? 12 : 6}
                sm={size ? 12 : 6}
                md={size ? 6 : 3}
              >
                <LmStepperCard
                  size={size}
                  globalState={globalState}
                  isAccessory={isAccessory}
                  el={el.item}
                  selected={selected}
                  handleSelected={handleSelected}
                  wrapperIndex={i}
                  noLabel={noLabel}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}
