import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
// imports from the lm-design-system library
import { LmCard, LmImage } from '@lm/lm-design-system/dist/components';

export default class LmConfOptionCard extends React.Component {
  render() {
    const { el, i, handleSelectedConfOptions, selectedConfOption } = this.props;

    return (
      <div
        className={`${style.cardItemOutline} ${
          selectedConfOption === el.label ? style.selected : ''
        }`}
        onClick={() => handleSelectedConfOptions(el.label)}
      >
        <LmCard key={`config-option-card-${i}`}>
          <LmImage key={`config-option-image-${i}`} src={el.img} alt="lm logo" />
          <p key={`config-option-title-${i}`}>{i18next.t(el.key)}</p>
        </LmCard>
      </div>
    );
  }
}
