import React from 'react';
import style from './style.module.scss';

export default class LmStepperCard extends React.Component {
  render() {
    const {
      globalState,
      isAccessory,
      el,
      selected,
      handleSelected,
      wrapperIndex,
      size,
      noLabel,
    } = this.props;

    return (
      <div
        className={`
                    ${style.lmStepperCard} 
                    ${
                      !isAccessory
                        ? selected === el.label ||
                          (el.label === '' && selected === 'nessuna')
                          ? style.selected
                          : ''
                        : selected[wrapperIndex] !== undefined
                        ? el.label === selected[wrapperIndex].label
                          ? style.selected
                          : ''
                        : ''
                    }
                    ${el.disabled && style.disabled}
                `}
        style={{
          minWidth: size ? '300px' : 'auto',
          minHeight: size ? '300px' : 'auto',
        }}
        onClick={() =>
          !el.disabled &&
          handleSelected(
            globalState,
            el.label || 'nessuna',
            el.id,
            wrapperIndex
          )
        }
      >
        <div className={style.imgWrapper}>
          <img
            className={`${size ? style.big : ''}`}
            style={{
              maxHeight:
                el.label.toLowerCase() === 'porta finestra' ? 150 : 'auto',
            }}
            src={el.img}
            alt="card img"
          />
        </div>
        {
          !noLabel && (
            // <>
            //     {el.disabled && (el.label === "Legno" || el.label === "Legno <br />e alluminio")
            //         ?   <p>{el.label.replace("<br />", "")} <br/> non disponbile</p> :
            <p>{el.label.replace('<br />', '') || 'nessuna'}</p>
          )
          //     }
          // </>
        }
      </div>
    );
  }
}
