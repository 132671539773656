import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';

export default class LmStepperMnetsStep3 extends React.Component {
  render() {
    const { 
      globalState,
      handleSelected1stSection,
      handleSelected2ndSection,
      handleSelected3rdSection,
      selected1stSection,
      selected2ndSection,
      selected3rdSection,
      stepInfos: { 
        title1stSection, 
        title2ndSection, 
        title3rdSection,
        options1stSection, 
        options2ndSection,
        options3rdSection
      }
    } = this.props;

    return(
      <>
        <LmStepperSectionContainer title={title1stSection}>
          <LmStepperCardWrapper 
            globalState={globalState}
            handleSelected={handleSelected1stSection}  
            options={options1stSection} 
            selected={selected1stSection} 
          />
        </LmStepperSectionContainer>
        {selected1stSection &&
          <LmStepperSectionContainer title={title2ndSection}>
            <LmStepperCardWrapper 
              globalState={globalState}
              handleSelected={handleSelected2ndSection}  
              options={options2ndSection} 
              selected={selected2ndSection} 
            />
          </LmStepperSectionContainer>
        }
        {selected1stSection && selected2ndSection &&
          <LmStepperSectionContainer title={title3rdSection}>
            <LmStepperCardWrapper 
              globalState={globalState}
              handleSelected={handleSelected3rdSection}  
              options={options3rdSection} 
              selected={selected3rdSection} 
            />
          </LmStepperSectionContainer>
        }
      </>  
    )
  }
}