import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';

import LmButton from '@lm/lm-design-system/dist/components/LmButton';
import LmStepperSummaryCard from '../LmStepperSummaryCard';
import LmStepperLinkProductModal from '../LmStepperLinkProductModal';
import LmStepperAppointmentRequestModal from '../LmStepperAppointmentRequestModal';
import LmStepperNewProductModal from '../LmStepperNewProductModal';
import LmStepperDuplicateModal from '../LmStepperDuplicateModal';
import LmEmailShare from '../LmEmailShare/';

export default class LmStepperSummaryContainer extends React.Component {
  render() {
    const {
      globalState,
      summaryCardsInfos,
      handleModify,
      handleDuplicate,
      handleNewCard,
      changeQuantity,
      handleLinkProductModal,
      newProductModalOpened,
      productModalOpened,
      configuratorsOptions,
      selectedNewProduct,
      handleNewProductModal,
      selectedToBeLinkedProduct,
      selectedLinkedProduct,
      handleLinkedProduct,
      appointmentModalOpened,
      appointmentForm,
      isLogged,
      storeDetails,
      currentGroup,
      isFromProjectCard,
      duplicateModalOpened,
      handleSummaryCardDuplicateModal,
      shareEmailModalOpened,
    } = this.props;

    const groupedCardsInfos = summaryCardsInfos.reduce(
      (h, obj) =>
        Object.assign(h, { [obj.group]: (h[obj.group] || []).concat(obj) }),
      {}
    );

    return (
      <>
        {Object.values(groupedCardsInfos).map((el, i) => {
          return (
            <LmStepperSummaryCard
              globalState={globalState}
              key={`summary-card-${i}`}
              el={el}
              handleLinkProductModal={handleLinkProductModal}
              handleModify={handleModify}
              duplicateModalOpened={duplicateModalOpened}
              changeQuantity={changeQuantity}
              isFromProjectCard={isFromProjectCard}
              handleDuplicate={handleDuplicate}
              handleSummaryCardDuplicateModal={handleSummaryCardDuplicateModal}
            />
          );
        })}
        {!isFromProjectCard && (
          <div className={style.newProjectButtonCont}>
            <div className={style.newProjectButton}>
              <LmButton onClick={() => handleNewProductModal(globalState)}>
                {i18next.t('windows.LM_STEPPER_SUMMARY_CONTAINER.newFixture')}
              </LmButton>
            </div>
          </div>
        )}

        <LmStepperNewProductModal
          globalState={globalState}
          newProductModalOpened={newProductModalOpened}
          handleNewProductModal={handleNewProductModal}
          configuratorsOptions={configuratorsOptions}
          selectedNewProduct={selectedNewProduct}
          handleNewCard={handleNewCard}
        />

        <LmStepperLinkProductModal
          globalState={globalState}
          productModalOpened={productModalOpened}
          handleLinkProductModal={handleLinkProductModal}
          configuratorsOptions={configuratorsOptions}
          selectedToBeLinkedProduct={selectedToBeLinkedProduct}
          selectedLinkedProduct={selectedLinkedProduct}
          handleLinkedProduct={handleLinkedProduct}
          currentGroup={currentGroup}
          summaryCardsInfos={summaryCardsInfos}
        />

        <LmStepperAppointmentRequestModal
          globalState={globalState}
          isLogged={isLogged}
          appointmentModalOpened={appointmentModalOpened}
          appointmentForm={appointmentForm}
          storeDetails={storeDetails}
        />

        <LmStepperDuplicateModal
          globalState={globalState}
          duplicateModalOpened={duplicateModalOpened}
          handleSummaryCardDuplicateModal={handleSummaryCardDuplicateModal}
          handleDuplicate={handleDuplicate}
        />
        <LmEmailShare
          globalState={globalState}
          shareEmailModalOpened={shareEmailModalOpened}
        />
      </>
    );
  }
}
