import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';
import i18next from '../../../utils/i18n';

export default class LmStepperWinStep4 extends React.Component {
  render() {
    const {
      globalState,
      handleSelected1stSection,
      handleSelected2ndSection,
      handleSelected3rdSection,
      selected1stSection, //first item chosen on step 4
      selected2ndSection, //second item chosen on step 4
      selected3rdSection, // third item chosen on step 4

      stepInfos: {
        title1stSection,
        title2ndSection,
        title3rdSection,
        options1stSection,
        options2ndSection,
        options3rdSection,
      },
    } = this.props;

    console.log('selected3rdSection', selected3rdSection);
    return (
      <>
        <LmStepperSectionContainer title={i18next.t(title1stSection)}>
          <LmStepperCardWrapper
            globalState={globalState}
            handleSelected={handleSelected1stSection}
            options={options1stSection}
            selected={selected1stSection}
            // noLabel
          />
        </LmStepperSectionContainer>
        {/* selected1stSection &&
          <LmStepperSectionContainer title={i18next.t(title2ndSection)}>
            <LmStepperCardWrapper 
              globalState={globalState}
              handleSelected={handleSelected2ndSection}  
              options={options2ndSection} 
              selected={selected2ndSection}
              noLabel
            />
          </LmStepperSectionContainer> */}
        {selected1stSection && (
          <LmStepperSectionContainer title={i18next.t(title3rdSection)}>
            <LmStepperCardWrapper
              globalState={globalState}
              handleSelected={handleSelected3rdSection}
              options={options3rdSection}
              selected={selected3rdSection}
            />
          </LmStepperSectionContainer>
        )}
      </>
    );
  }
}
