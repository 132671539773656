import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
import regPDF from '../../docs/20210223_regulamin-konfigurator-leroy-merlin.pdf';
// imports from the lm-design-system library
import { LmButton, LmInput } from '@lm/lm-design-system/dist/components';
// imports from material-ui
import { Dialog, TextareaAutosize } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

// images
import storeIconGreen from '../../assets/images/store-green.svg';

import {
  handleAppointmentRequestModal,
  handleAppointmentRequest,
} from '../../utils/commons/appointmentFunctions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const GreenCheckbox = withStyles({
  root: {
    marginLeft: '7px',
    color: '#CBCBCB',
    '&$checked': {
      color: '#78BE20',
    },
  },

  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class LmStepperAppointmentRequestModal extends React.Component {
  state = {
    privacyCheckbox: false,
    appointmentForm: {
      projectName: this.props.appointmentForm.projectName || '',
      name: this.props.appointmentForm.name || '',
      surname: this.props.appointmentForm.surname || '',
      email: this.props.appointmentForm.email || '',
      phonenumber: this.props.appointmentForm.phonenumber || '',
      note: this.props.appointmentForm.note || '',
    },
    show: false,
  };

  componentDidMount() {
    const { globalState, isLogged } = this.props;
    const { appointmentForm } = globalState();

    if (isLogged) {
      const {
        name,
        surname,
        emailAddress,
        phone1: { number },
      } = isLogged.mainContact;

      globalState({
        appointmentForm: {
          ...appointmentForm,
          name: name,
          surname: surname,
          email: emailAddress,
          phonenumber: number,
        },
      });
      this.setState({
        appointmentForm: {
          ...appointmentForm,
          name: name,
          surname: surname,
          email: emailAddress,
          phonenumber: number,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { globalState, isLogged } = this.props;
    const { appointmentForm } = globalState();

    if (prevProps.isLogged !== isLogged) {
      if (isLogged) {
        const {
          name,
          surname,
          emailAddress,
          phone1: { number },
        } = isLogged.mainContact;

        globalState({
          appointmentForm: {
            ...appointmentForm,
            name: name,
            surname: surname,
            email: emailAddress,
            phonenumber: number,
          },
        });
        this.setState({
          appointmentForm: {
            ...appointmentForm,
            name: name,
            surname: surname,
            email: emailAddress,
            phonenumber: number,
          },
        });
      } else {
        globalState({
          appointmentForm: {
            ...appointmentForm,
            name: '',
            surname: '',
            email: '',
            phonenumber: '',
            note: '',
          },
        });
        this.setState({
          appointmentForm: {
            ...appointmentForm,
            name: '',
            surname: '',
            email: '',
            phonenumber: '',
            note: '',
          },
        });
      }
    }
  }

  setAppointmentForm = (globalState, type, value) => {
    const state = globalState();
    globalState({
      appointmentForm: { ...state.appointmentForm, [type]: value },
    });
    this.setState({
      appointmentForm: { ...this.state.appointmentForm, [type]: value },
    });
  };

  handlePrivacyCheckbox = () =>
    this.setState({ privacyCheckbox: !this.state.privacyCheckbox });

  render() {
    const { globalState, storeDetails, appointmentModalOpened } = this.props;
    const {
      privacyCheckbox,
      appointmentForm: { projectName, name, surname, email, phonenumber, note },
    } = this.state;

    const lessInfo = i18next.t('windows.PRIVACY_POLICY.less');
    const moreInfo = i18next.t('windows.PRIVACY_POLICY.more');

    return (
      <Dialog
        open={appointmentModalOpened}
        onClose={() => handleAppointmentRequestModal(globalState)}
        maxWidth="xl"
      >
        <div className={style.appointmentForm}>
          <div className={style.title}>
            {i18next.t(
              'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.requestAppointment'
            )}
          </div>
          <div className={style.LmInput}>
            <LmInput
              label={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.projectName'
              )}
              maxLenght="20"
              name="appointment-input-projectname"
              type="text"
              handleChange={(e) =>
                this.setAppointmentForm(
                  globalState,
                  'projectName',
                  e.target.value
                )
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={projectName}
            />
          </div>
          <div className={style.LmInput}>
            <LmInput
              label={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.email'
              )}
              maxLenght="20"
              name="appointment-input-email"
              type="email"
              handleChange={(e) =>
                this.setAppointmentForm(globalState, 'email', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={email}
            />
          </div>
          <div className={style.LmInput}>
            <LmInput
              label={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.firstName'
              )}
              maxLenght="20"
              name="appointment-input-name"
              type="text"
              handleChange={(e) =>
                this.setAppointmentForm(globalState, 'name', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={name}
            />
          </div>
          <div className={style.LmInput}>
            <LmInput
              label={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.lastName'
              )}
              maxLenght="20"
              name="appointment-input-surname"
              type="text"
              handleChange={(e) =>
                this.setAppointmentForm(globalState, 'surname', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={surname}
            />
          </div>
          <div className={style.LmInput}>
            <LmInput
              label={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.phone'
              )}
              maxLenght="20"
              name="appointment-input-phone-number"
              type="number"
              handleChange={(e) =>
                this.setAppointmentForm(
                  globalState,
                  'phonenumber',
                  e.target.value
                )
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={phonenumber}
            />
          </div>
          <div className={style.LmInput}>
            <textarea
              className={style.notes}
              placeholder={i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.notes'
              )}
              maxLenght="255"
              name="appointment-input-note"
              type="text"
              handleChange={(e) =>
                this.setAppointmentForm(globalState, 'note', e.target.value)
              }
              onKeyPress={(event) =>
                event.charCode === 13 && handleAppointmentRequest(globalState)
              }
              value={note}
            />
          </div>
          {/* Privacy Box */}
          {/* Policy Wrappper */}
          <div className={style.toggleMenu}>
            <a
              className={style.toggleText}
              href="https://www.leroymerlin.pl/polityka-prywatnosci.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {' '}
              {i18next.t('windows.PRIVACY_POLICY.header')}
            </a>
            <span
              className={style.pointer}
              onClick={() => {
                this.setState({ show: !this.state.show });
              }}
            >
              {this.state.show ? lessInfo : moreInfo}
            </span>
          </div>
          {this.state.show && (
            <div className={style.privacyContainer}>
              <div className={style.privacyText}>
                {i18next.t('windows.PRIVACY_POLICY.policy')}
                <a
                  href="https://www.leroymerlin.pl/polityka-prywatnosci.html"
                  target="_blank"
                >
                  {i18next.t('windows.PRIVACY_POLICY.link')}
                </a>
                .
              </div>
            </div>
          )}

          <div className={style.informationText}>
            <a href={regPDF} target="_blank" rel="noopener noreferrer">
              {' '}
              {i18next.t(
                'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.pdfRegulation'
              )}{' '}
            </a>
          </div>
          <div className={style.store}>
            <div>
              <img
                className={style.imgStore}
                src={storeIconGreen}
                alt="img account"
              />
            </div>

            <div className={style.spanStore}>
              <div className={style.spanNavStore1}>
                {/* Il mio negozio */}
                {i18next.t(
                  'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.myStore'
                )}
              </div>
              <div className={style.spanNavStore2}>
                {storeDetails && storeDetails.storeName}
              </div>
            </div>
          </div>

          <div className={style.privacy}>
            <div className={style.privacyAlign}>
              <div className={style.checkBoxContainer}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={privacyCheckbox}
                      onChange={this.handlePrivacyCheckbox}
                      name="privacyCheckbox"
                    />
                  }
                  // label={i18next.t(
                  //   'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.agreement'
                  // )}
                />
              </div>
              <div className={style.textAlignment}>
                <span>
                  {i18next.t(
                    'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.agreement1'
                  )}
                  <a
                    href="https://www.leroymerlin.pl/polityka-prywatnosci.html"
                    target="_blank"
                  >
                    {i18next.t(
                      'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.agreementPrivacy'
                    )}
                  </a>
                  {i18next.t(
                    'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.agreement'
                  )}{' '}
                </span>
              </div>
            </div>
            {/* <div className={style.docLink}>
              <a href={`${process.env.PUBLIC_URL}/privacy-policy.pdf`} download> */}
            {/* Privacy */}
            {/* {i18next.t(
                  'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.privacy'
                )}
              </a>
            </div> */}

            {/* Cookie Policy */}
            {/* <div className={style.docLink}>
              <a href={`${process.env.PUBLIC_URL}/cookie-policy.pdf`} download>
                {i18next.t(
                  'windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.cookiePolicy'
                )}
              </a>
            </div> */}
          </div>

          <div className={style.LmButton}>
            <LmButton
              disabled={
                !(
                  privacyCheckbox &&
                  projectName &&
                  name &&
                  surname &&
                  email &&
                  phonenumber
                )
              }
              onClick={() => handleAppointmentRequest(globalState)}
            >
              {/* INVIA */}
              {i18next.t('windows.LM_STEPPER_APPOINTMENT_REQUEST_MODAL.send')}
            </LmButton>
          </div>
        </div>
      </Dialog>
    );
  }
}
