import loadJSON from './loadJSON';

export const env_default = loadJSON(`${process.env.PUBLIC_URL}/config.json`);
export const env_dev = loadJSON(`${process.env.PUBLIC_URL}/config.dev.json`);
export const env_local = loadJSON(`${process.env.PUBLIC_URL}/config.local.json`);
export const env_test = loadJSON(`${process.env.PUBLIC_URL}/config.test.json`);
export const env_prod = loadJSON(`${process.env.PUBLIC_URL}/config.prod.json`);

//console.log(`loadJSON ${process.env.NODE_ENV}`)
let inner_env = undefined;

const qa_domain = 'oaa-mom-windows-test.leroymerlin.pl';
const dev_domain = 'oaainfissi.dev.cloud.leroymerlin.it';


if( (window.location.host.indexOf('test') >= 0 ) || (window.location.host.indexOf(qa_domain) >= 0 ) ){
    inner_env = env_test;
}
else if( (window.location.host.indexOf('int.cloud') >= 0 ) || (window.location.host.indexOf(dev_domain) >= 0 ) ) {
    inner_env = env_dev;
}
else if(window.location.host.indexOf('dev.cloud') >= 0 ||
    window.location.host.indexOf(dev_domain) >= 0) {
    inner_env = env_dev;
}
else if(window.location.host.indexOf('localhost') >= 0){
    inner_env = env_local;
}
else{
    inner_env = env_default;
}
if(!inner_env.USE_GUEST_USER){
    inner_env.USE_GUEST_USER=true;
}
export const env = inner_env;
