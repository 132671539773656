import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperCardWrapper from '../../LmStepperCardWrapper';
import i18next from '../../../utils/i18n';

export default class LmStepperWinStep5 extends React.Component {
  render() {
    const { 
      globalState,
      handleSelected1stSection,
      handleSelected2ndSection,
      selected1stSection,
      selected2ndSection,
      stepInfos: { 
        title1stSection,
        title2ndSection,
        title3rdSection,
        title4thSection,
        options1stSection,
        options2ndSection,
        options3thSection,
        options4thSection
      }
    } = this.props;

    return(
      <>
      <LmStepperSectionContainer title={i18next.t(title1stSection)}>
        <LmStepperCardWrapper 
          globalState={globalState}
          options={options1stSection} 
          selected={selected1stSection} 
          handleSelected={handleSelected1stSection}
        />
      </LmStepperSectionContainer>
      <LmStepperSectionContainer title={i18next.t(title2ndSection)}>
        <LmStepperCardWrapper 
          globalState={globalState}
          options={options2ndSection} 
          selected={selected1stSection} 
          handleSelected={handleSelected1stSection}
        />
      </LmStepperSectionContainer>
      <LmStepperSectionContainer title={i18next.t(title3rdSection)}>
        <LmStepperCardWrapper 
          globalState={globalState}
          options={options3thSection} 
          selected={selected1stSection} 
          handleSelected={handleSelected1stSection}
        />
      </LmStepperSectionContainer>
      <LmStepperSectionContainer title={i18next.t(title4thSection)}>
        <LmStepperCardWrapper 
          globalState={globalState}
          options={options4thSection} 
          selected={selected1stSection} 
          handleSelected={handleSelected1stSection}
        />
      </LmStepperSectionContainer>
    </>
    )
  }
}