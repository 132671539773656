import React from 'react';
import style from './style.module.scss';

// imports from the lm-design-system library
// import { LmImage } from '@lm/lm-design-system/dist/components';

export default class LmStepperCardColor extends React.Component {
  render() {
    const { el, selected, handleSelected, globalState } = this.props;

    return(
      <div 
        className={`${style.lmStepperCardColor}`} 
        onClick={() => handleSelected(globalState, el.label, el.id)}
      > 
        <div
          className={`${style.colorBg} ${selected === el.label ? style.selected : ""}`} 
          style={{ backgroundImage: `url(${el.img})` }}></div>
        <div className={style.colorLabel}>{el.label}</div>
      </div>
    )
  }
}