import axios from 'axios';
import { PROJECT, PROJECT_LOGGED, GET_PROJECT_PDF } from '../api';
import { session, handleLoginModal } from './loginFunctions';
import { sendMailToUser } from './appointmentFunctions';
import { detectDevice, sendAnalytics, getStepsLabels } from './index';
import { env } from '../env';
import i18next from '../i18n';

const projectReducer = (globalState, contactInfo) => {
  const state = globalState();
  const { store, projectName, totalPrice, windows, note } = state;
  const { uuid, name, surname, emailAddress, phone1, guest, anonymous} = contactInfo.mainContact;
  console.log(
    'entityExtensions?.PrivacyFlags?.flagMap?.PRIVACY.PROTECTION.ACCEPTED',
    contactInfo.entityExtensions?.PrivacyFlags?.flagMap[
      'PRIVACY.PROTECTION.ACCEPTED'
    ],
    contactInfo.entityExtensions
  );

  return {
    guest: guest,
    anonymous: anonymous,
    title: projectName || '', // string Titolo del progetto
    storeId: store || '', // integer ID del negozio
    clientFirstName: name || '', // string Nome del cliente
    clientLastName: surname || '', // string Cognome del cliente
    clientEmail: emailAddress || '', // string Email del cliente
    clientPhone: phone1?.number || '', // string Numero di telefono del cliente
    note: note || '', // string Note del progetto per il negozio
    acceptedPrivacy:
      contactInfo.entityExtensions?.PrivacyFlags?.flagMap[
        'PRIVACY.PROTECTION.ACCEPTED'
      ] || '', // boolean Accettazione o meno della normativa sulla privacy da parte del cliente
    price: totalPrice || '', // number The total price
    windows: windows || [], // all items
    lmUniqueId: uuid || '', // string nullable: true Leroy Merlin Logged In User Unique Identifier
    windowBackGroundImgPaths: state.windowBackGroundImgPaths
  };
};

const showPDF = (params) => {
  axios
    .post(`${GET_PROJECT_PDF}`, params, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const objectUrl = window.URL.createObjectURL(blob);
      window.open(objectUrl);
    })
    .catch((error) => {
      console.log(error);
    });
};

const showPDFfromSummary = (globalState) => {
  let contactInfo = {
    mainContact: {
      guest: true,
      anonymous: true,
      uuid: '',
      name: '',
      surname: '',
      emailAddress: '',
      phone1: '',
    },
  };
  const postParams = projectReducer(globalState, contactInfo);
  showPDF(postParams);
};

const submitProject = (globalState, contactInfo) => {
  const postParams = projectReducer(globalState, contactInfo);

  const { storeDetails, selectedConfOption } = globalState();

  console.log('{Project} saving', postParams);

  globalState({ isLoading: true });

  if (contactInfo.mainContact.name === 'GUEST' && env.REACT_LM_LOGIN_URL) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(
        'WIN-' + postParams.title,
        JSON.stringify(postParams)
      );
      globalState({
        isLoading: false,
        modalOpened: true,
        modalMessage: {
          title: i18next.t('windows.LM_MODAL.projectSaved'),
          text: ``,
          buttonCallback: () => showPDF(postParams),
          code: 'WIN-' + postParams.title,
          callback: () => {
            // Redirect in home?
            // window.location.href = "./";
            // eslint-disable-next-line
            /* if(utag){
              // eslint-disable-next-line
              utag.link({
                page_name: [
                  'configuratore',
                  selectedConfOption.toLowerCase(),
                  'create',
                  '0',
                ],
                page_layout: detectDevice(),
                event_type: 'azione-progetto',
                event_action: 'crea-progetto',
                negozio: storeDetails.storeName,
              });
            } */
          },
        },
        projectCode: 'WIN-' + postParams.title,
      });
      resolve();
    });
  }

  return axios
    .post(PROJECT, postParams)
    .then(({ data }) => {
      console.log('{Project} saved', data);
      globalState({ isLoading: true });
      sendMailToUser(globalState).then((result) => {
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: {
            title: 'Progetto salvato',
            text: `${data.message.msg}`,
            buttonCallback: () => showPDF(postParams),
            code: data.data.code,
            callback: () => {
              // Redirect in home?
              // window.location.href = "./";

              // eslint-disable-next-line
              if (utag) {
                // eslint-disable-next-line
                utag.link({
                  page_name: [
                    'configuratore',
                    selectedConfOption.toLowerCase(),
                    'create',
                    '0',
                  ],
                  page_layout: detectDevice(),
                  event_type: 'azione-progetto',
                  event_action: 'crea-progetto',
                  negozio: storeDetails.storeName,
                });
              }
            },
          },
          projectCode: data.data.code,
        });
      });
    })
    .catch((error) => {
      console.log('{Project} error', error);
      if (error?.response?.data?.message?.msg) {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: `Errore`,
            text: error?.response?.data?.message?.msg,
          },
        });
      }
    });
};

const submitProjectFast = async (globalState, contactInfo) => {
  const postParams = projectReducer(globalState, contactInfo);

  console.log('{Project} saving fast', postParams);

  /* if(contactInfo.mainContact.name === 'GUEST' && env.REACT_LM_LOGIN_URL){
    const p = new Promise((resolve, reject) => {
      localStorage.setItem('WIN-'+postParams.title, JSON.stringify(postParams));
      resolve(postParams);
    }
    );
    return p.then((p) => {
      console.log('{Project} saved', p);
      return p;
    })
    .catch((error) => {
      console.log('{Project} error', error);
      return error;
    });
  } */

  return await axios
    .post(PROJECT, postParams)
    .then(({ data }) => {
      console.log('{Project} saved', data);
      return data;
    })
    .catch((error) => {
      console.log('{Project} error', error);
      return error;
    });
};

const saveProject = (globalState) => {
  const state = globalState();
  const { isLogged } = state;

  sendAnalytics('salva-progetto', state);

  if (isLogged) {
    // fetch
    submitProject(globalState, isLogged);
  } else {
    // login then fetch
    session('savingProject', true);
    handleLoginModal(globalState);
  }
};

const deleteProject = (globalState, id) => {
  const state = globalState();
  const { isLogged, projects } = state;
  if (isLogged) {
    globalState({ isLoading: true });
    return axios
      .delete(`${PROJECT}/${id}`)
      .then(({ data }) => {
        let newProjects = projects.filter((prj) => prj.id !== id);
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: {
            title: `Progetto eliminato`,
            text: `Progetto eliminato correttamente`,
          },
          projects: newProjects,
        });
        sendAnalytics('elimina', state);
      })
      .catch((error) => {
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: {
            title: `Errore`,
            text: error.message || JSON.stringify(error),
          },
        });
      });
  }
};

const getProjects = (globalState) => {
  const state = globalState();

  if (state.isLogged && state.isLogged.mainContact.guest) {
    globalState({ projects: [], isLoading: false });
    return;
  }

  if (state.isLogged) {
    const customerID = state.isLogged.mainContact.uuid;
    globalState({ isLoading: true });
    return axios
      .get(PROJECT_LOGGED + '/' + customerID)
      .then(({ data }) => {
        globalState({ projects: data.data, isLoading: false });
      })
      .catch((error) => {
        globalState({
          isLoading: false,
          modalOpened: true,
          modalMessage: {
            title: `Errore`,
            text: error?.response?.data?.message?.msg,
          },
        });
      });
  }
};

const handleProjectCardModify = (globalState, id) => {
  const state = globalState();
  const { projects } = state;
  const selectedProjectCard = projects.find((el) => el.id === id);

  const { project_windows, price, title } = selectedProjectCard;

  const newSummaryCardsInfos = [
    ...project_windows.map((el) => {
      let infos = {};

      const {
        code,
        title,
        price,
        project_window_selections,
        quantity,
        group,
        preview,
      } = el;

      const selectedConfOption = code.includes('NET-')
        ? 'Zanzariere'
        : code.includes('BLI-')
        ? 'Tapparelle'
        : code.includes('WIN-')
        ? 'Finestre'
        : '';

      if (selectedConfOption === 'Zanzariere') {
        infos = {
          cardName: title,
          selectedConfOption: selectedConfOption,
          id: code.slice(-1),
          code: code,
          price: price,
          height: project_window_selections[1].values[0].value,
          width: project_window_selections[1].values[1].value,
          selection1: project_window_selections[0].values[0].item.title,
          selection2: project_window_selections[2].values[0].item.title,
          selection3: project_window_selections[3].values[0].item.title,
          selection4: project_window_selections[4].values[0].item.title,
          selection5: project_window_selections[5].values[0].item.title,
          selection6: project_window_selections[6].values[0].item.title,
          selection7: project_window_selections[7].values[0].item.title,
          selection8:
            project_window_selections[7].values[0].color_reference
              .color_description,
          selection9: project_window_selections[8].values[0].item.title,
          accessories: project_window_selections[9].section.items.map((el) => ({
            label: el.title,
          })),
          preview: preview.image,
          quantity: quantity,
          group: group,
        };
      }

      if (selectedConfOption === 'Tapparelle') {
        infos = {
          cardName: title,
          selectedConfOption: selectedConfOption,
          id: code.slice(-1),
          code: code,
          price: price,
          height: project_window_selections[1].values[0].value,
          width: project_window_selections[1].values[1].value,
          selection1: project_window_selections[0].values[0].item.title,
          selection2: project_window_selections[2].values[0].item.title,
          selection3: project_window_selections[3].values[0].item.title,
          selection4: project_window_selections[4].values[0].item.title,
          selection5:
            project_window_selections[3].values[0].item.title === 'DUERO'
              ? project_window_selections[5].values[0].item.title
              : null,
          accessories: project_window_selections[6].section.items.map((el) => ({
            label: el.title,
          })),
          preview: preview.image,
          colorPreview:
            project_window_selections[3].values[0].item.title !== 'DUERO'
              ? require(`../../assets${project_window_selections[4].values[0].color_reference.meta.image}`)
              : null,
          colorPreviewExt:
            project_window_selections[3].values[0].item.title === 'DUERO'
              ? require(`../../assets${project_window_selections[4].values[0].color_reference.meta.image}`)
              : null,
          colorPreviewInt:
            project_window_selections[3].values[0].item.title === 'DUERO'
              ? require(`../../assets${project_window_selections[5].values[0].color_reference.meta.image}`)
              : null,
          quantity: quantity,
          group: group,
        };
      }

      if (selectedConfOption === 'Finestre') {
        const itemCodeBack = project_window_selections[5].section.items[1].meta.data.values.find(
          (el) => el.name === project_window_selections[5].values[1].value.title
        ).code;
        const frameImg = project_window_selections[2].section.items
          .find(
            (el) =>
              el.title === project_window_selections[2].values[0].item.title
          )
          .meta.image.replace('/images/', '');
        const itemImageBack = require(`../../assets/images/configurator/types/colors/${itemCodeBack}/${frameImg}`);

        infos = {
          cardName: title,
          selectedConfOption: selectedConfOption,
          id: code.slice(-1),
          code: code,
          price: price,
          height: project_window_selections[1].values[0].value,
          width: project_window_selections[1].values[1].value,
          selection1: project_window_selections[0].values[0].item.title,
          selection2: project_window_selections[2].values[0].item.title,
          selection3: project_window_selections[3].values[0].item.title,
          selection4: project_window_selections[4].values[0].item.title,
          selection5:
            project_window_selections[4].values[0].item.title !== 'Legno'
              ? project_window_selections[5].values[0].value.title
              : null,
          selection6:
            project_window_selections[4].values[0].item.title !== 'Legno'
              ? project_window_selections[5].values[1].value.title
              : project_window_selections[5].values[0].item.title,
          selection7: project_window_selections[6].values[0].value.title,
          selection8: project_window_selections[6].values[1].value.title,
          selection9: project_window_selections[6].values[2].value.title,
          selection10: project_window_selections[7].values[0].value.title,
          previewFront: preview.image,
          previewBack: itemImageBack,
          quantity: quantity,
          group: group,
        };
      }

      return infos;
    }),
  ];

  globalState(
    {
      isFromProjectCard: true,
      summaryCardsInfos: newSummaryCardsInfos,
      selectedConfOption: 'Finestre',
      windows: project_windows,
      totalPrice: price,
      projectName: title,
    },
    () => {
      getStepsLabels(globalState);
      globalState({
        currentStep: 7,
      });
    }
  );
};

export {
  submitProject,
  submitProjectFast,
  saveProject,
  deleteProject,
  getProjects,
  handleProjectCardModify,
  showPDFfromSummary,
};
