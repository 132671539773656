import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';
// imports from the lm-design-system library
import { LmButton } from '@lm/lm-design-system/dist/components';
// imports from material-ui
import { Dialog } from '@material-ui/core';

export default class LmModal extends React.Component {
  render() {
    const { modalOpened, modalClosed, modalMessage } = this.props;

    // translation
    const projectCode = i18next.t('windows.LM_MODAL.projectCode');
    const printProject = i18next.t('windows.LM_MODAL.printProject');
    const continueProject = i18next.t('windows.LM_MODAL.continue');

    return (
      <Dialog open={modalOpened} onClose={modalClosed} maxWidth="xl">
        <div className={style.loginForm}>
          <div className={style.title}>
            {modalMessage && modalMessage.title}
          </div>
          <div className={style.LmInput}>
            {modalMessage && modalMessage.text}
            {modalMessage && modalMessage.code && (
              <div className={style.codePRJ}>
                {projectCode} <span>{modalMessage.code}</span>
              </div>
            )}
          </div>
          <div className={style.LmButton}>
            {modalMessage && modalMessage.buttonCallback && (
              <>
                <LmButton
                  flavour="secondary-green"
                  onClick={modalMessage.buttonCallback}
                >
                  {printProject}
                </LmButton>
                <br />
                <br />
              </>
            )}
            <LmButton
              onClick={() => {
                modalClosed();
                if (modalMessage && modalMessage.callback) {
                  modalMessage.callback();
                }
              }}
            >
              {continueProject}
            </LmButton>
          </div>
        </div>
      </Dialog>
    );
  }
}
