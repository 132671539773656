import React from 'react';
import axios from 'axios';
import i18next from '../../utils/i18n';

import { isTablet, isMobileOnly } from 'react-device-detect';
import { STEPPER_STEPS_API, STEPS_INFOS_API, FAST_TRACK } from '../api';
import { saveProject, showPDFfromSummary } from './projectFunctions';
import { session } from './loginFunctions';
import { handleAppointmentRequestModal } from './appointmentFunctions';
import { handleShareRequestModal } from './shareProjectService';

import {
  stepInfosMnetsItem,
  stepInfosBlindsItem,
  stepInfosWindowsItem,
  stepInfosAccessories,
  stepInfosBlindsAccessories,
  stepSelectionsItem,
  sectionFiltered,
  windowsFilterSelectedById,
  windowsFilteredSelectedList,
  windowsFilterDataFromList,
  stepInfosItemWindowsFeaturesOptions,
  stepInfosItemWindowsAccessoriesOptions,
  blindsAccessoriesFiltered,
  getSelectedOptionId,
} from './genericStepperFunctions';
import {
  handleConfiguratorHeight,
  handleConfiguratorWidth,
  handleSelected1stStep1stSection,
  handleSelected2ndStep1stSection,
  handleSelected2ndStep2ndSection,
  handleSelected3rdStep1stSection,
  handleSelected3rdStep2ndSection,
  handleSelected3rdStep3rdSection,
  handleSelected4thStep1stSection,
  handleSelected4thStep2ndSection,
  handleSelected4thStep3rdSection,
  handleSelected5thStep1stSection,
  handleAccessories,
} from './handlesStepper';

const handleReset = (globalState) => {
  globalState({
    isLoading: false,

    selectedConfOption: 'Finestre',

    // modal
    modalOpened: false,
    modalClosed: () => globalState({ modalOpened: false }),
    modalMessage: null,

    // login
    isLogged: session('user') || false,
    credentials: {
      username: null,
      password: null,
    },
    project: {
      prjCode: null,
      prjEmail: null,
    },
    loginModalOpened: false,
    contactInfo: null,

    // stepper
    currentGroup: null,
    currentPreview: null,
    currentPreviewWindowsFront: null,
    currentPreviewWindowsBack: null,
    currentStepperId: null,
    currentStepperCode: null,
    currentPrice: 0,
    currentStep: 0,
    steps: [],
    stepsInfos: [],
    stepsLabels: [],
    stepsCode: [],
    stepperColorslist: null,
    stepperCanProceedMnets: [false, false, false, false, false, true, true],
    stepperCanProceedBlinds: [false, false, false, true, true],
    stepperCanProceedWindows: [false, false, false, false, false, true],
    totalPrice: 0,
    discountedTotalPrice: 0,
    newProductModalOpened: false,
    selectedNewProduct: null,
    productModalOpened: false,
    selectedToBeLinkedProduct: null,
    selectedLinkedProduct: null,
    appointmentModalOpened: false,
    appointmentForm: {},
    selectedBlindsColorImage: null,
    selectedBlindsColorImageExt: null,
    selectedBlindsColorImageInt: null,
    duplicateModalOpened: false,
    toBeDuplicatedCardItem: null,
    toBeDuplicatedWindowsItem: null,
    shareEmailModalOpened: false,
    // steps selections for BE
    selections: [],
    step1selections: null,
    step2selections: null,
    step3selections: null,
    step4selections: null,
    step5selections: null,
    step6selections: null,
    windows: [],

    // steps selections
    confHeightIsValid: false,
    confWidthIsValid: false,
    configuratorHeight: '',
    configuratorWidth: '',
    selectedDependsOns: [],
    selectedPrices: [],
    selectedAccessories: [],
    selectedAccessoriesItems: [],
    selectedAccessoriesPrices: [],
    selectedColorId: null,
    selected1stStep1stSection: null,
    selected2ndStep1stSection: null,
    selected2ndStep2ndSection: null,
    selected3rdStep1stSection: null,
    selected3rdStep2ndSection: null,
    selected3rdStep3rdSection: null,
    selected4thStep1stSection: null,
    selected4thStep2ndSection: null,
    selected4thStep3rdSection: null,
    selected5thStep1stSection: null,
    summaryCardsInfos: [],
  });
};

const sendAnalytics = (action, state) => {
  const {
    stepsLabels,
    stepsCode,
    currentStep,
    storeDetails,
    totalPrice,
    project,
    selectedConfOption,
  } = state;

  // eslint-disable-next-line
  /* if(!utag){
    return;
  } */
  // eslint-disable-next-line
  /* utag.link({
    page_name: [
      'configuratore',
      selectedConfOption.toLowerCase(),
      stepsLabels[currentStep - 1].split(' ').join('-').toLowerCase(),
      stepsCode[currentStep - 1].toString(),
    ],
    page_layout: detectDevice(),
    event_type: 'azione-progetto',
    event_action: action,
    progetto_id: project.prjCode ? project.prjCode.toLowerCase() : undefined,
    negozio: storeDetails.storeName,
    prezzo_totale: totalPrice,
  }); */
};

const sendPageViewAnalytics = (state) => {
  // eslint-disable-next-line
  /* if(!utag){
    return;
  }
  const {
    stepsLabels,
    stepsCode,
    currentStep,
    storeDetails,
    windows,
    totalPrice,
    project,
    selectedConfOption,
  } = state;

  let paramUtag = {
    page_name: [
      'configuratore',
      selectedConfOption.toLowerCase(),
      stepsLabels[currentStep]
        ? stepsLabels[currentStep].split(' ').join('-').toLowerCase()
        : '',
      stepsCode[currentStep].toString(),
    ],
    page_layout: detectDevice(),
    negozio: storeDetails.storeName,
  };
  if (stepsLabels.length === currentStep) {
    paramUtag = Object.assign(paramUtag, {
      numero_infissi: windows,
      prezzo_totale: totalPrice,
      progetto_id: project.prjCode ? project.prjCode.toLowerCase() : undefined,
    });
  }
  // eslint-disable-next-line
  if(!utag){
    return;
  }
  // eslint-disable-next-line
  utag.view(paramUtag); */
};

// Stepper summary functions
const createSummaryCardsInfos = (globalState) => {
  const state = globalState();

  const {
    selectedConfOption,
    projectName,
    backgroundBack,
    backgroundFront,
    currentPrice,
    configuratorHeight,
    configuratorWidth,
    selectedAccessories,
    selectedColorId,
    selected1stStep1stSection,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
    selected3rdStep1stSection,
    selected3rdStep2ndSection,
    selected3rdStep3rdSection,
    selected4thStep1stSection,
    selected4thStep2ndSection,
    selected4thStep3rdSection,
    selected5thStep1stSection,
    summaryCardsInfos,
    windows,
    selections,
    currentStepperId,
    currentStepperCode,
    selectedDependsOns,
    currentPreview,
    currentPreviewWindowsFront,
    currentPreviewWindowsBack,
    stepperColorslist,
    currentGroup,
    selectedBlindsColorImage,
    selectedBlindsColorImageExt,
    selectedBlindsColorImageInt,
  } = state;

  let newSummaryCardsInfos = [...summaryCardsInfos];
  let newWindows = [...windows];
  let newSummaryInfos;
  let newWindowsItem;

  const modifiedWindowsItem = newWindows.find(
    (item) => item.id === currentStepperId && item.code === currentStepperCode
  );
  const isToBeModified = newSummaryCardsInfos.find(
    (item) => item.id === currentStepperId && item.code === currentStepperCode
  );
  const cardIndex = newSummaryCardsInfos.indexOf(isToBeModified);

  const groups = newSummaryCardsInfos.map((el) => {
    return el.group;
  });
  const maxGroup = Math.max(...groups);

  const cardInfosMnets = {
    cardName: selectedConfOption + ' ' + projectName + ' ' + currentStepperId,
    selectedConfOption: selectedConfOption,
    id: currentStepperId,
    code: currentStepperCode,
    startingPrice: currentPrice,
    price: currentPrice,
    height: configuratorHeight,
    width: configuratorWidth,
    selection1: selected1stStep1stSection,
    selection2: selected2ndStep1stSection,
    selection3: selected2ndStep2ndSection,
    selection4: selected3rdStep1stSection,
    selection5: selected3rdStep2ndSection,
    selection6: selected3rdStep3rdSection,
    selection7: selected4thStep1stSection,
    selection8: selected4thStep2ndSection,
    selection9: selected5thStep1stSection,
    accessories: selectedAccessories,
    dependsOns: selectedDependsOns,
    colorList: stepperColorslist,
    colorId: selectedColorId,
    preview: currentPreview,
    quantity: isToBeModified ? isToBeModified.quantity : 1,
    group: isToBeModified
      ? isToBeModified.group
      : groups.length > 0
      ? currentGroup
        ? currentGroup
        : maxGroup + 1
      : 1,
  };

  const cardInfosBlinds = {
    cardName: selectedConfOption + ' ' + projectName + ' ' + currentStepperId,
    selectedConfOption: selectedConfOption,
    id: currentStepperId,
    code: currentStepperCode,
    price: currentPrice,
    height: configuratorHeight,
    width: configuratorWidth,
    selection1: selected1stStep1stSection,
    selection2: selected2ndStep1stSection,
    selection3: selected2ndStep2ndSection,
    selection4: selected3rdStep1stSection,
    selection5: selected3rdStep2ndSection,
    accessories: selectedAccessories,
    dependsOns: selectedDependsOns,
    colorList: stepperColorslist,
    preview: currentPreview,
    colorPreview: selectedBlindsColorImage,
    colorPreviewExt: selectedBlindsColorImageExt,
    colorPreviewInt: selectedBlindsColorImageInt,
    quantity: isToBeModified ? isToBeModified.quantity : 1,
    group: isToBeModified
      ? isToBeModified.group
      : groups.length > 0
      ? currentGroup
        ? currentGroup
        : maxGroup + 1
      : 1,
  };

  //selectedConfOption + ' ' +
  const cardInfosWindows = {
    cardName: projectName + ' ' + currentStepperId,
    selectedConfOption: selectedConfOption,
    id: currentStepperId,
    code: currentStepperCode,
    price: currentPrice,
    height: configuratorHeight,
    width: configuratorWidth,
    selection1: selected1stStep1stSection,
    selection2: selected2ndStep1stSection,
    selection3: selected2ndStep2ndSection,
    selection4: selected3rdStep1stSection,
    selection5: selected3rdStep2ndSection,
    selection6: selected3rdStep3rdSection,
    selection7: selected4thStep1stSection,
    selection8: selected4thStep2ndSection,
    selection9: selected4thStep3rdSection,
    selection10: selected5thStep1stSection,
    dependsOns: selectedDependsOns,
    previewFront: currentPreviewWindowsFront,
    previewBack: currentPreviewWindowsBack,
    quantity: isToBeModified ? isToBeModified.quantity : 1,
    group: isToBeModified
      ? isToBeModified.group
      : groups.length > 0
      ? currentGroup
        ? currentGroup
        : maxGroup + 1
      : 1,
  };

  const currentSide = cardInfosWindows.selection6.split('Kolor')[1];
  const intSide = ' wewnętrzny';
  const extSide = ' zewnętrzny';

  if (currentSide === intSide || currentSide === extSide) {
    const choosenColor = {
      title: 'Kolor' + currentSide + ':',
      value: cardInfosWindows.selection5,
    };

    const otherSide = currentSide === intSide ? extSide : intSide;
    const otherColor = {
      title: 'Kolor' + otherSide + ':',
      value: 'Biały',
    };

    cardInfosWindows.selection99 = choosenColor.value;
    cardInfosWindows.selection100 = otherColor.value;
    cardInfosWindows.selection111 = choosenColor.title;
    cardInfosWindows.selection122 = otherColor.title;
  } else {
    //case double side
    const choosenColor = {
      title: 'Kolor' + intSide + ':',
      value: cardInfosWindows.selection5,
    };

    const otherColor = {
      title: 'Kolor' + extSide + ':',
      value: cardInfosWindows.selection5,
    };

    cardInfosWindows.selection99 = choosenColor.value;
    cardInfosWindows.selection100 = otherColor.value;
    cardInfosWindows.selection111 = choosenColor.title;
    cardInfosWindows.selection122 = otherColor.title;
  }

  const windowsItem = {
    id: currentStepperId,
    code: currentStepperCode,
    price: currentPrice,
    quantity: isToBeModified ? modifiedWindowsItem.quantity : 1,
    title: selectedConfOption + ' ' + projectName + ' ' + currentStepperId,
    deleted: false,
    preview: {
      image:
        selectedConfOption === 'Finestre'
          ? currentPreviewWindowsFront
          : currentPreview,
      colorValue: selectedColorId || '',
      width: configuratorWidth,
      height: configuratorHeight,
      imageBack:
        selectedConfOption === 'Finestre' ? currentPreviewWindowsBack : '',
      backgroundBack: backgroundBack,
      backgroundFront: backgroundFront,
    },
    selections: selections,
    group: isToBeModified
      ? isToBeModified.group
      : groups.length > 0
      ? maxGroup + 1
      : 1,
  };
  if (isToBeModified) {
    newSummaryCardsInfos[cardIndex] =
      selectedConfOption === 'Zanzariere'
        ? cardInfosMnets
        : selectedConfOption === 'Tapparelle'
        ? cardInfosBlinds
        : selectedConfOption === 'Finestre'
        ? cardInfosWindows
        : {};
    newWindows[cardIndex] = windowsItem;
  } else {
    newSummaryInfos =
      selectedConfOption === 'Zanzariere'
        ? cardInfosMnets
        : selectedConfOption === 'Tapparelle'
        ? cardInfosBlinds
        : selectedConfOption === 'Finestre'
        ? cardInfosWindows
        : {};
    newWindowsItem = windowsItem;
  }

  globalState(
    {
      currentGroup: null,
      summaryCardsInfos: isToBeModified
        ? [...newSummaryCardsInfos]
        : [...summaryCardsInfos, newSummaryInfos],
      windows: isToBeModified ? [...newWindows] : [...windows, newWindowsItem],
    },
    () => {
      handleProjectTotal(globalState);
      getStepsInfos(globalState);
    }
  );
};

const handleLinkProductModal = (
  globalState,
  selectedConfOption,
  height,
  width,
  selection1,
  group
) => {
  const state = globalState();

  globalState({
    productModalOpened: !state.productModalOpened,
    selectedToBeLinkedProduct: selectedConfOption || null,
    selectedLinkedProduct: null,
    configuratorHeight: height || null,
    configuratorWidth: width || null,
    selected1stSection: selection1,
    currentGroup: group || null,
  });
};

const handleLinkedProduct = (globalState) => {
  const state = globalState();
  const { selectedLinkedProduct, summaryCardsInfos } = state;

  const cardCodePrefix =
    selectedLinkedProduct === 'Zanzariere'
      ? 'NET-'
      : selectedLinkedProduct === 'Tapparelle'
      ? 'BLI-'
      : selectedLinkedProduct === 'Finestre'
      ? 'WIN-'
      : '';

  const cardId = getSelectedOptionId(summaryCardsInfos, selectedLinkedProduct);

  const selected1stSection =
    selectedLinkedProduct === 'Zanzariere'
      ? 'Finestra'
      : selectedLinkedProduct === 'Tapparelle'
      ? 'Finestra / Porta Finestra'
      : selectedLinkedProduct === 'Finestre'
      ? 'Finestra'
      : '';

  const selectedDependsOns =
    selectedLinkedProduct === 'Zanzariere'
      ? selected1stSection === 'Finestra'
        ? { 0: 904 }
        : selected1stSection === 'Porta Finestra'
        ? { 0: 905 }
        : {}
      : selectedLinkedProduct === 'Tapparelle'
      ? selected1stSection === 'Finestra / Porta Finestra'
        ? { 0: 1210 }
        : {}
      : selectedLinkedProduct === 'Finestre'
      ? selected1stSection === 'Finestra'
        ? { 0: 100 }
        : selected1stSection === 'Porta Finestra'
        ? { 0: 101 }
        : {}
      : {};

  globalState(
    {
      productModalOpened: !state.productModalOpened,
      selectedToBeLinkedProduct: null,
      selectedLinkedProduct: null,

      selectedConfOption: selectedLinkedProduct,
      currentStepperId: cardId,
      currentStepperCode: cardCodePrefix + cardId,
      currentPrice: 0,
      currentStep: 0,
      selections: [],
      selectedDependsOns: selectedDependsOns,
      selectedAccessories: [],
      selectedColorId: null,
      selected1stStep1stSection: selected1stSection,
      selected2ndStep1stSection: null,
      selected2ndStep2ndSection: null,
      selected3rdStep1stSection: null,
      selected3rdStep2ndSection: null,
      selected3rdStep3rdSection: null,
      selected4thStep1stSection: null,
      selected4thStep2ndSection: null,
      selected5thStep1stSection: null,
      stepperColorslist: null,
    },
    () => {
      getStepsLabels(globalState);
      getStepsInfos(globalState);
    }
  );
};

const handleNewProductModal = (globalState) => {
  const state = globalState();
  globalState({
    newProductModalOpened: !state.newProductModalOpened,
    selectedNewProduct: null,
  });
};

const handleNewSummaryCard = (globalState) => {
  const state = globalState();
  const { selectedNewProduct, summaryCardsInfos } = state;

  const cardCodePrefix =
    selectedNewProduct === 'Zanzariere'
      ? 'NET-'
      : selectedNewProduct === 'Tapparelle'
      ? 'BLI-'
      : selectedNewProduct === 'Finestre'
      ? 'WIN-'
      : 'WIN-';

  const cardId = getSelectedOptionId(summaryCardsInfos, selectedNewProduct);

  globalState(
    {
      newProductModalOpened: !state.newProductModalOpened,
      selectedNewProduct: null,
      selectedConfOption: selectedNewProduct,
      currentStepperId: cardId,
      currentStepperCode: cardCodePrefix + cardId,
      currentPrice: 0,
      currentStep: 0,
      currentGroup: null,
      selections: [],
      confHeightIsValid: false,
      confWidthIsValid: false,
      configuratorHeight: '',
      configuratorWidth: '',
      selectedDependsOns: [],
      selectedPrices: [],
      selectedAccessories: [],
      selectedAccessoriesItems: [],
      selectedAccessoriesPrices: [],
      selectedColorId: null,
      selected1stStep1stSection: null,
      selected2ndStep1stSection: null,
      selected2ndStep2ndSection: null,
      selected3rdStep1stSection: null,
      selected3rdStep2ndSection: null,
      selected3rdStep3rdSection: null,
      selected4thStep1stSection: null,
      selected4thStep2ndSection: null,
      selected4thStep3rdSection: null,
      selected5thStep1stSection: null,
    },
    () => {
      getStepsLabels(globalState);
      getStepsInfos(globalState);
    }
  );
};

const handleSummaryCardDuplicate = (globalState, newHeight, newWidth) => {
  const state = globalState();
  const {
    toBeDuplicatedCardItem,
    toBeDuplicatedCardItem: { selectedConfOption, cardName },
    toBeDuplicatedWindowsItem,
    toBeDuplicatedWindowsItem: { selections },
    summaryCardsInfos,
    // windows
  } = state;

  let newSummaryInfos = { ...toBeDuplicatedCardItem };
  let newWindowsItem = { ...toBeDuplicatedWindowsItem };
  let newSelections = [...selections];

  newSelections[1].values[0].value = newHeight;
  newSelections[1].values[1].value = newWidth;

  const groups = summaryCardsInfos.map((el) => {
    return el.group;
  });
  const maxGroup = Math.max(...groups);

  const cardCodePrefix = toBeDuplicatedCardItem.code.slice(0, -1);
  const cardId = getSelectedOptionId(summaryCardsInfos, selectedConfOption);

  newSummaryInfos.cardName = cardName.slice(0, -1) + cardId;
  newSummaryInfos.id = summaryCardsInfos.length + 1;
  newSummaryInfos.code = cardCodePrefix + cardId;
  newSummaryInfos.group = maxGroup + 1;
  newSummaryInfos.height = newHeight;
  newSummaryInfos.width = newWidth;

  newWindowsItem.title = cardName.slice(0, -1) + cardId;
  newWindowsItem.id = cardId;
  newWindowsItem.code = cardCodePrefix + cardId;
  newWindowsItem.group = maxGroup + 1;
  newWindowsItem.selections = newSelections;

  sendAnalytics('duplica', state);

  axios
    .post(
      `${FAST_TRACK}${
        selectedConfOption === 'Zanzariere'
          ? '/mosquitonets'
          : selectedConfOption === 'Tapparelle'
          ? '/blinds'
          : selectedConfOption === 'Finestre'
          ? '/windows'
          : ''
      }`,
      {
        selections: newSelections,
      }
    )
    .then((res) => {
      if (selectedConfOption === 'Zanzariere') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
      }
      if (selectedConfOption === 'Tapparelle') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
      }
      if (selectedConfOption === 'Finestre') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
        // globalState({
        //   summaryCardsInfos: [...summaryCardsInfos, newSummaryInfos],
        //   windows: [...windows, newWindowsItem]
        // }, () => {
        //   handleProjectTotal(globalState)
        //   handleSummaryCardDuplicateModal(globalState)
        // })
      }
    })
    .catch((err) => {
      if (selectedConfOption === 'Zanzariere') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
      }
      if (selectedConfOption === 'Tapparelle') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
      }
      if (selectedConfOption === 'Finestre') {
        globalState({
          modalOpened: true,
          modalMessage: {
            title: 'Attenzione!',
            text: 'Servizio momentaneamente non disponbile',
          },
        });
        // globalState({
        //   modalOpened: true,
        //   modalMessage: { title: 'Attenzione!', text: 'Inserire delle dimensioni valide' }
        // })
      }
    });
};

const handleSummaryCardDuplicateModal = (globalState, id, code) => {
  // const { duplicateModalOpened, summaryCardsInfos, windows } = globalState();

  // if(!duplicateModalOpened) {
  //   const toBeDuplicatedCardItem = summaryCardsInfos.find(item => item.id === id && item.code === code)
  //   const toBeDuplicatedWindowsItem = windows.find(item => item.id === id && item.code === code)

  //   globalState({
  //     toBeDuplicatedCardItem,
  //     toBeDuplicatedWindowsItem
  //   })
  // } else {
  //   globalState({
  //     toBeDuplicatedCardItem: null,
  //     toBeDuplicatedWindowsItem: null
  //   })
  // }

  // globalState({
  //   duplicateModalOpened: !duplicateModalOpened,
  // })

  const state = globalState();
  const { summaryCardsInfos, windows } = state;

  const duplicatedCardItem = summaryCardsInfos.find(
    (item) => item.id === id && item.code === code
  );
  const duplicatedWindowsItem = windows.find(
    (item) => item.id === id && item.code === code
  );

  let newSummaryInfos = { ...duplicatedCardItem };
  let newWindowsItem = { ...duplicatedWindowsItem };

  const groups = summaryCardsInfos.map((el) => {
    return el.group;
  });
  const maxGroup = Math.max(...groups);

  const cardCodePrefix = duplicatedCardItem.code.slice(0, -1);
  const cardId = getSelectedOptionId(
    summaryCardsInfos,
    duplicatedCardItem.selectedConfOption
  );

  newSummaryInfos.cardName = duplicatedCardItem.cardName.slice(0, -1) + cardId;
  newSummaryInfos.id = summaryCardsInfos.length + 1;
  newSummaryInfos.code = cardCodePrefix + cardId;
  newSummaryInfos.group = maxGroup + 1;

  newWindowsItem.title = duplicatedCardItem.cardName.slice(0, -1) + cardId;
  newWindowsItem.id = cardId;
  newWindowsItem.code = cardCodePrefix + cardId;
  newWindowsItem.group = maxGroup + 1;

  globalState(
    {
      summaryCardsInfos: [...summaryCardsInfos, newSummaryInfos],
      windows: [...windows, newWindowsItem],
    },
    () => handleProjectTotal(globalState)
  );

  sendAnalytics('duplica', state);
};

const handleSummaryCardModify = (globalState, id, code) => {
  const state = globalState();
  const { summaryCardsInfos } = state;

  const toModifyCardItem = summaryCardsInfos.find(
    (item) => item.id === id && item.code === code
  );

  if (toModifyCardItem.selectedConfOption === 'Zanzariere') {
    const {
      selectedConfOption,
      id,
      code,
      price,
      height,
      width,
      dependsOns,
      selection1,
      selection2,
      selection3,
      selection4,
      selection5,
      selection6,
      selection7,
      selection8,
      selection9,
      accessories,
      colorId,
      colorList,
    } = toModifyCardItem;

    globalState(
      {
        selectedConfOption: selectedConfOption,
        currentStepperId: id,
        currentStepperCode: code,
        currentPrice: price,
        currentStep: 0,
        selections: [],
        configuratorHeight: height,
        configuratorWidth: width,
        selectedDependsOns: dependsOns,
        selectedAccessories: accessories,
        selectedColorId: colorId,
        selected1stStep1stSection: selection1,
        selected2ndStep1stSection: selection2,
        selected2ndStep2ndSection: selection3,
        selected3rdStep1stSection: selection4,
        selected3rdStep2ndSection: selection5,
        selected3rdStep3rdSection: selection6,
        selected4thStep1stSection: selection7,
        selected4thStep2ndSection: selection8,
        selected5thStep1stSection: selection9,
        stepperColorslist: colorList,
      },
      () => {
        getStepsLabels(globalState);
        getStepsInfos(globalState);
      }
    );
  }

  if (toModifyCardItem.selectedConfOption === 'Tapparelle') {
    const {
      selectedConfOption,
      id,
      code,
      price,
      height,
      width,
      dependsOns,
      selection1,
      selection2,
      selection3,
      selection4,
      selection5,
      accessories,
      colorId,
      colorList,
    } = toModifyCardItem;

    globalState(
      {
        selectedConfOption: selectedConfOption,
        currentStepperId: id,
        currentStepperCode: code,
        currentPrice: price,
        currentStep: 0,
        selections: [],
        configuratorHeight: height,
        configuratorWidth: width,
        selectedDependsOns: dependsOns,
        selectedAccessories: accessories,
        selectedColorId: colorId,
        selected1stStep1stSection: selection1,
        selected2ndStep1stSection: selection2,
        selected2ndStep2ndSection: selection3,
        selected3rdStep1stSection: selection4,
        selected3rdStep2ndSection: selection5,
        stepperColorslist: colorList,
      },
      () => {
        getStepsLabels(globalState);
        getStepsInfos(globalState);
      }
    );
  }

  if (toModifyCardItem.selectedConfOption === 'Finestre') {
    const {
      selectedConfOption,
      id,
      code,
      price,
      height,
      width,
      dependsOns,
      selection1,
      selection2,
      selection3,
      selection4,
      selection5,
      selection6,
      selection7,
      selection8,
      selection9,
    } = toModifyCardItem;

    globalState(
      {
        selectedConfOption: selectedConfOption,
        currentStepperId: id,
        currentStepperCode: code,
        currentPrice: price,
        currentStep: 0,
        selections: [],
        configuratorHeight: height,
        configuratorWidth: width,
        selectedDependsOns: dependsOns,
        selected1stStep1stSection: selection1,
        selected2ndStep1stSection: selection2,
        selected2ndStep2ndSection: selection3,
        selected3rdStep1stSection: selection4,
        selected3rdStep2ndSection: selection5,
        selected3rdStep3rdSection: selection6,
        selected4thStep1stSection: selection7,
        selected4thStep2ndSection: selection8,
        selected4thStep3rdSection: selection9,
      },
      () => {
        getStepsLabels(globalState);
        getStepsInfos(globalState);
      }
    );
  }

  sendAnalytics('modifica', state);
};

const changeQuantity = (globalState, type, cardName) => {
  const state = globalState();
  const { windows, summaryCardsInfos } = state;

  let newWindows = [...windows];
  let newSummaryCardsInfos = [...summaryCardsInfos];

  const selectedSummaryCard = newSummaryCardsInfos.find(
    (item) => item.cardName === cardName
  );
  const cardIndex = newSummaryCardsInfos.indexOf(selectedSummaryCard);

  if (type === 'add') {
    newWindows[cardIndex].quantity = newWindows[cardIndex].quantity + 1;
    newSummaryCardsInfos[cardIndex].quantity =
      newSummaryCardsInfos[cardIndex].quantity + 1;
  } else if (type === 'remove') {
    newWindows[cardIndex].quantity = newWindows[cardIndex].quantity - 1;
    newSummaryCardsInfos[cardIndex].quantity =
      newSummaryCardsInfos[cardIndex].quantity - 1;
  }

  globalState(
    {
      summaryCardsInfos: newSummaryCardsInfos,
      windows: newWindows,
    },
    () => handleProjectTotal(globalState)
  );
};

const checkMnetsPrices = (globalState) => {
  const state = globalState();
  const { summaryCardsInfos, windows } = state;

  let newWindows = [...windows];
  let newSummaryCardsInfos = [...summaryCardsInfos];

  let netsQuantity = 0;
  let netsIndexes = [];

  newSummaryCardsInfos
    .filter((el) => el.selectedConfOption === 'Zanzariere')
    .map((el) => {
      netsQuantity = netsQuantity + el.quantity;
      netsIndexes = [...netsIndexes, newSummaryCardsInfos.indexOf(el)];
      return '';
    });

  const netsAdditionalShippingPrice = Number(25.62 / netsQuantity);

  netsIndexes.map((index) => {
    newWindows[index].price =
      netsQuantity < 3
        ? `${
            Number(newSummaryCardsInfos[index].startingPrice) +
            netsAdditionalShippingPrice
          }`
        : newSummaryCardsInfos[index].startingPrice;
    newSummaryCardsInfos[index].price =
      netsQuantity < 3
        ? `${
            Number(newSummaryCardsInfos[index].startingPrice) +
            netsAdditionalShippingPrice
          }`
        : newSummaryCardsInfos[index].startingPrice;
    return '';
  });

  globalState({
    summaryCardsInfos: newSummaryCardsInfos,
    windows: newWindows,
  });
};

const handleProjectTotal = (globalState) => {
  checkMnetsPrices(globalState);

  const state = globalState();
  const { windows } = state;

  let totalPrice = 0;
  let discountedTotalPrice = 0;

  windows.map((el) => {
    const prefix = el.code.slice(0, -2);
    const discount =
      prefix === 'NET' ? 0 : prefix === 'BLI' ? 0 : prefix === 'WIN' ? 50 : 0;
    const discountedPrice = el.price - (el.price / 100) * discount;
    discountedTotalPrice = discountedTotalPrice + discountedPrice * el.quantity;
    totalPrice = totalPrice + el.price * el.quantity;
    return '';
  });

  globalState({ totalPrice, discountedTotalPrice });
};

// Stepper creation functions
const stepsContent = (globalState, currentStep, stepsComponents) => {
  const state = globalState();
  const {
    selectedConfOption,
    stepsInfos,
    confHeightIsValid,
    confWidthIsValid,
    configuratorHeight,
    configuratorWidth,
    selected1stStep1stSection,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
    selected3rdStep1stSection,
    selected3rdStep2ndSection,
    selected3rdStep3rdSection,
    selected4thStep1stSection,
    selected4thStep2ndSection,
    selected4thStep3rdSection,
    selected5thStep1stSection,
    selectedAccessories,
    summaryCardsInfos,
    productModalOpened,
    configuratorsOptions,
    selectedToBeLinkedProduct,
    selectedLinkedProduct,
    appointmentModalOpened,
    appointmentForm,
    isLogged,
    storeDetails,
    selectedNewProduct,
    newProductModalOpened,
    currentGroup,
    isFromProjectCard,
    duplicateModalOpened,
    shareEmailModalOpened,
  } = state;

  if (selectedConfOption === 'Zanzariere') {
    if (currentStep === 1) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[0],
        confHeightIsValid: confHeightIsValid,
        confWidthIsValid: confWidthIsValid,
        configuratorHeight: configuratorHeight,
        configuratorWidth: configuratorWidth,
        handleConfiguratorHeight: handleConfiguratorHeight,
        handleConfiguratorWidth: handleConfiguratorWidth,
        handleSelected1stSection: handleSelected1stStep1stSection,
        selected1stSection: selected1stStep1stSection,
      });
    }
    if (currentStep === 2) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[1],
        handleSelected1stSection: handleSelected2ndStep1stSection,
        handleSelected2ndSection: handleSelected2ndStep2ndSection,
        selected1stSection: selected2ndStep1stSection,
        selected2ndSection: selected2ndStep2ndSection,
      });
    }
    if (currentStep === 3) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[2],
        handleSelected1stSection: handleSelected3rdStep1stSection,
        handleSelected2ndSection: handleSelected3rdStep2ndSection,
        handleSelected3rdSection: handleSelected3rdStep3rdSection,
        selected1stSection: selected3rdStep1stSection,
        selected2ndSection: selected3rdStep2ndSection,
        selected3rdSection: selected3rdStep3rdSection,
      });
    }
    if (currentStep === 4) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[3],
        handleSelected1stSection: handleSelected4thStep1stSection,
        handleSelected2ndSection: handleSelected4thStep2ndSection,
        selected1stSection: selected4thStep1stSection,
        selected2ndSection: selected4thStep2ndSection,
      });
    }
    if (currentStep === 5) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[4],
        handleSelected1stSection: handleSelected5thStep1stSection,
        selected1stSection: selected5thStep1stSection,
      });
    }
    if (currentStep === 6) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[5],
        handleAccessories: handleAccessories,
        selectedAccessories: selectedAccessories,
      });
    }
    if (currentStep === 7) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        summaryCardsInfos: summaryCardsInfos,
        handleNewCard: handleNewSummaryCard,
        handleModify: handleSummaryCardModify,
        handleDuplicate: handleSummaryCardDuplicate,
        changeQuantity: changeQuantity,
        newProductModalOpened: newProductModalOpened,
        handleNewProductModal: handleNewProductModal,
        selectedNewProduct: selectedNewProduct,
        productModalOpened: productModalOpened,
        handleLinkProductModal: handleLinkProductModal,
        configuratorsOptions: configuratorsOptions,
        selectedToBeLinkedProduct: selectedToBeLinkedProduct,
        selectedLinkedProduct: selectedLinkedProduct,
        handleLinkedProduct: handleLinkedProduct,
        appointmentModalOpened: appointmentModalOpened,
        shareEmailModalOpened: shareEmailModalOpened,
        appointmentForm: appointmentForm,
        isLogged: isLogged,
        storeDetails: storeDetails,
        currentGroup: currentGroup,
        isFromProjectCard: isFromProjectCard,
        duplicateModalOpened: duplicateModalOpened,
        handleSummaryCardDuplicateModal: handleSummaryCardDuplicateModal,
      });
    }
  } else if (selectedConfOption === 'Tapparelle') {
    if (currentStep === 1) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[0],
        confHeightIsValid: confHeightIsValid,
        confWidthIsValid: confWidthIsValid,
        configuratorHeight: configuratorHeight,
        configuratorWidth: configuratorWidth,
        handleConfiguratorHeight: handleConfiguratorHeight,
        handleConfiguratorWidth: handleConfiguratorWidth,
        handleSelected1stSection: handleSelected1stStep1stSection,
        selected1stSection: selected1stStep1stSection,
      });
    }
    if (currentStep === 2) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[1],
        handleSelected1stSection: handleSelected2ndStep1stSection,
        handleSelected2ndSection: handleSelected2ndStep2ndSection,
        selected1stSection: selected2ndStep1stSection,
        selected2ndSection: selected2ndStep2ndSection,
      });
    }
    if (currentStep === 3) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[2],
        handleSelected1stSection: handleSelected3rdStep1stSection,
        handleSelected2ndSection: handleSelected3rdStep2ndSection,
        selected1stSection: selected3rdStep1stSection,
        selected2ndSection: selected3rdStep2ndSection,
      });
    }
    if (currentStep === 4) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[3],
        handleAccessories: handleAccessories,
        selectedAccessories: selectedAccessories,
      });
    }
    if (currentStep === 5) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        summaryCardsInfos: summaryCardsInfos,
        handleNewCard: handleNewSummaryCard,
        handleModify: handleSummaryCardModify,
        handleDuplicate: handleSummaryCardDuplicate,
        changeQuantity: changeQuantity,
        newProductModalOpened: newProductModalOpened,
        handleNewProductModal: handleNewProductModal,
        selectedNewProduct: selectedNewProduct,
        productModalOpened: productModalOpened,
        handleLinkProductModal: handleLinkProductModal,
        configuratorsOptions: configuratorsOptions,
        selectedToBeLinkedProduct: selectedToBeLinkedProduct,
        selectedLinkedProduct: selectedLinkedProduct,
        handleLinkedProduct: handleLinkedProduct,
        appointmentModalOpened: appointmentModalOpened,
        shareEmailModalOpened: shareEmailModalOpened,
        appointmentForm: appointmentForm,
        isLogged: isLogged,
        storeDetails: storeDetails,
        currentGroup: currentGroup,
        isFromProjectCard: isFromProjectCard,
        duplicateModalOpened: duplicateModalOpened,
        handleSummaryCardDuplicateModal: handleSummaryCardDuplicateModal,
      });
    }
  } else if (selectedConfOption === 'Finestre') {
    if (currentStep === 1) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[0],
        confHeightIsValid: confHeightIsValid,
        confWidthIsValid: confWidthIsValid,
        configuratorHeight: configuratorHeight,
        configuratorWidth: configuratorWidth,
        handleConfiguratorHeight: handleConfiguratorHeight,
        handleConfiguratorWidth: handleConfiguratorWidth,
        handleSelected1stSection: handleSelected1stStep1stSection,
        selected1stSection: selected1stStep1stSection,
      });
    }
    if (currentStep === 2) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[1],
        handleSelected1stSection: handleSelected2ndStep1stSection,
        handleSelected2ndSection: handleSelected2ndStep2ndSection,
        selected1stSection: selected2ndStep1stSection,
        selected2ndSection: selected2ndStep2ndSection,
      });
    }
    if (currentStep === 3) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[2],
        handleSelected1stSection: handleSelected3rdStep1stSection,
        handleSelected2ndSection: handleSelected3rdStep2ndSection,
        handleSelected3rdSection: handleSelected3rdStep3rdSection,
        selected1stSection: selected3rdStep1stSection,
        selected2ndSection: selected3rdStep2ndSection,
        selected3rdSection: selected3rdStep3rdSection,
      });
    }
    if (currentStep === 4) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[3],
        handleSelected1stSection: handleSelected4thStep1stSection,
        handleSelected2ndSection: handleSelected4thStep2ndSection,
        handleSelected3rdSection: handleSelected4thStep3rdSection,
        selected1stSection: selected4thStep1stSection,
        selected2ndSection: selected4thStep2ndSection,
        selected3rdSection: selected4thStep3rdSection,
      });
    }
    if (currentStep === 5) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        stepInfos: stepsInfos[4],
        handleSelected1stSection: handleSelected5thStep1stSection,
        selected1stSection: selected5thStep1stSection,
      });
    }
    if (currentStep === 6) {
      return React.createElement(stepsComponents[currentStep - 1], {
        globalState: globalState,
        summaryCardsInfos: summaryCardsInfos,
        handleNewCard: handleNewSummaryCard,
        handleModify: handleSummaryCardModify,
        handleDuplicate: handleSummaryCardDuplicate,
        changeQuantity: changeQuantity,
        newProductModalOpened: newProductModalOpened,
        handleNewProductModal: handleNewProductModal,
        selectedNewProduct: selectedNewProduct,
        productModalOpened: productModalOpened,
        handleLinkProductModal: handleLinkProductModal,
        configuratorsOptions: configuratorsOptions,
        selectedToBeLinkedProduct: selectedToBeLinkedProduct,
        selectedLinkedProduct: selectedLinkedProduct,
        handleLinkedProduct: handleLinkedProduct,
        appointmentModalOpened: appointmentModalOpened,
        shareEmailModalOpened: shareEmailModalOpened,
        appointmentForm: appointmentForm,
        isLogged: isLogged,
        storeDetails: storeDetails,
        currentGroup: currentGroup,
        isFromProjectCard: isFromProjectCard,
        duplicateModalOpened: duplicateModalOpened,
        handleSummaryCardDuplicateModal: handleSummaryCardDuplicateModal,
      });
    }
  }
};

const stepsCreate = (globalState, stepsComponents) => {
  const state = globalState();
  const {
    selectedConfOption,
    stepsLabels,
    stepperCanProceedMnets,
    stepperCanProceedBlinds,
    stepperCanProceedWindows,
    isFromProjectCard,
  } = state;

  const steps = stepsLabels.map((el, i) => {
    const saveButton = i18next.t('windows.STEPPER_MAIN_BUTTONS.saveButton');
    const backButton = i18next.t('windows.STEPPER_MAIN_BUTTONS.backButton');
    const requestAppointment = i18next.t(
      'windows.STEPPER_MAIN_BUTTONS.requestAppointment'
    );
    const continueButton = i18next.t('windows.STEPPER_MAIN_BUTTONS.continue');

    return {
      label: i18next.t(el),
      content: stepsContent(globalState, i + 1, stepsComponents),
      actions: [
        {
          disabled: isFromProjectCard,
          label:
            i === stepsLabels.length - 1
              ? i18next.t('windows.STEPPER_MAIN_BUTTONS.saveButton')
              : i18next.t('windows.STEPPER_MAIN_BUTTONS.backButton'),
          type: 'back',
          flavour: i === stepsLabels.length - 1 ? 'primary' : 'secondary-green',
          onClick:
            i === 0
              ? () => handleReset(globalState)
              : i === stepsLabels.length - 1
              ? () => showPDFfromSummary(globalState)
              : undefined,
        },
        {
          // i === stepsLabels.length - 1 ? false
          disabled: isFromProjectCard
            ? true
            : selectedConfOption === 'Zanzariere'
            ? !stepperCanProceedMnets[i]
            : selectedConfOption === 'Tapparelle'
            ? !stepperCanProceedBlinds[i]
            : selectedConfOption === 'Finestre'
            ? !stepperCanProceedWindows[i]
            : [],
          label:
            i === stepsLabels.length - 1
              ? i18next.t('windows.STEPPER_MAIN_BUTTONS.requestAppointment')
              : i18next.t('windows.STEPPER_MAIN_BUTTONS.continue'),
          type: 'next',
          flavour: 'primary',
          onClick:
            i === stepsLabels.length - 2
              ? () => createSummaryCardsInfos(globalState)
              : i === stepsLabels.length - 1
              ? () => handleAppointmentRequestModal(globalState)
              : undefined,
        },
        // Share email
        {
          disabled: isFromProjectCard,
          label:
            i === stepsLabels.length - 1
              ? 'Udostępnij projekt'
              : 'Ukończ projekt do udostępnienia',
          type: i === stepsLabels.length - 1 ? 'next' : '',
          flavour: i === stepsLabels.length - 1 ? 'primary' : 'secondary-green',
          onClick:
            i === 0
              ? () => handleReset(globalState)
              : i === stepsLabels.length - 1
              ? () => handleShareRequestModal(globalState)
              : undefined,
        },
      ],
    };
  });

  return steps;
};

// Stepper calls
const getStepsLabels = async (globalState) => {
  const state = globalState();
  const { selectedConfOption } = state;

  await axios
    .get(
      `${STEPPER_STEPS_API}${
        selectedConfOption === 'Zanzariere'
          ? '/mosquitonets'
          : selectedConfOption === 'Tapparelle'
          ? '/blinds'
          : selectedConfOption === 'Finestre'
          ? '/windows'
          : ''
      }`
    )
    .then((res) => {
      globalState({
        stepsCode: res.data.data ? res.data.data.map((el) => el.id) : [],
      });
      if (selectedConfOption === 'Zanzariere') {
        globalState({
          stepsLabels: res.data.data ? res.data.data.map((el) => el.title) : [],
        });
      } else if (selectedConfOption === 'Tapparelle') {
        globalState({
          stepsLabels: res.data.data ? res.data.data.map((el) => el.title) : [],
        });
      } else if (selectedConfOption === 'Finestre') {
        globalState({
          stepsLabels: res.data.data ? res.data.data.map((el) => el.title) : [],
        });
      }
    })
    .catch((err) => {
      console.log(err);
      globalState({
        modalOpened: true,
        modalMessage: {
          title: 'Attenzione!',
          text: 'Si è verificato un errore',
        },
      });
    });
};

const getStepsInfos = async (globalState) => {
  const state = globalState();
  const {
    windows,
    selections,
    stepsInfos,
    selectedDependsOns,
    currentStepperId,
    currentStepperCode,
    currentStep,
    selectedConfOption,
    configuratorHeight,
    configuratorWidth,
    selected1stStep1stSection,
    selected2ndStep1stSection,
    selected2ndStep2ndSection,
    selected3rdStep1stSection,
    selected3rdStep2ndSection,
    selected3rdStep3rdSection,
    selected4thStep1stSection,
    selected4thStep2ndSection,
    selected4thStep3rdSection,
    selected5thStep1stSection,
    selectedColorId,
    currentGroup,
  } = state;

  globalState({ isLoading: true });
  const newCurrentStep = currentStep + 1;

  // console.log("getStepsInfos", state)

  sendPageViewAnalytics(state);

  await axios
    .post(
      `${STEPS_INFOS_API}${
        selectedConfOption === 'Zanzariere'
          ? '/mosquitonets'
          : selectedConfOption === 'Tapparelle'
          ? '/blinds'
          : selectedConfOption === 'Finestre'
          ? '/windows'
          : ''
      }`,
      {
        currentStep: newCurrentStep,
        selections: selections,
      }
    )
    .then((res) => {
      const data = res.data.data;
      let newStepsInfos = { ...stepsInfos };
      let newSelectedDependsOns = { ...selectedDependsOns };

      if (selectedConfOption === 'Zanzariere') {
        if (newCurrentStep === 1) {
          // creates the expected object for the 1st step component
          const step1infos = stepInfosMnetsItem(
            data[0].title,
            data[0].items,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[0] = step1infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step1selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...step1selections],
            step1selections,
          });

          // create the stepper id and code
          if (!currentStepperId && !currentStepperCode) {
            globalState({
              currentStepperId: windows.length + 1,
              currentStepperCode: 'NET-' + (windows.length + 1),
            });
          }

          // for modify summary card
          if (selected1stStep1stSection && windows.length >= 1) {
            handleSelected1stStep1stSection(
              globalState,
              selected1stStep1stSection,
              selectedDependsOns[0]
            );
            handleConfiguratorHeight(
              globalState,
              configuratorHeight,
              0,
              100000
            );
            handleConfiguratorWidth(globalState, configuratorWidth, 0, 100000);
          }
        }
        if (newCurrentStep === 2) {
          // filter the 1st section based on the previous dependsOn
          const section1itemsFiltered = sectionFiltered(
            data[0],
            selectedDependsOns[0]
          );

          // creates the expected object for the 2nd step component
          // the options2ndSection will be added on the 1st section selection
          const step2infos = stepInfosMnetsItem(
            data[0].title,
            section1itemsFiltered,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[1] = step2infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step2selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...state.step1selections, ...step2selections],
            step2selections,
          });

          // for modify summary card
          if (selected2ndStep1stSection && windows.length >= 1) {
            handleSelected2ndStep1stSection(
              globalState,
              selected2ndStep1stSection,
              selectedDependsOns[2]
            );
            handleSelected2ndStep2ndSection(
              globalState,
              selected2ndStep2ndSection,
              selectedDependsOns[3]
            );
          }
        }
        if (newCurrentStep === 3) {
          // filter the 1st section based on the previous dependsOn
          const section1itemsFiltered = sectionFiltered(
            data[0],
            selectedDependsOns[3]
          );

          // creates the expected object for the 3rd step component
          // the options2ndSection will be added on the 1st section selection
          // the options3rdSection will be added on the 2nd section selection
          const step3infos = stepInfosMnetsItem(
            data[0].title,
            section1itemsFiltered,
            data[1].title,
            [],
            data[2].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[2] = step3infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step3selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...step3selections,
            ],
            step3selections,
          });

          // for modify summary card
          if (selected3rdStep1stSection && windows.length >= 1) {
            handleSelected3rdStep1stSection(
              globalState,
              selected3rdStep1stSection,
              selectedDependsOns[4]
            );
            handleSelected3rdStep2ndSection(
              globalState,
              selected3rdStep2ndSection,
              selectedDependsOns[5]
            );
            handleSelected3rdStep3rdSection(
              globalState,
              selected3rdStep3rdSection,
              selectedDependsOns[6]
            );
          }
        }
        if (newCurrentStep === 4) {
          // filter the 1st section based on the previous dependsOn
          const section1itemsFiltered = sectionFiltered(
            data[0],
            selectedDependsOns[6]
          );

          // creates the expected object for the 4th step component
          // the options2ndSection will be added on the 1st section selection
          const step4infos = stepInfosMnetsItem(
            'Scegli la tipologia di colore',
            section1itemsFiltered,
            data[0].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[3] = step4infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          // in section.items you need to show only the 1st section filtered items
          const step4selections = stepSelectionsItem(data);
          step4selections[0].section.items = section1itemsFiltered;

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...state.step3selections,
              ...step4selections,
            ],
            step4selections,
          });

          // for modify summary card
          if (selected4thStep1stSection && windows.length >= 1) {
            handleSelected4thStep1stSection(
              globalState,
              selected4thStep1stSection,
              selectedDependsOns[7]
            );
            handleSelected4thStep2ndSection(
              globalState,
              selected4thStep2ndSection,
              selectedColorId
            );
          }
        }
        if (newCurrentStep === 5) {
          // filter the 1st section based on the previous dependsOn
          const section1itemsFiltered = sectionFiltered(
            data[0],
            selectedDependsOns[7]
          );

          // creates the expected object for the 5th step component
          const step5infos = stepInfosMnetsItem(
            data[0].title,
            section1itemsFiltered
          );

          // add the object in the stepsInfos state
          newStepsInfos[4] = step5infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          // in section.items you need to show only the 1st section filtered items
          const step5selections = stepSelectionsItem(data);
          step5selections[0].section.items = section1itemsFiltered;

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...state.step3selections,
              ...state.step4selections,
              ...step5selections,
            ],
            step5selections,
          });

          // for modify summary card
          if (selected5thStep1stSection && windows.length >= 1) {
            handleSelected5thStep1stSection(
              globalState,
              selected5thStep1stSection,
              selectedDependsOns[8]
            );
          }
        }
        if (newCurrentStep === 6) {
          // creates the expected object for the 6th step component
          const step6infos = stepInfosAccessories(data[0].title, data[0].items);

          // add the object in the stepsInfos state
          newStepsInfos[5] = step6infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step6selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...state.step3selections,
              ...state.step4selections,
              ...state.step5selections,
              ...step6selections,
            ],
            step6selections,
          });
        }
        if (newCurrentStep === 7) {
        }
      }
      if (selectedConfOption === 'Tapparelle') {
        if (newCurrentStep === 1) {
          // creates the expected object for the 1st step component
          const step1infos = stepInfosBlindsItem(
            data[0].title,
            data[0].items,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[0] = step1infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step1selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...step1selections],
            step1selections,
          });

          // create the stepper id and code
          if (!currentStepperId && !currentStepperCode) {
            globalState({
              currentStepperId: windows.length + 1,
              currentStepperCode: 'BLI-' + (windows.length + 1),
            });
          }

          // for modify summary card
          if (selected1stStep1stSection && windows.length >= 1) {
            handleSelected1stStep1stSection(
              globalState,
              selected1stStep1stSection,
              selectedDependsOns[0]
            );
            handleConfiguratorHeight(
              globalState,
              configuratorHeight,
              0,
              100000
            );
            handleConfiguratorWidth(globalState, configuratorWidth, 0, 100000);
          }
        }
        if (newCurrentStep === 2) {
          // filter the 1st section based on the previous dependsOn
          const section1itemsFiltered = sectionFiltered(
            data[0],
            selectedDependsOns[0]
          );

          // creates the expected object for the 2nd step component
          // the options2ndSection will be added on the 1st section selection
          const step2infos = stepInfosBlindsItem(
            data[0].title,
            section1itemsFiltered,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[1] = step2infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step2selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...state.step1selections, ...step2selections],
            step2selections,
          });

          // for modify summary card
          if (selected2ndStep1stSection && windows.length >= 1) {
            handleSelected2ndStep1stSection(
              globalState,
              selected2ndStep1stSection,
              selectedDependsOns[2]
            );
            handleSelected2ndStep2ndSection(
              globalState,
              selected2ndStep2ndSection,
              selectedDependsOns[3]
            );
          }
        }
        if (newCurrentStep === 3) {
          // step infos created in handleSelected2ndStep2ndSection
          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step3selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...step3selections,
            ],
            step3selections,
          });

          // for modify summary card
          if (selected3rdStep1stSection && windows.length >= 1) {
            handleSelected3rdStep1stSection(
              globalState,
              selected3rdStep1stSection,
              selectedDependsOns[4]
            );
            handleSelected3rdStep2ndSection(
              globalState,
              selected3rdStep2ndSection,
              selectedDependsOns[5]
            );
          }
        }
        if (newCurrentStep === 4) {
          // creates the expected object for the 4th step component
          const filteredAccessories = blindsAccessoriesFiltered(
            data[0].items,
            selected2ndStep2ndSection
          );
          const step4infos = stepInfosBlindsAccessories(
            data[0].title,
            filteredAccessories
          );

          // add the object in the stepsInfos state
          newStepsInfos[3] = step4infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step4selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...state.step3selections,
              ...step4selections,
            ],
            step4selections,
          });
        }
        if (newCurrentStep === 5) {
        }
      }
      if (selectedConfOption === 'Finestre') {
        if (newCurrentStep === 1) {
          // creates the expected object for the 1st step component
          const step1infos = stepInfosWindowsItem(
            data[0].title,
            data[0].items,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[0] = step1infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step1selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...step1selections],
            step1selections,
          });

          // create the stepper id and code
          if (!currentStepperId && !currentStepperCode) {
            globalState({
              currentStepperId: windows.length + 1,
              currentStepperCode: 'WIN-' + (windows.length + 1),
            });
          }

          // for modify summary card
          if (selected1stStep1stSection && windows.length >= 1) {
            handleSelected1stStep1stSection(
              globalState,
              selected1stStep1stSection,
              selectedDependsOns[0]
            );
            handleConfiguratorHeight(
              globalState,
              configuratorHeight,
              0,
              100000
            );
            handleConfiguratorWidth(globalState, configuratorWidth, 0, 100000);
          }
        }
        if (newCurrentStep === 2) {
          // creates the expected object for the 2nd step component
          // the options2ndSection will be added on the 1st section selection
          const step2infos = stepInfosWindowsItem(
            data[0].title,
            data[0].items,
            data[1].title
          );

          // add the object in the stepsInfos state
          newStepsInfos[1] = step2infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step2selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [...state.step1selections, ...step2selections],
            step2selections,
          });

          // for modify summary card
          if (selected2ndStep1stSection && windows.length >= 1) {
            handleSelected2ndStep1stSection(
              globalState,
              selected2ndStep1stSection,
              selectedDependsOns[2]
            );
            handleSelected2ndStep2ndSection(
              globalState,
              selected2ndStep2ndSection,
              selectedDependsOns[3]
            );
          }
        }
        if (newCurrentStep === 3) {
          // creates the expected object for the 3rd step component
          const step3infos = stepInfosWindowsItem(data[0].title, data[0].items);

          // add the object in the stepsInfos state
          newStepsInfos[2] = step3infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step3selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState(
            {
              selections: [
                ...state.step1selections,
                ...state.step2selections,
                ...step3selections,
              ],
              step3selections,
            },
            () => {
              // preselect first section item only if items array has length 1
              if (step3selections[0].section.items.length <= 1) {
                const materialItem = step3selections[0].section.items[0];
                handleSelected3rdStep1stSection(
                  globalState,
                  i18next.t(materialItem.title),
                  materialItem.id
                );
              }
            }
          );

          // for modify summary card
          if (selected3rdStep1stSection && windows.length >= 1) {
            handleSelected3rdStep1stSection(
              globalState,
              selected3rdStep1stSection,
              selectedDependsOns[4]
            );
            handleSelected3rdStep2ndSection(
              globalState,
              selected3rdStep2ndSection
            );
            handleSelected3rdStep3rdSection(
              globalState,
              selected3rdStep3rdSection
            );
          }
        }
        if (newCurrentStep === 4) {
          // filter all sections items
          const section1selectedItems =
            data[0].items[0].meta['@selected'].values;
          const section3selectedItems =
            data[0].items[2].meta['@selected'].values;

          const section1selectedItemsFiltered = windowsFilterSelectedById(
            section1selectedItems,
            newSelectedDependsOns[4]
          );
          const section3selectedItemsFiltered = windowsFilterSelectedById(
            section3selectedItems,
            newSelectedDependsOns[4]
          );

          const section1selectedItemsFilteredList = windowsFilteredSelectedList(
            section1selectedItemsFiltered
          );
          const section3selectedItemsFilteredList = windowsFilteredSelectedList(
            section3selectedItemsFiltered
          );

          const section1dataItems = data[0].items[0].meta.data.values;
          const section2dataItems = data[0].items[1].meta.data.values;
          const section3dataItems = data[0].items[2].meta.data.values;

          const section1items = windowsFilterDataFromList(
            section1selectedItemsFilteredList,
            section1dataItems
          );
          const section2items = section2dataItems;
          const section3items = windowsFilterDataFromList(
            section3selectedItemsFilteredList,
            section3dataItems
          );

          // creates the expected object for the 4th step component
          const step4infos = stepInfosWindowsItem(
            data[0].items[0].title,
            [],
            data[0].items[1].title,
            [],
            data[0].items[2].title,
            []
          );

          // add step options
          step4infos.options1stSection = stepInfosItemWindowsFeaturesOptions(
            section1items
          );
          step4infos.options2ndSection = stepInfosItemWindowsFeaturesOptions(
            section2items,
            true
          );
          step4infos.options3rdSection = stepInfosItemWindowsFeaturesOptions(
            section3items,
            true
          );

          // add the object in the stepsInfos state
          newStepsInfos[3] = step4infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step4selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState(
            {
              selections: [
                ...state.step1selections,
                ...state.step2selections,
                ...state.step3selections,
                ...step4selections,
              ],
              step4selections,
            },
            () => {
              // preselect first section item only if items array has length 1
              debugger;
              if (step4selections[0].section.items[0].meta['@selected'].values.length <= 3) {
                  handleSelected4thStep1stSection(
                  globalState,
                  "PREMIUM 3 szyby",
                  undefined
                  ); 
              }
            }
          );

          // for modify summary card
          if (selected4thStep1stSection && windows.length >= 1) {
            handleSelected4thStep1stSection(
              globalState,
              selected4thStep1stSection,
              selectedDependsOns[3]
            );
            handleSelected4thStep2ndSection(
              globalState,
              selected4thStep2ndSection
            );
            handleSelected4thStep3rdSection(
              globalState,
              selected4thStep3rdSection
            );
          }
        }
        if (newCurrentStep === 5) {
          console.log('newCurrentStep...');
          // filter all sections items
          const section1selectedItems =
            data[0].items[0].meta['@selected'].values;
          const section1selectedItemsFiltered = windowsFilterSelectedById(
            section1selectedItems,
            newSelectedDependsOns[4]
          );
          const section1selectedItemsFilteredList = windowsFilteredSelectedList(
            section1selectedItemsFiltered
          );
          const section1dataItems = data[0].items[0].meta.data.values;
          const section1itemsFromList = windowsFilterDataFromList(
            section1selectedItemsFilteredList,
            section1dataItems
          );
          const section1items = section1itemsFromList.filter((el) =>
            el.dependsOn.APERTURA.includes(selectedDependsOns[3])
          );

          /*  // creates the expected object for the 5th step component
          let step5infos = stepInfosWindowsItem(data[0].items[0].title, []);

          // add step options
          step5infos.options1stSection = stepInfosItemWindowsAccessoriesOptions(
            section1items
          );
 */
          let step5infos = undefined;
          if (data[0].items.length > 1) {
            step5infos = stepInfosWindowsItem(
              data[0].items[0].title,
              [],
              data[0].items[1].a,
              [],
              data[0].items[2].b,
              [],
              data[0].items[3].c,
              []
            );

            // add step options
            step5infos.options1stSection = stepInfosItemWindowsAccessoriesOptions(
              section1items
            );

            //2nd
            const section2selectedItems =
              data[0].items[1].meta['@selected'].values;
            const section2selectedItemsFiltered = windowsFilterSelectedById(
              section2selectedItems,
              newSelectedDependsOns[4]
            );
            const section2selectedItemsFilteredList = windowsFilteredSelectedList(
              section2selectedItemsFiltered
            );
            const section2dataItems = data[0].items[1].meta.data.values;
            const section2itemsFromList = windowsFilterDataFromList(
              section2selectedItemsFilteredList,
              section2dataItems
            );
            const section2items = section2itemsFromList.filter((el) =>
              el.dependsOn.APERTURA.includes(selectedDependsOns[3])
            );

            step5infos.options2ndSection = stepInfosItemWindowsAccessoriesOptions(
              section2items
            );

            //3th
            const section3selectedItems =
              data[0].items[2].meta['@selected'].values;
            const section3selectedItemsFiltered = windowsFilterSelectedById(
              section3selectedItems,
              newSelectedDependsOns[4]
            );
            const section3selectedItemsFilteredList = windowsFilteredSelectedList(
              section3selectedItemsFiltered
            );
            const section3dataItems = data[0].items[2].meta.data.values;
            const section3itemsFromList = windowsFilterDataFromList(
              section3selectedItemsFilteredList,
              section3dataItems
            );
            const section3items = section3itemsFromList.filter((el) =>
              el.dependsOn.APERTURA.includes(selectedDependsOns[3])
            );

            step5infos.options3thSection = stepInfosItemWindowsAccessoriesOptions(
              section3items
            );

            //4th
            const section4selectedItems =
              data[0].items[3].meta['@selected'].values;
            const section4selectedItemsFiltered = windowsFilterSelectedById(
              section4selectedItems,
              newSelectedDependsOns[4]
            );
            const section4selectedItemsFilteredList = windowsFilteredSelectedList(
              section4selectedItemsFiltered
            );
            const section4dataItems = data[0].items[2].meta.data.values;
            const section4itemsFromList = windowsFilterDataFromList(
              section4selectedItemsFilteredList,
              section4dataItems
            );
            const section4items = section4itemsFromList.filter((el) =>
              el.dependsOn.APERTURA.includes(selectedDependsOns[3])
            );

            step5infos.options4thSection = stepInfosItemWindowsAccessoriesOptions(
              section4items
            );
          }

          // add the object in the stepsInfos state
          newStepsInfos[4] = step5infos;
          globalState({ stepsInfos: newStepsInfos });

          // creates the expected object for the selection state
          // values is empty and will be updated on items selecions
          const step5selections = stepSelectionsItem(data);

          // create the object for the selections state
          // save the current step selections object
          globalState({
            selections: [
              ...state.step1selections,
              ...state.step2selections,
              ...state.step3selections,
              ...state.step4selections,
              ...step5selections,
            ],
            step5selections,
          });

          // for modify summary card
          if (selected5thStep1stSection && windows.length >= 1) {
            handleSelected5thStep1stSection(
              globalState,
              selected5thStep1stSection
            );
          }
        }
      }

      globalState(
        {
          currentStep: newCurrentStep,
          isLoading: false,
        },
        () => {
          // for linked card
          if (windows.length >= 1 && currentStep === 0 && currentGroup) {
            getStepsInfos(globalState);
          }
        }
      );
      window.scrollTo(0, 0);
    })
    .catch((err) => {
      console.log(err);
      globalState({ currentStep: currentStep, isLoading: false });
      globalState({
        modalOpened: true,
        modalMessage: {
          title: 'Attenzione!',
          text: 'Si è verificato un errore',
        },
      });
    });

  // globalState({
  //   currentStep: newCurrentStep,
  //   isLoading: false
  // }, () => {
  //   //for linked card
  //   if(windows.length >= 1 && currentStep === 0 && currentGroup) {
  //     getStepsInfos(globalState)
  //   }
  // });
  // window.scrollTo(0, 0);
};

// Stepper reset
const stepsResetOnBackButton = (globalState, currentBackwardStep) => {
  const state = globalState();
  const {
    selected2ndStep1stSection,
    stepsInfos,

    currentStep,
    selectedConfOption,
    selectedPrices,

    step1selections,
    step2selections,
    step3selections,
    step4selections,
    step5selections,
  } = state;

  const newCurrentStep = currentBackwardStep
    ? currentBackwardStep
    : currentStep - 1;

  if (selectedConfOption === 'Zanzariere') {
    if (newCurrentStep === 1) {
      globalState({
        selections: [...step1selections],
        selected2ndStep1stSection: null,
        selected2ndStep2ndSection: null,
        currentPrice: 0,
        stepperCanProceedMnets: [true, false, false, false, false, true, true],
      });
    }
    if (newCurrentStep === 2) {
      globalState({
        selections: [...step1selections, ...step2selections],
        selected3rdStep1stSection: null,
        selected3rdStep2ndSection: null,
        selected3rdStep3rdSection: null,
        currentPrice: selectedPrices[3],
        stepperCanProceedMnets: [true, true, false, false, false, true, true],
      });
    }
    if (newCurrentStep === 3) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
        ],
        selected4thStep1stSection: null,
        selected4thStep2ndSection: null,
        currentPrice: selectedPrices[6],
        stepperCanProceedMnets: [true, true, true, false, false, true, true],
      });
    }
    if (newCurrentStep === 4) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
          ...step4selections,
        ],
        selected5thStep1stSection: null,
        currentPrice: selectedPrices[7],
        stepperCanProceedMnets: [true, true, true, true, false, true, true],
      });
    }
    if (newCurrentStep === 5) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
          ...step4selections,
          ...step5selections,
        ],
        selectedAccessories: [],
        selectedAccessoriesItems: [],
        selectedAccessoriesPrices: [],
        currentPrice: selectedPrices[8],
        stepperCanProceedMnets: [true, true, true, true, true, true, true],
      });
    }
  }

  if (selectedConfOption === 'Tapparelle') {
    if (newCurrentStep === 1) {
      globalState({
        selections: [...step1selections],
        selected2ndStep1stSection: null,
        selected2ndStep2ndSection: null,
        currentPrice: 0,
        stepperCanProceedBlinds: [true, false, false, true, true],
      });
    }
    if (newCurrentStep === 2) {
      globalState({
        selections: [...step1selections, ...step2selections],
        selected3rdStep1stSection: null,
        selected3rdStep2ndSection: null,
        selected3rdStep3rdSection: null,
        currentPrice: selectedPrices[3],
        stepperCanProceedBlinds: [true, true, false, true, true],
      });
    }
    if (newCurrentStep === 3) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
        ],
        selectedAccessories: [],
        selectedAccessoriesItems: [],
        selectedAccessoriesPrices: [],
        currentPrice: selectedPrices[6] || selectedPrices[5],
        stepperCanProceedBlinds: [true, true, true, true, true],
      });
    }
  }

  if (selectedConfOption === 'Finestre') {
    if (newCurrentStep === 1) {
      globalState({
        selections: [...step1selections],
        selected2ndStep1stSection: null,
        selected2ndStep2ndSection: null,
        currentPrice: 0,
        stepperCanProceedWindows: [true, false, false, false, false, true],
      });
    }
    if (newCurrentStep === 2) {
      // get item preview to reset colors
      const previousItem = stepsInfos[1].options1stSection.find(
        (el) => el.item.label === selected2ndStep1stSection
      )['item'];
      const itemImage = require(`../../assets/images/configurator/types/${previousItem.imgPath.replace(
        '/images/',
        ''
      )}`);

      globalState({
        selections: [...step1selections, ...step2selections],
        selected3rdStep1stSection: null,
        selected3rdStep2ndSection: null,
        selected3rdStep3rdSection: null,
        currentPrice: selectedPrices[3],
        stepperCanProceedWindows: [true, true, false, false, false, true],
        currentPreviewWindowsFront: itemImage,
        currentPreviewWindowsBack: itemImage,
      });
    }
    if (newCurrentStep === 3) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
        ],
        selected4thStep1stSection: null,
        selected4thStep2ndSection: null,
        selected4thStep3rdSection: null,
        currentPrice: selectedPrices[53],
        stepperCanProceedWindows: [true, true, true, false, false, true],
      });
    }
    if (newCurrentStep === 4) {
      globalState({
        selections: [
          ...step1selections,
          ...step2selections,
          ...step3selections,
          ...step4selections,
        ],
        selected5thStep1stSection: null,
        currentPrice: selectedPrices[7],
        stepperCanProceedWindows: [true, true, true, true, false, true],
      });
    }
  }

  globalState({ currentStep: newCurrentStep });
  window.scrollTo(0, 0);
};

const handleStepNavigation = (globalState, selectedStep) => {
  const { currentStep } = globalState();

  for (let i = currentStep - 1; i >= selectedStep; i--) {
    stepsResetOnBackButton(globalState, i);
  }
};

// Detect
const detectEnviroment = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'dev';
    case 'test':
      return 'qa';
    case 'prod':
      return 'prod';
    default:
      return 'dev';
  }
};

const detectDevice = () => {
  if (isMobileOnly) {
    return 'smartphone';
  } else if (isTablet) {
    return 'tablet';
  } else {
    return 'large';
  }
};

export {
  stepsCreate,
  getStepsLabels,
  getStepsInfos,
  stepsResetOnBackButton,
  detectEnviroment,
  detectDevice,
  sendAnalytics,
  handleStepNavigation,
};
