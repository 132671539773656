"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extensions = require("./extensions");

var _ramda = require("ramda");

function selectValidator(value) {
  return {
    valid: value !== '',
    data: 'Devi selezionare un\'opzione '
  };
}

function requiredValidator(value) {
  if (typeof value === 'string') {
    return {
      valid: value.trim() !== '',
      data: 'Campo obbligatorio'
    };
  } else {
    return {
      valid: !(0, _ramda.isNil)(value) && !(0, _ramda.isEmpty)(value),
      data: 'Campo obbligatorio'
    };
  }
}

function nameValidator(value) {
  return {
    valid: (0, _extensions.personName)(value),
    data: 'Nome non valido'
  };
}

function companyNameValidator(value) {
  return {
    valid: (0, _extensions.companyName)(value),
    data: 'Ragione sociale non valida'
  };
}

function emailValidator(value) {
  // eslint-disable-next-line
  var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return {
    valid: regex.test(value),
    data: 'non completo'
  };
}

var lengthValidator = function lengthValidator(value, length) {
  return {
    valid: value.length === length,
    data: 'è troppo breve'
  };
};

var bypassValidator = function bypassValidator(value) {
  return {
    valid: true
  };
};

var numberValidator = function numberValidator(value) {
  return {
    valid: (0, _extensions.isNumber)(value),
    data: 'Inserisci solo numeri'
  };
};

var alphanumericValidator = function alphanumericValidator(value) {
  return {
    valid: (0, _extensions.isAlphanumeric)(value),
    data: 'deve avere solo caratteri alfanumerici'
  };
};

var alphanumericWithSpacesValidator = function alphanumericWithSpacesValidator(value) {
  return {
    valid: (0, _extensions.isAlphanumericWithSpaces)(value),
    data: 'deve avere solo caratteri alfanumerici o spazi'
  };
};

var nicknameValidator = function nicknameValidator(value) {
  var reg = /[|<> °;:@#§*!'?£$%=^()[\]\\\\/"]/;
  var valid = reg.test(value);
  return {
    valid: valid,
    data: 'Nickname validator error'
  };
};

var notEmptyValidator = function notEmptyValidator(value) {
  return {
    valid: value !== '',
    data: 'Deve contenere almeno un carattere'
  };
};

function taxCodeValidator(value) {
  var cf = value.toUpperCase();
  var lengthCheck = lengthValidator(cf, 16);

  if (!lengthCheck.valid) {
    return lengthCheck;
  }

  var alphanumericCheck = alphanumericValidator(value);

  if (!alphanumericCheck.valid) {
    return alphanumericCheck;
  }

  var map = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
  var string = 0;

  for (var i = 0; i < 15; i++) {
    var char = cf.charCodeAt(i);

    if (char < 65) {
      char = char - 48;
    } else {
      char = char - 55;
    }

    if (i % 2 === 0) {
      string += map[char];
    } else {
      string += char < 10 ? char : char - 10;
    }
  }

  var expected = String.fromCharCode(65 + string % 26);
  return {
    valid: expected === cf.charAt(15),
    data: 'non è corretto'
  };
}

function disallowSpecialCharsValidator(value) {
  return {
    valid: (0, _extensions.disallowSpecialChars)(value),
    data: 'Caratteri non validi'
  };
}

var _default = {
  lengthValidator: lengthValidator,
  numberValidator: numberValidator,
  bypassValidator: bypassValidator,
  alphanumericValidator: alphanumericValidator,
  nicknameValidator: nicknameValidator,
  selectValidator: selectValidator,
  notEmptyValidator: notEmptyValidator,
  requiredValidator: requiredValidator,
  nameValidator: nameValidator,
  companyNameValidator: companyNameValidator,
  emailValidator: emailValidator,
  alphanumericWithSpacesValidator: alphanumericWithSpacesValidator,
  taxCodeValidator: taxCodeValidator,
  disallowSpecialCharsValidator: disallowSpecialCharsValidator
};
exports.default = _default;