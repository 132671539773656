import React from 'react';
import style from './style.module.scss';
import i18next from '../../utils/i18n';

import { LmButton } from '@lm/lm-design-system/dist/components';
import { Dialog, Grid } from '@material-ui/core';
import LmConfOptionCard from '../LmConfOptionCard';

export default class LmStepperNewProductModal extends React.Component {
  render() {
    const {
      globalState,
      newProductModalOpened,
      handleNewProductModal,
      configuratorsOptions,
      selectedNewProduct,
      handleNewCard,
    } = this.props;

    return (
      <Dialog
        open={newProductModalOpened}
        onClose={() => handleNewProductModal(globalState)}
        maxWidth="xl"
      >
        <div className={style.newProductModalContainer}>
          <div className={style.title}>
            {/* Scegli il nuovo infisso */}
            {i18next.t('windows.LM_STEPPER_NEW_PRODUCT_MODAL.selectNew')}
          </div>

          <Grid container justify={'center'} spacing={2}>
            {configuratorsOptions.map((el, i) => {
              return (
                <Grid item xs={12} md={4} key={`new-config-option-${i}`}>
                  <LmConfOptionCard
                    el={el}
                    i={i}
                    handleSelectedConfOptions={(label) =>
                      globalState({ selectedNewProduct: label })
                    }
                    selectedConfOption={selectedNewProduct}
                  />
                </Grid>
              );
            })}
          </Grid>

          <div className={style.newCardModalButton}>
            <LmButton
              disabled={!selectedNewProduct}
              onClick={() => handleNewCard(globalState)}
            >
              {i18next.t('windows.LM_STEPPER_NEW_PRODUCT_MODAL.proceed')}
            </LmButton>
          </div>
        </div>
      </Dialog>
    );
  }
}
