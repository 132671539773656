
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from '@lm/lm-design-system/dist/assets/styles/theme.js';
import LmConfigurator from './pages/LmConfigurator';

import TagManager from 'react-gtm-module';


import { env } from './utils/env';
import {init18n} from './utils/i18n';

const GTAG_ALE = 'GTM-NBG9DJ3';
const GTAG_POLAND = 'GTM-T4R6NPK';

const tagManagerArgs = {
    gtmId: GTAG_POLAND
}



TagManager.initialize(tagManagerArgs);


const routing = (
    <Router>
        <Route path={env.REACT_APP_DOMAIN}>
            <MuiThemeProvider theme={theme}><LmConfigurator /></MuiThemeProvider>
        </Route>
    </Router>
)

init18n().then((res)=>{
    ReactDOM.render(routing, document.getElementById('root'));
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
