import React from 'react';
import style from './style.module.scss';

import LmStepperSummaryInfos from '../LmStepperSummaryInfos';

export default class LmStepperSummaryCard extends React.Component {
  render() {
    const { 
      globalState,
      el, 
      handleLinkProductModal,
      handleModify, 
      duplicateModalOpened,
      changeQuantity,
      isFromProjectCard,
      handleDuplicate,
      handleSummaryCardDuplicateModal
    } = this.props;

    return (
      <div className={style.summaryStepperContainer}>
        <div className={style.summaryStepper}>
          {el.map((el, i) => {
            return (
              <LmStepperSummaryInfos 
                globalState={globalState}
                key={el.cardName}
                el={el}
                i={i}
                changeQuantity={changeQuantity}
                handleLinkProductModal={handleLinkProductModal}
                handleModify={handleModify}
                duplicateModalOpened={duplicateModalOpened}
                isFromProjectCard={isFromProjectCard}
                handleDuplicate={handleDuplicate}
                handleSummaryCardDuplicateModal={handleSummaryCardDuplicateModal}
              />
            )}
          )}
        </div>
      </div>
    )
  }
}