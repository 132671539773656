import React from 'react';

// import project components
import LmStepperSectionContainer from '../../LmStepperSectionContainer';
import LmStepperSummaryContainer from '../../LmStepperSummaryContainer';
import i18next from '../../../utils/i18n';

export default class LmStepperWinStep6 extends React.Component {
  render() {
    const {
      globalState,
      summaryCardsInfos,
      handleModify,
      handleDuplicate,
      handleNewCard,
      changeQuantity,
      newProductModalOpened,
      productModalOpened,
      handleLinkProductModal,
      handleLinkProduct,
      configuratorsOptions,
      selectedNewProduct,
      handleNewProductModal,
      selectedToBeLinkedProduct,
      selectedLinkedProduct,
      handleLinkedProduct,
      appointmentModalOpened,
      appointmentForm,
      isLogged,
      storeDetails,
      currentGroup,
      duplicateModalOpened,
      handleSummaryCardDuplicateModal,
      shareEmailModalOpened,
      handleShareRequestModal,
    } = this.props;

    return (
      <LmStepperSectionContainer
        title={i18next.t('windows.section.title.fixtures_summary')}
      >
        <LmStepperSummaryContainer
          globalState={globalState}
          summaryCardsInfos={summaryCardsInfos}
          handleNewCard={handleNewCard}
          handleModify={handleModify}
          handleDuplicate={handleDuplicate}
          changeQuantity={changeQuantity}
          newProductModalOpened={newProductModalOpened}
          productModalOpened={productModalOpened}
          handleLinkProductModal={handleLinkProductModal}
          handleLinkProduct={handleLinkProduct}
          configuratorsOptions={configuratorsOptions}
          selectedNewProduct={selectedNewProduct}
          handleNewProductModal={handleNewProductModal}
          selectedLinkedProduct={selectedLinkedProduct}
          selectedToBeLinkedProduct={selectedToBeLinkedProduct}
          handleLinkedProduct={handleLinkedProduct}
          appointmentModalOpened={appointmentModalOpened}
          appointmentForm={appointmentForm}
          isLogged={isLogged}
          storeDetails={storeDetails}
          currentGroup={currentGroup}
          duplicateModalOpened={duplicateModalOpened}
          handleSummaryCardDuplicateModal={handleSummaryCardDuplicateModal}
          shareEmailModalOpened={shareEmailModalOpened}
          handleShareRequestModal={handleShareRequestModal}
        />
      </LmStepperSectionContainer>
    );
  }
}
