"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = void 0;

var _core = require("@material-ui/core");

var breakpointValues = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1200
};
var theme = (0, _core.createMuiTheme)({
  breakpoints: {
    values: breakpointValues
  }
});
exports.theme = theme;