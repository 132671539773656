import React from 'react';

// imports from material-ui
import { Dialog } from '@material-ui/core';
import svg from './../../assets/images/loader.svg';

export default class LmLoader extends React.Component {
  render() {
    const { isLoading } = this.props;
    return (
      <Dialog
        open={isLoading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        maxWidth="xl"><img src={svg} alt="Loading..." /></Dialog>
    )
  }
}